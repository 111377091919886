import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Typography,
	makeStyles,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { loadExtratoResumoAction, loadUserData } from '../../../actions/actions';
import { useDispatch, useSelector } from 'react-redux';

import AccountCollectionItem from '../../reusable/Content/AccountCollections/AccountCollectionItem/AccountCollectionItem';
import { AiOutlineBarcode } from 'react-icons/ai';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import CustomButton from '../../reusable/CustomButton/CustomButton';
import CustomCurrencyFormat from '../../../constants/CustomCurrencyFormat';
import { Link, useHistory } from 'react-router-dom';
import { MdCollectionsBookmark } from 'react-icons/md';
import NumberFormat from 'react-number-format';
import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';
import { getUserData } from '../../../services/services';
import { toast } from 'react-toastify';
import useAuth from '../../../hooks/useAuth';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: '100%',
	},
	menuTransferencia: {
		padding: '20px',
		borderRadius: '0px',
	},
	iconContainer: {
		display: 'flex',
		alignItems: 'flex-start',
		backgroundColor: '#FFE564',
		color: '#0f1c36',
		padding: '15px 10px 0px 15px',
		boxShadow: '0px 0px 30px 3px #CC9B00',
		transition: `${theme.transitions.create(['background-color', 'transform'], {
			duration: theme.transitions.duration.standard,
		})}`,
		'&:hover': {
			cursor: 'pointer',
			transform: 'scale(1.05)',
		},

		height: '100px',
		width: '100%',
		animation: `$myEffect 500ms ${theme.transitions.easing.easeInOut}`,
		marginLeft: '8px',
	},
	hoverAnimation: {
		transition: `${theme.transitions.create(['background-color', 'transform'], {
			duration: theme.transitions.duration.standard,
		})}`,
		'&:hover': {
			transform: 'scale(1.05)',
		},

		animation: `$myEffect 500ms ${theme.transitions.easing.easeInOut}`,
		margin: '10px 8px',
	},
}));

const Home = () => {
	const classes = useStyles();
	const theme = useTheme();
	const dispatch = useDispatch();
	const history = useHistory();
	const token = useAuth();
	const [showRestOptions, setShowRestOptions] = useState(false);
	const [openTransferirModal, setOpenTransferirModal] = useState(false);
	const small = useMediaQuery(theme.breakpoints.down(1090));
	const userData = useSelector((state) => state.userData);
	const extratoResumo = useSelector((state) => state.extratoResumo);

	useEffect(() => {
		dispatch(loadUserData(token));
	}, []);

	useEffect(() => {
		dispatch(loadExtratoResumoAction(token));
	}, []);

	useEffect(() => {
		console.log(userData);
	}, [userData]);

	return (
		<Box className={classes.root}>
			<Zoom>
				<Box style={!small ? { display: 'flex' } : { display: 'flex', justifyContent: 'center' }}>
					<Box
						style={
							!small
								? {
										zIndex: 3,
										marginBottom: '15px',
								  }
								: {
										zIndex: 3,
										marginBottom: '15px',
										display: 'flex',
										alignItems: 'center',

										flexDirection: 'column',
										justifyContent: 'center',
										alignSelf: 'center',
										justifySelf: 'center',
								  }
						}
						item
					>
						<Typography
							style={{
								marginBottom: '10px',
								marginLeft: '8px',
								color: theme.palette.primary.dark,
								fontSize: '1rem',
							}}
							variant="h4"
						>
							Olá, {userData.nome}
						</Typography>
						<Box className={classes.iconContainer} style={small ? { height: '160px' } : {}}>
							<Box display="flex" flexDirection="column" width="70%">
								<Typography variant="subtitle2" style={{ color: theme.palette.primary.dark }}>
									SALDO DISPONÍVEL
								</Typography>
								<CustomCurrencyFormat
									value={userData.saldo.valor}
									renderText={(value, props) => (
										<Typography variant="h1" style={{ fontSize: '25px', paddingTop: '10px' }}>
											{value}
										</Typography>
									)}
								/>
							</Box>
							<Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<Button
									variant="contained"
									style={{ backgroundColor: 'white', fontSize: '0.65rem', width: '100px' }}
									onClick={() => history.push('/dashboard/extrato')}
								>
									Ver Tudo
								</Button>
							</Box>
						</Box>
					</Box>
				</Box>
			</Zoom>
			<Zoom>
				<Box
					style={
						!small
							? {
									display: 'flex',
									justifyContent: 'space-between',
									width: '100%',
							  }
							: {
									display: 'flex',
									flexDirection: 'column',
									width: '100%',
									height: '100%',
									alignItems: 'center',
							  }
					}
				>
					<Box
						style={{ zIndex: 3, width: '25%', minWidth: '230px', margin: '8px 8px 8px 8px' }}
						item
						xs={3}
					>
						<AccountCollectionItem
							icon="api"
							onClick={() => toast.error('Em desenvolvimento')}
							text="PIX"
						/>
					</Box>
					<Box style={{ zIndex: 3, width: '25%', minWidth: '230px', margin: '8px 8px' }} item xs={3}>
						<AccountCollectionItem
							icon="arrow_upward"
							onClick={() => setOpenTransferirModal(true)}
							text="TRANSFERIR"
						/>
					</Box>
					<Box style={{ zIndex: 3, width: '25%', minWidth: '230px', margin: '8px 8px' }} item xs={3}>
						<AccountCollectionItem
							icon="arrow_downward"
							onClick={() => toast.error('Em desenvolvimento')}
							text="DEPOSITAR"
						/>
					</Box>
					<Box style={{ zIndex: 3, width: '25%', minWidth: '230px', margin: '8px' }} item xs={3}>
						<AccountCollectionItem
							icon="attach_money"
							onClick={() => toast.error('Em desenvolvimento')}
							text="PAGAR"
						/>
					</Box>
				</Box>
			</Zoom>
			<Zoom>
				<Box
					style={
						!small
							? { display: 'flex', margin: '18px 0px', justifyContent: 'space-between' }
							: { display: 'flex', flexDirection: 'column', margin: '18px 0px', alignItems: 'center' }
					}
				>
					<Box
						className={classes.hoverAnimation}
						boxShadow={3}
						style={{
							backgroundColor: 'white',
							minWidth: '263px',
							display: 'flex',
							flexDirection: 'column',
							width: '33%',
							height: '250px',
							padding: '20px',
						}}
					>
						<Typography variant="subtitle2">SEUS CARTÕES</Typography>
						<Typography>Não há cartões disponíveis</Typography>
					</Box>
					<Box display="flex" flexDirection="column">
						<Box
							boxShadow={3}
							className={classes.hoverAnimation}
							style={{
								backgroundColor: 'white',
								minWidth: '263px',
								display: 'flex',
								flexDirection: 'column',
								width: '33%',
								height: '100px',
								padding: '20px',
								marginBottom: 0,
							}}
						>
							<Box display="flex" margin="10px 3px" justifyContent="space-between">
								<Typography>Entradas</Typography>
								{/* <CustomCurrencyFormat
									value={extratoResumo.entrada}
									renderText={(value, props) => <Typography>{value}</Typography>}
								/> */}
								<Typography style={{ color: 'green' }}> R$ {extratoResumo.entrada}</Typography>
							</Box>
							<Box display="flex" margin="10px 3px" justifyContent="space-between">
								<Typography>Saídas</Typography>
								<Typography style={{ color: 'red' }}> R$ {extratoResumo.retirada}</Typography>
							</Box>
						</Box>
						<Box
							boxShadow={3}
							className={classes.hoverAnimation}
							style={{
								backgroundColor: 'white',
								minWidth: '263px',
								display: 'flex',
								flexDirection: 'column',
								width: '33%',
								height: '100px',
								padding: '20px',
							}}
						>
							<Box display="flex" margin="10px 3px" justifyContent="space-between">
								<Typography>Valor Futuro</Typography>
								<Typography>R$ {userData.saldo.valor_futuro}</Typography>
							</Box>
						</Box>
					</Box>
					<Box
						boxShadow={3}
						className={classes.hoverAnimation}
						style={{
							backgroundColor: 'white',
							minWidth: '280px',
							display: 'flex',
							flexDirection: 'column',
							width: '33%',
							height: '250px',
							padding: '20px',
						}}
					>
						<Typography variant="subtitle2">GRÁFICO</Typography>
						<Typography>Em desenvolvimento</Typography>
					</Box>
				</Box>
			</Zoom>
			{!showRestOptions ? (
				<Slide left appear spy={showRestOptions}>
					<Box
						style={
							!small
								? { display: 'flex', justifyContent: 'space-between', alignItems: 'center' }
								: { display: 'flex', flexDirection: 'column', alignItems: 'center' }
						}
					>
						<Box style={{ width: '17%', minWidth: '171px', margin: '8px' }} item xs={3}>
							<AccountCollectionItem
								variant="small"
								icon="credit_card"
								text="CARTÃO"
								link="cobrancas-credito"
							/>
						</Box>
						<Box style={{ width: '17%', minWidth: '171px', margin: '8px' }} item xs={3}>
							<AccountCollectionItem
								variant="small"
								IconComponent={() => (
									<AiOutlineBarcode
										style={{ color: theme.palette.primary.dark, fontSize: '60px' }}
									/>
								)}
								text="BOLETO"
								link="boleto"
							/>
						</Box>
						<Box style={{ width: '17%', minWidth: '171px', margin: '8px' }} item xs={3}>
							<AccountCollectionItem
								variant="small"
								IconComponent={() => (
									<MdCollectionsBookmark
										style={{ color: theme.palette.primary.dark, fontSize: '60px' }}
									/>
								)}
								text="CARNÊ"
								link="carne"
							/>
						</Box>
						<Box style={{ width: '17%', minWidth: '171px', margin: '8px' }} item xs={3}>
							<AccountCollectionItem
								variant="small"
								icon="sync"
								link="assinaturas"
								text="COBRANÇA RECORRENTE"
							/>
						</Box>
						<Box style={{ width: '17%', minWidth: '171px', margin: '8px' }} item xs={3}>
							<AccountCollectionItem
								variant="small"
								icon="link"
								link="link-pagamento"
								text="LINK DE PAGAMENTO"
							/>
						</Box>
						<IconButton onClick={() => setShowRestOptions(true)}>
							<ArrowForwardIosOutlinedIcon />
						</IconButton>
					</Box>
				</Slide>
			) : (
				<Slide right appear spy={showRestOptions}>
					<Box
						style={
							!small
								? { display: 'flex', justifyContent: 'space-between', alignItems: 'center' }
								: { display: 'flex', flexDirection: 'column', alignItems: 'center' }
						}
					>
						<IconButton onClick={() => setShowRestOptions(false)}>
							<ArrowBackIosOutlinedIcon />
						</IconButton>
						<Box style={{ zIndex: 3, width: '33%', minWidth: '279px', margin: '8px' }} item xs={3}>
							<AccountCollectionItem
								variant="small"
								icon="history"
								text="HISTÓRICO DE TRANSAÇÕES"
								link="historico-de-transacoes"
							/>
						</Box>
						<Box style={{ zIndex: 3, width: '33%', minWidth: '279px', margin: '8px' }} item xs={3}>
							<AccountCollectionItem
								variant="small"
								icon="update"
								link="lancamentos-futuros"
								text="LANÇAMENTOS FUTUROS"
							/>
						</Box>
						<Box style={{ zIndex: 3, width: '33%', minWidth: '279px', margin: '8px' }} item xs={3}>
							<AccountCollectionItem variant="small" icon="group" text="PAGADORES" link="pagadores" />
						</Box>
					</Box>
				</Slide>
			)}
			<Dialog
				open={openTransferirModal}
				onClose={() => setOpenTransferirModal(false)}
				className={classes.menuTransferencia}
				style={{ borderRadius: '0px' }}
			>
				<DialogTitle>
					<Typography variant="subtitle2" align="center" style={{ fontSize: '1.2rem' }}>
						TRANSFERIR
					</Typography>
				</DialogTitle>
				<DialogContent>
					<Box>
						<List>
							<ListItem
								component={Link}
								button
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									padding: '20px',
								}}
								to="nova-transferencia"
							>
								<ListItemAvatar>
									<span class="material-icons" style={{ color: '#CC9B00', fontSize: '1.8rem' }}>
										import_export
									</span>
								</ListItemAvatar>
								<ListItemText primary="Trânsferencia entre ItaContas" />
							</ListItem>
							<ListItem
								component={Link}
								button
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									padding: '20px',
								}}
								onClick={() => {
									setOpenTransferirModal(false);
									toast.error('Em desenvolvimento');
								}}
							>
								<ListItemAvatar>
									<span class="material-icons" style={{ color: '#CC9B00', fontSize: '1.8rem' }}>
										arrow_upward
									</span>
								</ListItemAvatar>
								<ListItemText primary="Trânsferencia TED" />
							</ListItem>
						</List>
					</Box>
				</DialogContent>
			</Dialog>
		</Box>
	);
};

export default Home;
