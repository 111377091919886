import { CssBaseline, InputAdornment, OutlinedInput, TextField } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	textField: {
		borderRadius: '27px',
		opacity: 1,
		borderWidth: 0,
	},

	cssLabel: {},

	cssOutlinedInput: {},

	cssFocused: {},

	notchedOutline: {},
	input: {
		backgroundColor: 'rgba(204, 155, 0, 0.3)',
		opacity: 1,
		'&::placeholder': {
			textOverflow: 'ellipsis !important',
			color: 'black',
			fontWeight: '600',
			fontSize: '14px',
		},
	},
}));

const SearchBar = (rest) => {
	const classes = useStyles();
	return (
		<>
			<TextField
				{...rest}
				className={classes.textField}
				variant="outlined"
				InputProps={{
					classes: {
						root: classes.cssOutlinedInput,
						focused: classes.cssFocused,
						notchedOutline: classes.notchedOutline,
						input: classes.input,
					},
				}}
			/>
		</>
	);
};

export default SearchBar;
