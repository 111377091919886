import {
	Box,
	Button,
	Grid,
	IconButton,
	InputLabel,
	LinearProgress,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { faBan, faTable, faTrash, faWallet } from '@fortawesome/free-solid-svg-icons';
import { generatePath, useHistory, useParams } from 'react-router';
import { loadExportExtrato, loadExtratoFilter } from '../../../actions/actions';
import { useDispatch, useSelector } from 'react-redux';

import CustomBreadcrumbs from '../../reusable/CustomBreadcrumbs/CustomBreadcrumbs';
import CustomCollapseTable from '../../reusable/CustomCollapseTable/CustomCollapseTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from '@material-ui/lab/Pagination';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import useAuth from '../../../hooks/useAuth';
import useDebounce from '../../../hooks/useDebounce';

const columns = [
	{
		headerText: 'Detalhes da Transação',
		key: 'data',
		CustomValue: (data) => {
			const p = data.split(/\D/g);
			const dataFormatada = [p[2], p[1], p[0]].join('/');
			return (
				<Box display="flex" justifyContent="center">
					<FontAwesomeIcon icon={faCalendarAlt} size="lg" />
					<Typography style={{ marginLeft: '6px' }}>{dataFormatada}</Typography>
				</Box>
			);
		},
	},
	{
		headerText: 'Valor Bloqueado',
		key: 'valor_bloqueado',
		CustomValue: (value) => (
			<Box display="flex" justifyContent="center">
				<FontAwesomeIcon icon={faBan} size="lg" />
				<Typography style={{ marginLeft: '6px', color: 'red' }}>
					<b>R$ {value}</b>
				</Typography>
			</Box>
		),
	},
	{
		headerText: 'Saldo do dia',
		key: 'saldo',
		CustomValue: (value) => (
			<Box display="flex" justifyContent="center">
				<FontAwesomeIcon icon={faWallet} size="lg" />
				<Typography style={{ marginLeft: '6px' }}>
					<b>R$ {value}</b>
				</Typography>
			</Box>
		),
	},
];
const itemColumns = [
	{
		headerText: <Typography variant="h6">Descrição</Typography>,
		key: 'description',
		CustomValue: (description) => {
			return (
				<Typography variant="" style={{ fontSize: 16 }}>
					{description}
				</Typography>
			);
		},
	},
	{
		headerText: <Typography variant="h6">Transação Id</Typography>,
		key: 'transaction.id',
		CustomValue: (id) => {
			return (
				<Typography variant="" style={{ fontSize: 12 }}>
					{id ? id : null}
				</Typography>
			);
		},
	},
	{
		headerText: <Typography variant="h6">nsu</Typography>,
		key: 'nsu',
		CustomValue: (nsu) => {
			return (
				<Typography variant="" style={{ fontSize: 16 }}>
					{nsu}
				</Typography>
			);
		},
	},
	{
		headerText: <Typography variant="h6">Taxas</Typography>,
		key: 'fee',
		CustomValue: (fee) => {
			if (fee > 0) {
				return (
					<Typography variant="" style={{ fontSize: 16, color: '#dfad06', fontWeight: 600 }}>
						R$ {fee}
					</Typography>
				);
			} else {
				return (
					<Typography variant="" style={{ fontSize: 16, color: '	green', fontWeight: 600 }}>
						R$ {fee}
					</Typography>
				);
			}
		},
	},
	{
		headerText: <Typography variant="h6">Valor</Typography>,
		key: 'amount',
		CustomValue: (amount) => {
			if (amount < 0) {
				return (
					<Typography variant="" style={{ fontSize: 17, fontWeight: 600, color: 'red' }}>
						R$ {amount}
					</Typography>
				);
			} else {
				return (
					<Typography variant="" style={{ fontSize: 17, fontWeight: 600, color: 'green' }}>
						R$ {amount}
					</Typography>
				);
			}
		},
	},
];

const AccountStatement = () => {
	const dispatch = useDispatch();
	const token = useAuth();
	const [page, setPage] = useState(1);
	const userExtrato = useSelector((state) => state.extrato);
	const userData = useSelector((state) => state.userData);
	const exportExtrato = useSelector((state) => state.exportExtrato);
	const { id } = useParams();
	const history = useHistory();
	const [filters, setFilters] = useState({
		id: '',
		day: ' ',
		order: '',
		mostrar: '',
		tipo: '',
	});
	const debouncedId = useDebounce(filters.id, 800);

	useEffect(() => {
		dispatch(
			loadExtratoFilter(
				token,
				page,
				debouncedId,
				filters.day,
				filters.order,
				filters.mostrar,
				filters.tipo,
				id
			)
		);
	}, [filters.day, filters.order, filters.mostrar, filters.tipo, page, debouncedId, id]);

	const handleChangePage = (e, value) => {
		setPage(value);
	};

	const handleClickRow = (row) => {
		if (row.transaction && row.transaction.id) {
			const path = generatePath('/dashboard/detalhes-transacao/:id/ver', {
				id: row.transaction.id,
			});
			history.push(path);
		} else {
			return null;
		}
	};

	const handleExportarExtrato = async () => {
		const res = await dispatch(
			loadExportExtrato(
				token,
				page,
				debouncedId,
				filters.day,
				filters.order,
				filters.mostrar,
				filters.tipo,
				id
			)
		);
		if (res && res.url !== undefined) {
			window.open(`${res.url}`, '', '');
		}
	};

	useEffect(() => {
		return () => {
			setFilters({ ...filters });
		};
	}, []);

	return (
		<Box display="flex" flexDirection="column">
			{token && userData === '' ? (
				<CustomBreadcrumbs path1="Gerenciar Listas" to1="goBack" path2="Extrato" />
			) : (
				<CustomBreadcrumbs path1="Extrato" />
			)}
			<Typography style={{ marginTop: '8px' }} variant="h4">
				Extrato
			</Typography>
			<Box display="flex" style={{ marginTop: '10px', marginBottom: '16px' }}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={3}>
						<TextField
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							placeholder="Filtrar por ID da transação"
							fullWidth
							label="ID da transação"
							value={filters.id}
							onChange={(e) => setFilters({ ...filters, id: e.target.value })}
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<InputLabel>Período</InputLabel>
						<Select
							fullWidth
							value={filters.day}
							onChange={(e) => setFilters({ ...filters, day: e.target.value })}
						>
							<MenuItem value=" ">Todos</MenuItem>
							<MenuItem value={1}>Hoje</MenuItem>
							<MenuItem value={7}>Últimos 7 dias</MenuItem>
							<MenuItem value={15}>Últimos 15 dias</MenuItem>
							<MenuItem value={30}>Últimos 30 dias</MenuItem>
							<MenuItem value={60}>Últimos 60 dias</MenuItem>
							<MenuItem value={90}>Últimos 90 dias</MenuItem>
						</Select>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Box display="flex" width="100%" justifyContent="flex-end">
							<Tooltip title="Limpar Filtros">
								<IconButton
									onClick={() =>
										setFilters({
											...filters,
											day: ' ',
											order: '',
											mostrar: '',
											tipo: '',
										})
									}
								>
									<FontAwesomeIcon icon={faTrash} />
								</IconButton>
							</Tooltip>
							<Tooltip title="Exportar Excel">
								<IconButton
									variant="outlined"
									style={{ marginLeft: '6px' }}
									onClick={handleExportarExtrato}
								>
									<FontAwesomeIcon icon={faTable} color="green" />
								</IconButton>
							</Tooltip>
						</Box>
					</Grid>
				</Grid>
			</Box>

			{userExtrato && userExtrato.per_page ? (
				<CustomCollapseTable
					itemColumns={itemColumns}
					data={userExtrato.data}
					columns={columns}
					handleClickRow={handleClickRow}
				/>
			) : (
				<LinearProgress />
			)}
			<Box alignSelf="flex-end" marginTop="8px">
				{
					<Pagination
						variant="outlined"
						color="secondary"
						size="large"
						count={userExtrato.last_page}
						onChange={handleChangePage}
						page={page}
					/>
				}
			</Box>
		</Box>
	);
};

export default AccountStatement;
