import { Box, Typography, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';

import React from 'react';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
	smallIconContainer: {
		display: 'flex',
		flexDirection: 'column',
		padding: '0px',
		alignItems: 'center',
		backgroundColor: theme.palette.common.white,
		color: '#0f1c36',
		justifyContent: 'center',
		transition: `${theme.transitions.create(['background-color', 'transform'], {
			duration: theme.transitions.duration.standard,
		})}`,
		'&:hover': {
			cursor: 'pointer',
			transform: 'scale(1.05)',
		},
		width: '100%',
		height: '170px',
		animation: `$myEffect 500ms ${theme.transitions.easing.easeInOut}`,
	},
	iconContainer: {
		display: 'flex',
		flexDirection: 'row-reverse',
		alignItems: 'flex-start',
		backgroundColor: theme.palette.common.white,
		color: '#0f1c36',
		justifyContent: 'space-between',
		padding: '20px 33px',
		transition: `${theme.transitions.create(['background-color', 'transform'], {
			duration: theme.transitions.duration.standard,
		})}`,
		'&:hover': {
			cursor: 'pointer',
			transform: 'scale(1.05)',
		},

		width: '100%',
		height: '100px',
		animation: `$myEffect 500ms ${theme.transitions.easing.easeInOut}`,
	},

	textContainer: {
		fontSize: '0.9rem',
		fontWeight: '500',
	},
}));

const AccountCollectionItem = ({ icon, link, text, variant, IconComponent, ...rest }) => {
	const classes = useStyles();
	const { section } = useParams();
	const theme = useTheme();

	return (
		<Box display="flex">
			<Box
				onClick={
					link === null ? () => toast.warning('Sem permissão para acessar essa funcionalidade') : null
				}
				boxShadow={3}
				className={variant === 'small' ? classes.smallIconContainer : classes.iconContainer}
				component={Link}
				to={link === null ? section : link}
				{...rest}
			>
				{icon ? (
					<span class="material-icons" style={{ color: theme.palette.primary.dark, fontSize: '60px' }}>
						{icon}
					</span>
				) : IconComponent ? (
					<IconComponent />
				) : null}

				<Typography
					variant="subtitle2"
					className={classes.textContainer}
					align="center"
					style={variant === 'small' ? { marginTop: '20px' } : { marginTop: '10px' }}
				>
					{text}
				</Typography>
			</Box>
		</Box>
	);
};

export default AccountCollectionItem;
