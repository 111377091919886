import { Box, LinearProgress } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { delTokens, loadTokens } from '../../../actions/actions';

import useAuth from '../../../hooks/useAuth';
import CustomTable from '../../reusable/CustomTable/CustomTable';

import GradientButton from '../../reusable/GradientButton/GradientButton';
const columns = [
	{ headerText: 'Nome', key: 'name' },
	{
		headerText: '',
		key: 'id',
		CustomValue: (id) => {
			const dispatch = useDispatch();
			const token = useAuth();
			const handleExcluir = () => {
				const res = dispatch(delTokens(token, id));
				if (res === 0) {
					toast.error('Erro ao excluir token');
				}
			};
			return <GradientButton buttonText="Excluir" onClick={handleExcluir} />;
		},
	},
];
const PublicTokens = () => {
	const token = useAuth();
	const dispatch = useDispatch();
	const publicTokensList = useSelector((state) => state.publicTokensList);
	useEffect(() => {
		dispatch(loadTokens(token));
	}, []);

	return (
		<Box>
			<>
				{publicTokensList && publicTokensList ? (
					<CustomTable data={publicTokensList} columns={columns} compacta="true" />
				) : (
					<LinearProgress />
				)}
			</>
		</Box>
	);
};

export default PublicTokens;
