import {
	AppBar,
	Box,
	Button,
	Card,
	CardActionArea,
	CardActions,
	CardMedia,
	Divider,
	Grid,
	IconButton,
	Paper,
	Tab,
	Tabs,
	TextField,
	Typography,
	makeStyles,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
	delDocumento,
	loadDocumentos,
	postDocumentoAction,
	updateUserConta,
	updateUserPassword,
} from '../../../actions/actions';
import { useDispatch, useSelector } from 'react-redux';

import ClearIcon from '@material-ui/icons/Clear';
import CreateBankAccount from '../BankAccounts/CreateBankAccount/CreateBankAccount';
import CustomBreadcrumbs from '../../reusable/CustomBreadcrumbs/CustomBreadcrumbs';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import GradientButton from '../../reusable/GradientButton/GradientButton';
import ListBankAccounts from '../BankAccounts/ListBankAccounts/ListBankAccounts';
import LoadingScreen from '../../reusable/LoadingScreen/LoadingScreen';
import NewAccount from '../../reusable/Account/NewAccount/NewAccount';
import { PictureAsPdf } from '@material-ui/icons';
import PublicTokens from '../PublicTokens/PublicTokens';
import SwipeableViews from 'react-swipeable-views';
import { toast } from 'react-toastify';
import useAuth from '../../../hooks/useAuth';
import { useHistory } from 'react-router';

const TabPanel = (props) => {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		width: '70%',
		borderRadius: '27px',
		alignSelf: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	card: {
		margin: theme.spacing(1),
		padding: 0,
	},
	dropzoneAreaBaseClasses: {
		width: '70%',
		height: '250px',
		fontSize: '10px',
	},
	dropzoneContainer: {
		margin: '6px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '12px',
		minHeight: '564px',
	},
	textoDropzone: {
		fontSize: '1.2rem',
	},
}));

const a11yProps = (index) => {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
};

const EditLoginAccount = () => {
	const classes = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const token = useAuth();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const [value, setValue] = useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const handleChangeIndex = (index) => {
		setValue(index);
	};
	const userData = useSelector((state) => state.userData);

	const [conta, setConta] = useState({
		nome: '',
		endereco: {
			cep: '',
		},
	});

	const [contaBancaria, setContaBancaria] = useState({
		banco: '',
		agencia: '',
		tipo: '1',
		conta: '',
	});

	const [errosConta, setErrosConta] = useState({});

	useEffect(() => {
		setConta({ ...conta, ...userData });
	}, [userData]);

	const handleAlterar = async () => {
		setLoading(true);
		const newConta = conta;
		const resConta = await dispatch(updateUserConta(token, newConta));
		if (resConta) {
			setErrosConta(resConta);
			toast.error('Erro ao alterar dados');
			setLoading(false);
		} else {
			toast.success('Dados alterados com sucesso!');
			setLoading(false);
		}
	};

	const [userPass, setUserPass] = useState({
		password: '',
		password_confirmation: '',
	});

	const [errosPass, setErrosPass] = useState({});

	const handleUpdatePass = async () => {
		setLoading(true);
		const resPass = await dispatch(updateUserPassword(token, userPass));
		if (resPass) {
			toast.error('Erro ao modificar senha');
			setErrosPass(resPass);
			setLoading(false);
		} else {
			toast.success('Senha modificada com sucesso');
			setLoading(false);
		}
	};

	const arquivoDocumento = useSelector((state) => state.arquivoDocumento);

	useEffect(() => {
		dispatch(loadDocumentos(token));
	}, [arquivoDocumento.lenght]);

	const handleExcluirArquivo = async (item) => {
		setLoading(true);
		await dispatch(delDocumento(token, item.id));
		await dispatch(loadDocumentos(token));
		setLoading(false);
	};

	const onDropIdentificacao = async (picture) => {
		setLoading(true);
		const categoria = 'IDENTIFICACAO';
		await dispatch(postDocumentoAction(token, picture, categoria));
		await dispatch(loadDocumentos(token));
		setLoading(false);
	};
	const onDropComprovanteResidencia = async (picture, file) => {
		setLoading(true);
		const categoria = 'COMPROVANTE_RESIDENCIA';
		await dispatch(postDocumentoAction(token, picture, categoria));
		await dispatch(loadDocumentos(token));
		setLoading(false);
	};
	const onDropAtividade = async (picture, file) => {
		setLoading(true);
		const categoria = 'COMPROVANTE_ATIVIDADE';
		await dispatch(postDocumentoAction(token, picture, categoria));
		await dispatch(loadDocumentos(token));
		setLoading(false);
	};
	const onDropOutros = async (picture, file) => {
		setLoading(true);
		const categoria = 'OUTROS';
		await dispatch(postDocumentoAction(token, picture, categoria));
		await dispatch(loadDocumentos(token));
		setLoading(false);
	};

	return (
		<Box className={classes.root}>
			<LoadingScreen isLoading={loading} />
			<CustomBreadcrumbs path1="Editar conta" />
			<Typography style={{ marginTop: '8px', marginBottom: '8px' }} variant="h4">
				Editar Conta
			</Typography>
			<Paper className={classes.paper}>
				<AppBar
					position="static"
					color="default"
					style={{
						borderRadius: '27px 27px 0 0 ',
					}}
				>
					<Tabs
						value={value}
						onChange={handleChange}
						indicatorColor="secondary"
						textColor="primary"
						variant="scrollable"
						centered
					>
						<Tab label="Dados Cadastrais" style={{ width: '60%' }} {...a11yProps(0)} />
						<Tab label="Alterar senha" style={{ width: '60%' }} {...a11yProps(1)} />
						<Tab label="Documentos" style={{ width: '60%' }} {...a11yProps(2)} />
						<Tab label="Dados bancários" style={{ width: '60%' }} {...a11yProps(3)} />
						<Tab label="Tokens Públicos" style={{ width: '60%' }} {...a11yProps(4)} />
					</Tabs>
				</AppBar>
				<SwipeableViews
					axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
					index={value}
					onChangeIndex={handleChangeIndex}
				>
					<TabPanel value={value} index={0} dir={theme.direction}>
						<Box display="flex" flexDirection="column">
							<NewAccount conta={conta} setConta={setConta} errosConta={errosConta} disableEditar />
							<Box alignSelf="flex-end" marginTop="16px">
								<GradientButton buttonText="Alterar" onClick={handleAlterar} />
							</Box>
						</Box>
					</TabPanel>
					<TabPanel value={value} index={1} dir={theme.direction}>
						<Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
							<TextField
								variant="outlined"
								error={errosPass.password}
								helperText={errosPass.password ? errosPass.password.join(' ') : null}
								label="Nova Senha"
								type="password"
								value={userPass.password}
								onChange={(e) =>
									setUserPass({
										...userPass,
										password: e.target.value,
									})
								}
								style={{ width: '50%' }}
							/>

							<TextField
								variant="outlined"
								style={{ width: '50%' }}
								label="Confirmar Nova Senha"
								type="password"
								value={userPass.password_confirmation}
								onChange={(e) =>
									setUserPass({
										...userPass,
										password_confirmation: e.target.value,
									})
								}
							/>

							<Box marginTop="12px">
								<GradientButton buttonText="Alterar" onClick={handleUpdatePass} />
							</Box>
						</Box>
					</TabPanel>
					<TabPanel value={value} index={2} dir={theme.direction}>
						<Box
							display="flex"
							style={matches ? { flexDirection: 'column' } : null}
							justifyContent="center"
						>
							<Box display="flex" flexDirection="column" margin="8px">
								<Box className={classes.dropzoneContainer} boxShadow={3}>
									<Typography variant="h6">IDENTIFICAÇÃO</Typography>
									<Typography>RG, CPF ou CNH.</Typography>
									<DropzoneAreaBase
										dropzoneParagraphClass={classes.textoDropzone}
										maxFileSize={3145728}
										onDropRejected={() => {
											toast.error('Tamanho máximo: 3mb ');
											toast.error('Arquivos suportados: .pdf .png .jpg .jpeg');
										}}
										acceptedFiles={['image/*', 'application/pdf']}
										dropzoneClass={classes.dropzoneAreaBaseClasses}
										onAdd={onDropIdentificacao}
										filesLimit={1}
										dropzoneText="Arraste e solte o arquivo aqui ou clique para escolher"
										showPreviews={false}
										showPreviewsInDropzone={false}
									/>
									<Box width="300px">
										<Grid container>
											{arquivoDocumento.map((item) =>
												item.categoria === 'IDENTIFICACAO' ? (
													<Grid item xs={6}>
														<Card className={classes.card}>
															<CardActionArea>
																<Box position="absolute">
																	<IconButton
																		onClick={() => handleExcluirArquivo(item)}
																		size="small"
																		style={{ color: 'white', backgroundColor: 'red' }}
																	>
																		<ClearIcon />
																	</IconButton>
																</Box>

																{item.descricao === 'application/pdf' ? (
																	<PictureAsPdf
																		style={{ height: '100px', width: '100%' }}
																		onClick={() => window.open(item.arquivo)}
																	/>
																) : (
																	<CardMedia
																		component="img"
																		alt="Arquivo de Identificação"
																		height="100"
																		image={item.arquivo}
																		onClick={() => window.open(item.arquivo)}
																	/>
																)}
															</CardActionArea>
														</Card>
													</Grid>
												) : (
													false
												)
											)}
										</Grid>
									</Box>
								</Box>
								<Box className={classes.dropzoneContainer} boxShadow={3}>
									<Typography variant="h6">COMPROVANTE DE RESIDÊNCIA</Typography>
									<Typography>Conta de luz, telefone e água.</Typography>
									<DropzoneAreaBase
										dropzoneParagraphClass={classes.textoDropzone}
										maxFileSize={3145728}
										onDropRejected={() => {
											toast.error('Tamanho máximo: 3mb ');
											toast.error('Arquivos suportados: .pdf .png .jpg .jpeg');
										}}
										acceptedFiles={['image/*', 'application/pdf']}
										dropzoneClass={classes.dropzoneAreaBaseClasses}
										onAdd={onDropComprovanteResidencia}
										filesLimit={1}
										dropzoneText="Arraste e solte o arquivo aqui ou clique para escolher"
										showPreviews={false}
										showPreviewsInDropzone={false}
									/>
									<Box width="300px">
										<Grid container>
											{arquivoDocumento.map((item) =>
												item.categoria === 'COMPROVANTE_RESIDENCIA' ? (
													<Grid item xs={6}>
														<Card className={classes.card}>
															<CardActionArea>
																<Box position="absolute">
																	<IconButton
																		onClick={() => handleExcluirArquivo(item)}
																		size="small"
																		style={{ color: 'white', backgroundColor: 'red' }}
																	>
																		<ClearIcon />
																	</IconButton>
																</Box>

																{item.descricao === 'application/pdf' ? (
																	<PictureAsPdf
																		style={{ height: '100px', width: '100%' }}
																		onClick={() => window.open(item.arquivo)}
																	/>
																) : (
																	<CardMedia
																		component="img"
																		alt="Comrpovante de Residência"
																		height="100"
																		image={item.arquivo}
																		onClick={() => window.open(item.arquivo)}
																	/>
																)}
															</CardActionArea>
														</Card>
													</Grid>
												) : (
													false
												)
											)}
										</Grid>
									</Box>
								</Box>
							</Box>
							<Box display="flex" flexDirection="column" margin="8px">
								<Box className={classes.dropzoneContainer} boxShadow={3}>
									<Typography variant="h6">COMPROVANTE DE ATIVIDADE</Typography>
									<Typography>Nota fiscal ou cartão de visita</Typography>
									<DropzoneAreaBase
										dropzoneParagraphClass={classes.textoDropzone}
										maxFileSize={3145728}
										onDropRejected={() => {
											toast.error('Tamanho máximo: 3mb ');
											toast.error('Arquivos suportados: .pdf .png .jpg .jpeg');
										}}
										acceptedFiles={['image/*', 'application/pdf']}
										dropzoneClass={classes.dropzoneAreaBaseClasses}
										onAdd={onDropAtividade}
										filesLimit={1}
										dropzoneText="Arraste e solte o arquivo aqui ou clique para escolher"
										showPreviews={false}
										showPreviewsInDropzone={false}
									/>
									<Box width="300px">
										<Grid container>
											{arquivoDocumento.map((item) =>
												item.categoria === 'COMPROVANTE_ATIVIDADE' ? (
													<Grid item xs={6}>
														<Card className={classes.card}>
															<CardActionArea>
																<Box position="absolute">
																	<IconButton
																		onClick={() => handleExcluirArquivo(item)}
																		size="small"
																		style={{ color: 'white', backgroundColor: 'red' }}
																	>
																		<ClearIcon />
																	</IconButton>
																</Box>

																{item.descricao === 'application/pdf' ? (
																	<PictureAsPdf
																		style={{ height: '100px', width: '100%' }}
																		onClick={() => window.open(item.arquivo)}
																	/>
																) : (
																	<CardMedia
																		component="img"
																		alt="Comrpovante de Residência"
																		height="100"
																		image={item.arquivo}
																		onClick={() => window.open(item.arquivo)}
																	/>
																)}
															</CardActionArea>
														</Card>
													</Grid>
												) : (
													false
												)
											)}
										</Grid>
									</Box>
								</Box>
								<Box className={classes.dropzoneContainer} boxShadow={3}>
									<Typography variant="h6">OUTROS</Typography>
									<Typography>Contrato social.</Typography>
									<DropzoneAreaBase
										dropzoneParagraphClass={classes.textoDropzone}
										maxFileSize={3145728}
										onDropRejected={() => {
											toast.error('Tamanho máximo: 3mb ');
											toast.error('Arquivos suportados: .pdf .png .jpg .jpeg');
										}}
										acceptedFiles={['image/*', 'application/pdf']}
										dropzoneClass={classes.dropzoneAreaBaseClasses}
										onAdd={onDropOutros}
										filesLimit={1}
										dropzoneText="Arraste e solte o arquivo aqui ou clique para escolher"
										showPreviews={false}
										showPreviewsInDropzone={false}
									/>
									<Box width="300px">
										<Grid container>
											{arquivoDocumento.map((item) =>
												item.categoria === 'OUTROS' ? (
													<Grid item xs={6}>
														<Card className={classes.card}>
															<CardActionArea>
																<Box position="absolute">
																	<IconButton
																		onClick={() => handleExcluirArquivo(item)}
																		size="small"
																		style={{ color: 'white', backgroundColor: 'red' }}
																	>
																		<ClearIcon />
																	</IconButton>
																</Box>

																{item.descricao === 'application/pdf' ? (
																	<PictureAsPdf
																		style={{ height: '100px', width: '100%' }}
																		onClick={() => window.open(item.arquivo)}
																	/>
																) : (
																	<CardMedia
																		component="img"
																		alt="Comrpovante de Residência"
																		height="100"
																		image={item.arquivo}
																		onClick={() => window.open(item.arquivo)}
																	/>
																)}
															</CardActionArea>
														</Card>
													</Grid>
												) : (
													false
												)
											)}
										</Grid>
									</Box>
								</Box>
							</Box>
						</Box>
					</TabPanel>
					<TabPanel value={value} index={3} dir={theme.direction}>
						<Box>
							<Typography align="center" variant="h6">
								Lista de contas bancárias
							</Typography>
							<ListBankAccounts />
							<Box margin="16px"> </Box>
							<Divider />
							<Box margin="16px"> </Box>
							<CreateBankAccount contaBancaria={contaBancaria} setContaBancaria={setContaBancaria} />
						</Box>
					</TabPanel>
					<TabPanel value={value} index={4} dir={theme.direction}>
						<Box display="flex" flexDirection="column">
							<Typography align="center" variant="h6">
								Tokens Públicos
							</Typography>
							<Box margin="16px"> </Box>
							<PublicTokens />
							<Divider />
							<Box margin="16px"> </Box>
							<GradientButton
								style={{ width: '50%', alignSelf: 'center' }}
								buttonText="Criar novo token"
								onClick={() => history.push('/dashboard/novo-token-publico')}
							/>
						</Box>
					</TabPanel>
				</SwipeableViews>
			</Paper>
		</Box>
	);
};

export default EditLoginAccount;
