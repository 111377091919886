import { Box, Divider, LinearProgress, Paper, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { loadCarneId, loadCarneIdBoleto } from '../../../../actions/actions';
import { useDispatch, useSelector } from 'react-redux';

import CustomBreadcrumbs from '../../../reusable/CustomBreadcrumbs/CustomBreadcrumbs';
import CustomTable from '../../../reusable/CustomTable/CustomTable';
import GradientButton from '../../../reusable/GradientButton/GradientButton';
import LoadingScreen from '../../../reusable/LoadingScreen/LoadingScreen';
import { toast } from 'react-toastify';
import useAuth from '../../../../hooks/useAuth';
import { useParams } from 'react-router';

const columns = [
	{
		headerText: 'Data de Vencimento',
		key: 'data_vencimento',
		CustomValue: (data_vencimento) => {
			const date = new Date(data_vencimento);
			const option = {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
			};
			const formatted = date.toLocaleDateString('pt-br', option);
			return <Typography align="center"> {formatted}</Typography>;
		},
	},
	{
		headerText: 'Id',
		key: 'id',
		CustomValue: (value) => <TextField variant="outlined" value={value} />,
	},
	{
		headerText: 'Situação',
		key: 'status',
		CustomValue: (status) => {
			if (status === 'Pendente') {
				return (
					<Typography
						style={{
							color: '#dfad06',
							borderRadius: '27px',
						}}
					>
						PENDENTE
					</Typography>
				);
			}
			if (status === 'Concluido') {
				return (
					<Typography
						style={{
							color: 'green',
						}}
					>
						CONCLUIDO
					</Typography>
				);
			}
		},
	},
	{
		headerText: 'Visualizar',
		key: 'url',
		CustomValue: (url) => (
			<GradientButton
				buttonText="Visualizar"
				onClick={() => window.open(`${url}`, 'Boleto', 'height=1000,width=1000')}
			/>
		),
	},
];

const PaymentBookletDetails = () => {
	const { id } = useParams();
	const token = useAuth();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		dispatch(loadCarneId(token, id));
	}, []);

	const carneId = useSelector((state) => state.carne);
	const date = new Date(carneId.created_at);
	const option = {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	};
	const formatted = date.toLocaleDateString('pt-br', option);

	const handleVisualizar = async () => {
		setLoading(true);
		const res = await dispatch(loadCarneIdBoleto(token, id));
		if (res.data && res && res.status === 200) {
			const newWindow = window.open('', 'Carnê', 'height=1000,width=1000');
			await newWindow.document.write(res.data);
			setLoading(false);
		} else {
			toast.error('Erro ao visualizar');
			setLoading(false);
		}
	};

	if (carneId.boleto === undefined) {
		return <LinearProgress />;
	} else
		return (
			<Box display="flex" flexDirection="column">
				<CustomBreadcrumbs path1="Carnê" to1="/dashboard/carne" path2="Detalhes do carnê" />
				<LoadingScreen isLoading={loading} />
				<Paper
					style={{
						padding: '24px',
						marginTop: '12px',
						borderRadius: '27px',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Typography variant="h4"> Detalhes do Carnê </Typography>

					<Box display="flex">
						<Box display="flex" flexDirection="column" style={{ width: '100%' }}>
							<Typography variant="h6" align="center">
								Criado em: {formatted}
							</Typography>
							<CustomTable data={carneId.boleto ? carneId.boleto : [{}, {}]} columns={columns} />
						</Box>
						<Box display="flex" flexDirection="column" style={{ marginLeft: '20px', width: '100%' }}>
							<Box
								style={{
									padding: '12px',
									borderRadius: '27px 27px 0 0 ',
									backgroundColor: '#08e1ae',
									color: 'white',
								}}
							>
								<Typography variant="h6" align="center">
									Carne de {carneId.boleto.length} parcelas
								</Typography>
							</Box>
							<Box display="flex" marginTop="6px" flexDirection="column">
								<Typography variant="h6">
									Id: <TextField variant="outlined" style={{ width: '50%' }} value={carneId.id} />
								</Typography>
								<Typography variant="h6">Valor total: R${carneId.valor}</Typography>
								<Divider style={{ marginTop: '6px' }} />
								<Typography variant="h6" align="center">
									Pagador
								</Typography>
								<Typography>Nome:</Typography>
								<Typography variant="h6">
									{carneId.pagador.nome ? carneId.pagador.nome : '-'}
								</Typography>
								<Typography>Documento:</Typography>
								<Typography variant="h6">
									{carneId.pagador.documento !== '..-' ? carneId.pagador.documento : '-'}
								</Typography>
								<Typography>E-mail:</Typography>
								<Typography variant="h6">
									{carneId.pagador.email ? carneId.pagador.email : '-'}
								</Typography>
								<Typography>Contato:</Typography>
								<Typography variant="h6">
									{carneId.pagador.celular ? carneId.pagador.celular : '-'}
								</Typography>
							</Box>
							<GradientButton
								onClick={handleVisualizar}
								style={{ marginTop: '6px' }}
								buttonText="Visualizar Carnê"
							/>
						</Box>
					</Box>
				</Paper>
			</Box>
		);
};

export default PaymentBookletDetails;
