import { Box, Button, Grid, Paper, TextField, Typography, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';

import LoadingScreen from '../../../../reusable/LoadingScreen/LoadingScreen';
import { postUsuarioConta } from '../../../../../actions/actions';
import { toast } from 'react-toastify';
import useAuth from '../../../../../hooks/useAuth';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	paper: {
		marginBottom: theme.spacing(6),
		padding: theme.spacing(3),
		borderRadius: '27px',
		width: '850px',
		alignSelf: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
}));

const NewUser = () => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const { id } = useParams();
	const dispatch = useDispatch();
	const token = useAuth();
	const history = useHistory();

	const [usuario, setUsuario] = useState({
		conta_id: id,
		email: '',
	});

	const handleCreateUser = async () => {
		setLoading(true);
		const resUser = await dispatch(postUsuarioConta(token, usuario));

		if (resUser) {
			toast.error('Erro ao cadastrar novo usuario');
			setLoading(false);
		} else {
			toast.success('Usuario cadastrado com sucesso');
			history.goBack();
			setLoading(false);
		}
	};

	return (
		<Box className={classes.root}>
			<LoadingScreen isLoading={loading} />
			<Typography style={{ marginTop: '8px' }} variant="h4">
				Criar Novo Usuário
			</Typography>
			<Paper className={classes.paper}>
				<Box display="flex" flexDirection="column" alignItems="center">
					<Typography></Typography>
					<Grid item xs={12} sm={6}>
						<Typography variant="h6">Digite o email do usuário</Typography>
						<TextField
							variant="outlined"
							value={usuario.email}
							onChange={(e) =>
								setUsuario({
									...usuario,
									email: e.target.value,
								})
							}
							fullWidth
							required
							label="E-mail"
							type="email"
						/>
					</Grid>
					<Box alignSelf="center" marginTop="12px">
						<Button
							variant="contained"
							color="secondary"
							style={{ marginTop: '12px', color: 'white' }}
							onClick={handleCreateUser}
						>
							Criar usuário
						</Button>
					</Box>
				</Box>
			</Paper>
		</Box>
	);
};

export default NewUser;
