import { Box, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
	faBarcode,
	faCheck,
	faCopy,
	faCreditCard,
	faDesktop,
	faDollarSign,
	faForward,
	faHandHoldingUsd,
	faHistory,
	faLink,
	faMobile,
	faSignOutAlt,
	faUndo,
	faUsers,
} from '@fortawesome/free-solid-svg-icons';

import AccountCollectionItem from './AccountCollectionItem/AccountCollectionItem';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
	accountCollectionContainer: {
		width: '60%',
		display: 'flex',
		height: '100%',
		flexDirection: 'column',
		color: theme.palette.primary.main,
		[theme.breakpoints.down(850)]: {
			width: '100%',
		},
	},
}));

const AccountCollections = ({
	cartao,
	boleto,
	carne,
	assinaturas,
	cobranca,
	link,
	extrato,
	historicoTransacoes,
	lancamentosFuturos,
	realizarTransferencia,
	historicoTransferencia,
	criarContaDigital,
	pagadores,
	solicitarCartao,
	cartoesPre,
	terminais,
	area,
	todos,
}) => {
	const classes = useStyles();
	const userPermissao = useSelector((state) => state.userPermissao);
	const [permissoes, setPermissoes] = useState([]);

	useEffect(() => {
		const { permissao } = userPermissao;
		setPermissoes(permissao.map((item) => item.tipo));
	}, [userPermissao]);

	useEffect(() => {
		return () => {
			setPermissoes([]);
		};
	}, []);

	const Aviso = () => {
		return (
			<Box display="flex" flexDirection="column">
				<Typography align="center">Prezado cliente,</Typography>
				<Typography align="justify" style={{ margin: '30px 0px 30px 0px' }}>
					Para atender às normas do Banco Central do Brasil (Bacen), no que diz respeito às transferência
					de valores entre terceiros, via conta de pagamento, as mesmas a partir desta data 06/04/2021,
					não poderão ser realizadas, sendo autorizado somente as transferências para outros bancos desde
					que sejam da mesma titularidade do responsável pela transação.
				</Typography>
				<Typography align="justify">
					Certos de sua colaboração, nos colocamos à disposição para o esclarecimento de eventuais
					dúvidas.
				</Typography>
			</Box>
		);
	};

	if (todos) {
		return (
			<Box className={classes.accountCollectionContainer}>
				<Typography variant="h6">{area}</Typography>
				<Box display="flex">
					{cartao ? (
						<AccountCollectionItem
							link={permissoes.includes('Cobranca - Cartao') ? 'cobrancas-credito' : null}
							text="Máquina Virtual"
							icon={faCreditCard}
						/>
					) : null}

					{boleto ? (
						<AccountCollectionItem
							link={permissoes.includes('Cobranca - Boleto') ? 'boleto' : null}
							text="Boleto"
							icon={faBarcode}
						/>
					) : null}

					{carne ? (
						<AccountCollectionItem
							link={permissoes.includes('Cobranca - Carne') ? 'carne' : null}
							text="Carnê"
							icon={faCopy}
						/>
					) : null}
				</Box>
				<Box display="flex">
					{link ? (
						<AccountCollectionItem
							link={permissoes.includes('Cobranca - Link Pagamento') ? 'link-pagamento' : null}
							text="Link de Pagamento"
							icon={faLink}
						/>
					) : null}

					{lancamentosFuturos ? (
						<AccountCollectionItem
							link={
								permissoes.includes('Financa - Lancamento Funturos') ? 'lancamentos-futuros' : null
							}
							text="Lançamentos Futuros"
							icon={faForward}
						/>
					) : null}

					{extrato ? (
						<AccountCollectionItem
							link={permissoes.includes('Financa - Extrato') ? 'extrato' : null}
							text="Extrato"
							icon={faDollarSign}
						/>
					) : null}
				</Box>

				<Box display="flex">
					{assinaturas ? (
						<AccountCollectionItem
							link={permissoes.includes('Cobranca - Assinatura') ? 'assinaturas' : null}
							text="Cobrança Recorrente"
							icon={faUndo}
						/>
					) : null}
					{historicoTransacoes ? (
						<AccountCollectionItem
							link={
								permissoes.includes('Financa - Historico Transacoes')
									? 'historico-de-transacoes'
									: null
							}
							text="Transações"
							icon={faHistory}
						/>
					) : null}
				</Box>
			</Box>
		);
	} else {
		return (
			<Box className={classes.accountCollectionContainer}>
				<Typography variant="h6">{area}</Typography>
				<Box display="flex">
					{cartao ? (
						<AccountCollectionItem
							link={permissoes.includes('Cobranca - Cartao') ? 'cobrancas-credito' : null}
							text="Máquina Virtual"
							icon={faCreditCard}
						/>
					) : null}

					{boleto ? (
						<AccountCollectionItem
							link={permissoes.includes('Cobranca - Boleto') ? 'boleto' : null}
							text="Boleto"
							icon={faBarcode}
						/>
					) : null}

					{carne ? (
						<AccountCollectionItem
							link={permissoes.includes('Cobranca - Carne') ? 'carne' : null}
							text="Carnê"
							icon={faCopy}
						/>
					) : null}
				</Box>
				<Box display="flex">
					{assinaturas ? (
						<AccountCollectionItem
							link={permissoes.includes('Cobranca - Assinatura') ? 'assinaturas' : null}
							text="Cobrança Recorrente"
							icon={faUndo}
						/>
					) : null}

					{link ? (
						<AccountCollectionItem
							link={permissoes.includes('Cobranca - Link Pagamento') ? 'link-pagamento' : null}
							text="Link de Pagamento"
							icon={faLink}
						/>
					) : null}

					{cobranca ? (
						<AccountCollectionItem
							onClick={() => toast.warning('Em desenvolvimento')}
							text="Solicitações de Cobrança"
							icon={faHandHoldingUsd}
						/>
					) : null}
				</Box>

				<Box display="flex">
					{extrato ? (
						<AccountCollectionItem
							link={permissoes.includes('Financa - Extrato') ? 'extrato' : null}
							text="Extrato"
							icon={faDollarSign}
						/>
					) : null}
					{historicoTransacoes ? (
						<AccountCollectionItem
							link={
								permissoes.includes('Financa - Historico Transacoes')
									? 'historico-de-transacoes'
									: null
							}
							text="Histórico de Transações"
							icon={faHistory}
						/>
					) : null}
					{lancamentosFuturos ? (
						<AccountCollectionItem
							link={
								permissoes.includes('Financa - Lancamento Funturos') ? 'lancamentos-futuros' : null
							}
							text="Lançamentos Futuros"
							icon={faForward}
						/>
					) : null}
				</Box>
				<Box display="flex">
					{realizarTransferencia ? (
						<AccountCollectionItem
							/* link={
								permissoes.includes('Transferencia - Realizar Transferencia')
									? 'nova-transferencia'
									: null
							} */
							onClick={() =>
								toast.warning(Aviso, {
									position: toast.POSITION.TOP_CENTER,
									autoClose: false,
								})
							}
							text="Realizar Transferência"
							icon={faSignOutAlt}
						/>
					) : null}
					{historicoTransferencia ? (
						<AccountCollectionItem
							link={
								permissoes.includes('Transferencia - Historico Transferencia')
									? 'historico-transferencia'
									: null
							}
							text="Histórico Transferências"
							icon={faCheck}
						/>
					) : null}
				</Box>

				<Box display="flex">
					{criarContaDigital ? (
						<AccountCollectionItem
							link={permissoes.includes('Outros - Contas Digitais') ? 'contas-digitais' : null}
							text="Contas Digitais"
							icon={faDesktop}
						/>
					) : null}
					{pagadores ? (
						<AccountCollectionItem
							link={permissoes.includes('Outros - Pagadores') ? 'pagadores' : null}
							text="Pagadores"
							icon={faUsers}
						/>
					) : null}
					{solicitarCartao ? (
						<AccountCollectionItem
							onClick={() => toast.warning('Em desenvolvimento')}
							text="Solicitar Cartão"
							icon={faCreditCard}
						/>
					) : null}
				</Box>
				<Box display="flex">
					{cartoesPre ? (
						<AccountCollectionItem
							onClick={() => toast.warning('Em desenvolvimento')}
							text="Cartões Pré Pagados"
							icon={faCreditCard}
						/>
					) : null}
					{terminais ? (
						<AccountCollectionItem
							onClick={() => toast.warning('Em desenvolvimento')}
							text="Terminais"
							icon={faMobile}
						/>
					) : null}
				</Box>
			</Box>
		);
	}
};

export default AccountCollections;
