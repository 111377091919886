import axios from 'axios';
const REACT_QR_CODE_API_URL =
	'https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=';

export const getCep = (cep) => {
	const url = `https://viacep.com.br/ws/${cep}/json`;

	return axios({
		method: 'get',
		url,
	});
};

export const authMe = (token) => {
	const url = `${process.env.REACT_APP_API_URL}/auth/me`;
	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const postAuth = (documento, senha) => {
	const url = `${process.env.REACT_APP_API_URL}/auth/login`;

	return axios({
		method: 'post',
		url,
		data: {
			documento,
			password: senha,
		},
	});
};

export const getUserData = (token) => {
	const url = `${process.env.REACT_APP_API_URL}/perfil`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const putUserConta = (token, conta) => {
	const url = `${process.env.REACT_APP_API_URL}/perfil`;

	return axios({
		method: 'put',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			nome: conta.nome,
			razao_social: conta.razao_social,
			celular: conta.celular,
			site: conta.site,
			endereco: {
				cep: conta.endereco.cep,
				rua: conta.endereco.rua,
				numero: conta.endereco.numero,
				complemento: conta.endereco.complemento,
				bairro: conta.endereco.bairro,
				cidade: conta.endereco.cidade,
				estado: conta.endereco.estado,
			},
		},
	});
};

export const putUserPassword = (token, userPass) => {
	const url = `${process.env.REACT_APP_API_URL}/user`;

	return axios({
		method: 'put',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			password: userPass.password,
			password_confirmation: userPass.password_confirmation,
		},
	});
};

export const postFirstAcess = (user) => {
	const url = `${process.env.REACT_APP_API_URL}/user/criar-senha`;

	return axios({
		method: 'post',
		url,
		data: {
			email: user.email,
			token: user.token,
			password: user.password,
			password_confirmation: user.password_confirmation,
		},
	});
};

export const getLinkPagamentos = (token, page) => {
	const url = `${process.env.REACT_APP_API_URL}/link-pagamento?page=${page}`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getLancamentosFuturos = (token, page, data_liberacao) => {
	const url = `${process.env.REACT_APP_API_URL}/lancamento-futuro?page=${page}&data_liberacao=${data_liberacao}`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getLinkPagamentoId = (token, id) => {
	// tem que ser sem autenticacao
	const url = `${process.env.REACT_APP_API_URL}/link-pagamento/${id}`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const postLinkPagamento = (token, linkPagamento) => {
	const url = `${process.env.REACT_APP_API_URL}/link-pagamento`;

	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			valor: linkPagamento.valor,
			limite_parcelas: linkPagamento.limite_parcelas,
			vencimento: linkPagamento.vencimento,
			quantidade_utilizacoes: linkPagamento.quantidade_utilizacoes,
			senha: linkPagamento.senha,
			numero_pedido: linkPagamento.numero_pedido,
			descricao: linkPagamento.descricao,
			status: linkPagamento.status,
			pagador_id: linkPagamento.pagador_id,
			pagamento: linkPagamento.pagamento,
		},
	});
};

export const getLinkPagamentosFilter = (
	token,
	page,
	like,
	order,
	mostrar,
	conta_id
) => {
	const url = `${process.env.REACT_APP_API_URL}/link-pagamento?page=${page}&like=${like}&order=${order}&mostrar=${mostrar}&conta_id=${conta_id}`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const postLinkPagamentoPagar = (linkPagamentoPagar, id) => {
	const url = `${process.env.REACT_APP_API_URL}/link-pagamento/${id}/pagar`;

	return axios({
		method: 'post',
		url,
		data: {
			pagador_id: linkPagamentoPagar.pagador_id,
			parcelas: linkPagamentoPagar.parcelas,
			cartao: {
				nome: linkPagamentoPagar.cartao.nome,
				numero: linkPagamentoPagar.cartao.numero,
				cvv: linkPagamentoPagar.cartao.cvv,
				mes: linkPagamentoPagar.cartao.mes,
				ano: linkPagamentoPagar.cartao.ano,
			},
		},
	});
};

export const postCobrancaCartao = (token, cobrancaCartao) => {
	const url = `${process.env.REACT_APP_API_URL}/cartao`;

	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			pagador_id: cobrancaCartao.pagador_id,
			parcelas: cobrancaCartao.parcelas,
			valor: cobrancaCartao.valor,
			captura: cobrancaCartao.captura,
			cartao: {
				nome: cobrancaCartao.cartao.nome,
				numero: cobrancaCartao.cartao.numero,
				cvv: cobrancaCartao.cartao.cvv,
				mes: cobrancaCartao.cartao.mes,
				ano: cobrancaCartao.cartao.ano,
			},
		},
	});
};
export const postCobrancaCartaoSimples = (token, cobrancaCartao) => {
	const url = `${process.env.REACT_APP_API_URL}/simples/cartao`;

	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			documento: cobrancaCartao.documento,
			celular: cobrancaCartao.celular,
			parcelas: cobrancaCartao.parcelas,
			valor: cobrancaCartao.valor,
			captura: cobrancaCartao.captura,
			cartao: {
				nome: cobrancaCartao.cartao.nome,
				numero: cobrancaCartao.cartao.numero,
				cvv: cobrancaCartao.cartao.cvv,
				mes: cobrancaCartao.cartao.mes,
				ano: cobrancaCartao.cartao.ano,
			},
		},
	});
};

export const getBoletos = (token, page) => {
	const url = `${process.env.REACT_APP_API_URL}/boleto?page=${page}`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getExtratoPage = (token, page) => {
	const url = `${process.env.REACT_APP_API_URL}/extrato?page=${page}`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getExtratoFilters = (
	token,
	page,
	id,
	day,
	order,
	mostrar,
	tipo,
	conta_id
) => {
	const url = `${process.env.REACT_APP_API_URL}/extrato?page=${page}&day=${day}&id=${id}&order=${order}&mostrar=${mostrar}&tipo=${tipo}&conta_id=${conta_id}`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getBoletoId = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/boleto/${id}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getBoletosFilter = (
	token,
	page,
	like,
	order,
	mostrar,
	conta_id
) => {
	const url = `${process.env.REACT_APP_API_URL}/boleto?page=${page}&like=${like}&order=${order}&mostrar=${mostrar}&conta_id=${conta_id}`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const postBoletos = (token, boleto) => {
	const url = `${process.env.REACT_APP_API_URL}/boleto`;

	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			pagador_id: boleto.pagador_id,
			valor: boleto.valor,
			descricao: boleto.descricao,
			instrucao1: boleto.instrucao1,
			instrucao2: boleto.instrucao2,
			instrucao3: boleto.instrucao3,
			data_vencimento: boleto.data_vencimento,
			tipo_multa: boleto.tipo_multa,
			valor_multa: boleto.valor_multa,
			tipo_juros: boleto.tipo_juros,
			valor_juros: boleto.valor_juros,
			tipo_desconto: boleto.tipo_desconto,
			valor_desconto: boleto.valor_desconto,
			data_limite_valor_desconto: boleto.data_limite_valor_desconto,
		},
	});
};

export const postPagador = (pagador) => {
	const url = `${process.env.REACT_APP_API_URL}/pagador`;

	return axios({
		method: 'post',
		url,
		data: {
			conta_id: pagador.conta_id,
			documento: pagador.documento,
			nome: pagador.nome,
			celular: pagador.celular,
			data_nascimento: pagador.data_nascimento,
			email: pagador.email,
			endereco: {
				cep: pagador.endereco.cep,
				rua: pagador.endereco.rua,
				numero: pagador.endereco.numero,
				complemento: pagador.endereco.complemento,
				bairro: pagador.endereco.bairro,
				cidade: pagador.endereco.cidade,
				estado: pagador.endereco.estado,
			},
		},
	});
};

export const getPagadorId = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/pagador/${id}`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};
export const getPagadores = (token, page) => {
	const url = `${process.env.REACT_APP_API_URL}/pagador?page=${page}`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const deletePagador = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/pagador/${id}`;

	return axios({
		method: 'delete',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const deleteConta = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/conta/${id}`;

	return axios({
		method: 'delete',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const putPagador = (token, pagador, id) => {
	const url = `${process.env.REACT_APP_API_URL}/pagador/${id}`;

	return axios({
		method: 'put',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			conta_id: pagador.conta_id,
			documento: pagador.documento,
			nome: pagador.nome,
			celular: pagador.celular,
			data_nascimento: pagador.data_nascimento,
			email: pagador.email,
			endereco: {
				cep: pagador.endereco.cep,
				rua: pagador.endereco.rua,
				numero: pagador.endereco.numero,
				complemento: pagador.endereco.complemento,
				bairro: pagador.endereco.bairro,
				cidade: pagador.endereco.cidade,
				estado: pagador.endereco.estado,
			},
		},
	});
};

export const getPagadoresFilter = (
	token,
	page,
	like,
	order,
	mostrar,
	conta_id
) => {
	const url = `${process.env.REACT_APP_API_URL}/pagador?page=${page}&like=${like}&order=${order}&mostrar=${mostrar}&conta_id=${conta_id}`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

// Requisicoes ADM

export const getContas = (token, page) => {
	const url = `${process.env.REACT_APP_API_URL}/contas?page=${page}`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};
export const getContasAll = (token) => {
	const url = `${process.env.REACT_APP_API_URL}/contas`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getContaId = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/conta/${id}`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const postContas = (token, conta) => {
	const url = `${process.env.REACT_APP_API_URL}/conta`;

	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			documento: conta.documento,
			nome: conta.nome,
			razao_social: conta.razao_social,
			cnpj: conta.cnpj,
			celular: conta.celular,
			data_nascimento: conta.data_nascimento,
			email: conta.email,
			site: conta.site,
			endereco: {
				cep: conta.endereco.cep,
				rua: conta.endereco.rua,
				numero: conta.endereco.numero,
				complemento: conta.endereco.complemento,
				bairro: conta.endereco.bairro,
				cidade: conta.endereco.cidade,
				estado: conta.endereco.estado,
			},
		},
	});
};

export const putConta = (token, conta, id) => {
	const url = `${process.env.REACT_APP_API_URL}/conta/${id}`;

	return axios({
		method: 'put',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			nome: conta.nome,
			razao_social: conta.razao_social,
			celular: conta.celular,
			site: conta.site,
			endereco: {
				cep: conta.endereco.cep,
				rua: conta.endereco.rua,
				numero: conta.endereco.numero,
				complemento: conta.endereco.complemento,
				bairro: conta.endereco.bairro,
				cidade: conta.endereco.cidade,
				estado: conta.endereco.estado,
			},
		},
	});
};

export const getContasFilter = (token, page, like, mostrar) => {
	const url = `${process.env.REACT_APP_API_URL}/pagador?page=${page}&like=${like}&mostrar=${mostrar}`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getUserConta = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/user/${id}`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const deleteUser = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/user/${id}`;

	return axios({
		method: 'delete',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getUserContaFilters = (token, page, like, order, mostrar) => {
	const url = `${process.env.REACT_APP_API_URL}/user?page=${page}&like=${like}&order=${order}&mostrar=${mostrar}`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const postUserConta = (token, user) => {
	const url = `${process.env.REACT_APP_API_URL}/user`;

	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			conta_id: user.conta_id,
			email: user.email,
		},
	});
};

export const getBancos = (token) => {
	const url = `${process.env.REACT_APP_API_URL}/banco`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const postContaBancaria = (token, conta, conta_id) => {
	const url = `${process.env.REACT_APP_API_URL}/conta-bancaria?conta_id=${conta_id}`;
	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			conta_id: conta.conta_id,
			banco: conta.banco,
			agencia: conta.agencia,
			tipo: conta.tipo,
			conta: conta.conta,
		},
	});
};

export const getContaBancaria = (token, conta_id) => {
	const url = `${process.env.REACT_APP_API_URL}/conta-bancaria?conta_id=${conta_id}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const deleteContaBancaria = (token, id, conta_id) => {
	const url = `${process.env.REACT_APP_API_URL}/conta-bancaria/${id}`;
	return axios({
		method: 'delete',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};
export const getHistoricoTransacaoFilters = (
	token,
	page,
	day,
	order,
	mostrar,
	status,
	like,
	payment_type,
	data_inicial,
	data_final,
	id,
	documento,
	vencimento_inicial,
	vencimento_final,
	pagamento_inicial,
	pagamento_final,
	conta_id
) => {
	const url = `${process.env.REACT_APP_API_URL}/transacao?page=${page}&id=${id}&day=${day}&order=${order}&mostrar=${mostrar}&status=${status}&like=${like}&payment_type=${payment_type}&documento=${documento}&data_inicial=${data_inicial}&data_final=${data_final}&vencimento_inicial=${vencimento_inicial}&vencimento_final=${vencimento_final}&conta_id=${conta_id}&pagamento_inicial=${pagamento_inicial}&pagamento_final=${pagamento_final}`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getHistoricoTransacao = (token, page) => {
	const url = `${process.env.REACT_APP_API_URL}/transacao?page=${page}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getTransacaoId = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/transacao/${id}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const enviarBoleto = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/enviar/${id}/boleto`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const postSaque = (token, saqueInfos) => {
	const url = `${process.env.REACT_APP_API_URL}/saque`;
	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			conta_bancaria_id: saqueInfos.conta_bancaria_id,
			valor: saqueInfos.valor,
			descricao: saqueInfos.descricao,
		},
	});
};

export const getHistoricoTransferencia = (token, page) => {
	const url = `${process.env.REACT_APP_API_URL}/transferencia?page=${page}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getHistoricoTransferenciaFilters = (
	token,
	page,
	like,
	valor,
	data,
	conta_id
) => {
	const url = `${process.env.REACT_APP_API_URL}/transferencia?page=${page}&like=${like}&valor=${valor}&data=${data}&conta_id=${conta_id}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const postTransferencia = (token, transferenciaInfos) => {
	const url = `${process.env.REACT_APP_API_URL}/transferencia`;
	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			conta_destino_id: transferenciaInfos.conta_destino_id,
			valor: transferenciaInfos.valor,
			descricao: transferenciaInfos.descricao,
		},
	});
};

export const postSenhaLink = (senha, id) => {
	const url = `${process.env.REACT_APP_API_URL}/senha/link-pagamento`;
	return axios({
		method: 'post',
		url,
		data: {
			link_pagamento_id: id,
			senha: senha,
		},
	});
};

export const getCarne = (token, page) => {
	const url = `${process.env.REACT_APP_API_URL}/carne?page=${page}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getCarneFilters = (
	token,
	page,
	like,
	order,
	mostrar,
	conta_id
) => {
	const url = `${process.env.REACT_APP_API_URL}/carne?page=${page}&like=${like}&order=${order}&mostrar=${mostrar}&conta_id=${conta_id}`;

	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getCarneId = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/carne/${id}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getCarneIdBoleto = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/carne/${id}/boleto`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const deleteCarneId = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/carne/${id}`;
	return axios({
		method: 'delete',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const postCarne = (token, carne) => {
	const url = `${process.env.REACT_APP_API_URL}/carne`;
	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			pagador_id: carne.pagador_id,
			valor: carne.valor,
			parcelas: carne.parcelas,
			descricao: carne.descricao,
			instrucao1: carne.instrucao1,
			instrucao2: carne.instrucao2,
			instrucao3: carne.instrucao3,
			data_vencimento: carne.data_vencimento,
			tipo_multa: carne.tipo_multa,
			valor_multa: carne.valor_multa,
			tipo_juros: carne.tipo_juros,
			valor_juros: carne.valor_juros,
			tipo_desconto: carne.tipo_desconto,
			valor_desconto: carne.valor_desconto,
			data_limite_valor_desconto: carne.data_limite_valor_desconto,
		},
	});
};

export const getCobrancasCartao = (token, page) => {
	const url = `${process.env.REACT_APP_API_URL}/cartao?page=${page}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getCobrancasCartaoFilters = (
	token,
	page,
	like,
	order,
	mostrar,
	conta_id
) => {
	const url = `${process.env.REACT_APP_API_URL}/cartao?page=${page}&like=${like}&order=${order}&mostrar=${mostrar}&conta_id=${conta_id}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const postCapturaCobranca = (token, id, valor) => {
	const url = `${process.env.REACT_APP_API_URL}/cartao/${id}/captura`;
	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			valor: valor,
		},
	});
};

export const postCobrancaEstornar = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/cartao/${id}/estornar`;
	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getPlanos = (token, page) => {
	const url = `${process.env.REACT_APP_API_URL}/plano-assinatura?page=${page}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};
export const getPlanosAll = (token) => {
	const url = `${process.env.REACT_APP_API_URL}/plano-assinatura`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getPlanosFilters = (token, page, like, order, mostrar) => {
	const url = `${process.env.REACT_APP_API_URL}/plano-assinatura?page=${page}&like=${like}&order=${order}&mostrar=${mostrar}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getPlanoId = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/plano-assinatura/${id}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const postPlano = (token, plano) => {
	const url = `${process.env.REACT_APP_API_URL}/plano-assinatura`;
	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			nome: plano.nome,
			valor: plano.valor,
			frequencia: plano.frequencia,
			descricao: plano.descricao,
			duracao: plano.duracao,
		},
	});
};

export const putPlano = (token, id, plano) => {
	const url = `${process.env.REACT_APP_API_URL}/plano-assinatura/${id}`;
	return axios({
		method: 'put',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			nome: plano.nome,
			valor: plano.valor,
			frequencia: plano.frequencia,
			descricao: plano.descricao,
			duracao: plano.duracao,
		},
	});
};

export const deletePlano = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/plano-assinatura/${id}`;
	return axios({
		method: 'delete',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};
export const getAssinaturas = (token, page) => {
	const url = `${process.env.REACT_APP_API_URL}/assinatura?page=${page}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getAssinaturasFilters = (
	token,
	page,
	like,
	plano,
	order,
	mostrar,
	conta_id
) => {
	const url = `${process.env.REACT_APP_API_URL}/assinatura?page=${page}&like=${like}&plano=${plano}&order=${order}&mostrar=${mostrar}&conta_id=${conta_id}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getAssinaturasId = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/assinatura/${id}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const postAssinaturas = (token, assinatura) => {
	const url = `${process.env.REACT_APP_API_URL}/assinatura`;
	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			plano_id: assinatura.plano_id,
			pagador_id: assinatura.pagador_id,
		},
	});
};

export const putAssinaturas = (token, id, planoId) => {
	const url = `${process.env.REACT_APP_API_URL}/assinatura/${id}`;
	return axios({
		method: 'put',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			plano_id: planoId,
		},
	});
};

export const deleteAssinatura = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/assinatura/${id}`;
	return axios({
		method: 'delete',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const postCartaoAssinatura = (token, id, cartao) => {
	const url = `${process.env.REACT_APP_API_URL}/pagador/${id}/cartao`;
	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			nome: cartao.nome,
			numero: cartao.numero,
			cvv: cartao.cvv,
			mes: cartao.mes,
			ano: cartao.ano,
		},
	});
};
export const postSaqueAutomatico = (token, saqueAutomatico) => {
	const url = `${process.env.REACT_APP_API_URL}/politica-recebimento`;
	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			saque_automatico: saqueAutomatico,
		},
	});
};

export const postSplit = (token, transacao) => {
	const url = `${process.env.REACT_APP_API_URL}/split`;
	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			cobranca_boleto_ou_cartao_id: transacao.cobranca_boleto_ou_cartao_id,
			conta_id: transacao.conta_id,
			porcentagem: transacao.porcentagem,
			valor: transacao.valor,
			responsavel_pelo_prejuizo: transacao.responsavel_pelo_prejuizo,
			usar_valor_liquido: transacao.usar_valor_liquido,
		},
	});
};

export const getResumoTransacao = (token) => {
	const url = `${process.env.REACT_APP_API_URL}/transacao-mes`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getTokens = (token) => {
	const url = `${process.env.REACT_APP_API_URL}/tokens`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const postTokens = (token, tokenInfos) => {
	const url = `${process.env.REACT_APP_API_URL}/tokens/create`;
	var bodyFormData = new FormData();
	bodyFormData.append('token_name', tokenInfos);
	return axios({
		method: 'post',
		url,
		headers: {
			ContentType: `multipart/form-data`,
			Authorization: `Bearer ${token}`,
		},
		data: bodyFormData,
	});
};

export const deleteTokens = (token, index) => {
	const url = `${process.env.REACT_APP_API_URL}/tokens/${index}`;
	return axios({
		method: 'delete',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const postDocumentos = (token, documento, categoria, descricao) => {
	const url = `${process.env.REACT_APP_API_URL}/documento`;
	var bodyFormData = new FormData();
	bodyFormData.append('arquivo', documento);
	bodyFormData.append('categoria', categoria);
	bodyFormData.append('descricao', descricao);
	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: bodyFormData,
	});
};

export const postDocumentosAdm = (
	token,
	documento,
	categoria,
	conta_id,
	descricao
) => {
	const url = `${process.env.REACT_APP_API_URL}/documento?conta_id=${conta_id}`;
	var bodyFormData = new FormData();
	bodyFormData.append('arquivo', documento);
	bodyFormData.append('categoria', categoria);
	bodyFormData.append('descricao', descricao);
	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: bodyFormData,
	});
};

export const getDocumento = (token, conta_id) => {
	const url = `${process.env.REACT_APP_API_URL}/documento?conta_id=${conta_id}&mostrar=100`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const deleteDocumento = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/documento/${id}`;
	return axios({
		method: 'delete',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getPermissao = (token, id) => {
	const url = `${process.env.REACT_APP_API_URL}/permissao/${id}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const postPermissao = (token, id, tipoPermissao) => {
	const url = `${process.env.REACT_APP_API_URL}/permissao`;
	return axios({
		method: 'post',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			tipo: tipoPermissao,
			user_id: id,
		},
	});
};

export const deletePermissao = (token, id, tipoPermissao) => {
	const url = `${process.env.REACT_APP_API_URL}/permissao`;
	return axios({
		method: 'delete',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			tipo: tipoPermissao,
			user_id: id,
		},
	});
};

export const getContaDigitalDocumento = (token, documento) => {
	const url = `${process.env.REACT_APP_API_URL}/conta/buscar?documento=${documento}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getExportHistoricoTransacao = (
	token,
	page,
	day,
	order,
	mostrar,
	status,
	like,
	payment_type,
	data_inicial,
	data_final,
	id,
	documento,
	vencimento_inicial,
	vencimento_final,
	pagamento_inicial,
	pagamento_final,
	conta_id
) => {
	const url = `${process.env.REACT_APP_API_URL}/export/transacao?page=${page}&id=${id}&day=${day}&order=${order}&mostrar=${mostrar}&status=${status}&like=${like}&payment_type=${payment_type}&documento=${documento}&data_inicial=${data_inicial}&data_final=${data_final}&vencimento_inicial=${vencimento_inicial}&vencimento_final=${vencimento_final}&conta_id=${conta_id}&pagamento_inicial=${pagamento_inicial}&pagamento_final=${pagamento_final}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getExportExtrato = (
	token,
	page,
	id,
	day,
	order,
	mostrar,
	tipo,
	conta_id
) => {
	const url = `${process.env.REACT_APP_API_URL}/export/extrato?page=${page}&day=${day}&id=${id}&order=${order}&mostrar=${mostrar}&tipo=${tipo}&conta_id=${conta_id}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getExportHistoricoTransferencia = (
	token,
	page,
	like,
	valor,
	data,
	conta_id
) => {
	const url = `${process.env.REACT_APP_API_URL}/export/transferencia?page=${page}&like=${like}&valor=${valor}&data=${data}&conta_id=${conta_id}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};
export const getReenviarToken = (token, conta_id) => {
	const url = `${process.env.REACT_APP_API_URL}/reenviar_token/${conta_id}`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const getExtratoResumo = (token) => {
	const url = `${process.env.REACT_APP_API_URL}/extrato-resumo`;
	return axios({
		method: 'get',
		url,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const gerarImagemQrCode = (qrCodeString) => {
	const url = `${REACT_QR_CODE_API_URL}${qrCodeString}`;
	return axios({
		method: 'get',
		url,
	});
};

export const showQrCode = (id) => {
	const url = `${process.env.REACT_APP_API_URL}/conta/qr-code-link/${id}`;
	return axios({
		method: 'get',
		url,
	});
};
