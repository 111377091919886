import {
	Box,
	Button,
	Divider,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Typography,
	makeStyles,
	useTheme,
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import React, { useState } from 'react';

import { AiOutlineBarcode } from 'react-icons/ai';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CustomButton from '../CustomButton/CustomButton';
import ListItems from '../SideBar/ListItems/ListItems';
import { MdCollectionsBookmark } from 'react-icons/md';
import MenuIcon from '@material-ui/icons/Menu';
import UserMenu from '../SideBar/UserMenu/UserMenu';
import clsx from 'clsx';
import { toast } from 'react-toastify';

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
	root: {},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
		backgroundColor: theme.palette.background.default,
	},
	drawerHeader: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		justifyContent: 'flex-end',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: -drawerWidth,
	},
	contentShift: {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	},
}));

const DrawerSideBar = () => {
	const classes = useStyles();
	const theme = useTheme();
	const [open, setOpen] = useState(false);
	const history = useHistory();
	const menu = [
		{ text: 'Home', icon: 'home', to: '/' },
		{ text: 'Pix', icon: 'api', /*  to: '', */ onClick: () => toast.error('Em desenvolvimento') },
		{
			text: 'Transferir',
			icon: 'import_export',
			/*  to: '', */ onClick: () => toast.error('Em desenvolvimento'),
		},
		{
			text: 'Depositar',
			icon: 'arrow_downward',
			/*  to: '', */ onClick: () => toast.error('Em desenvolvimento'),
		},
		{
			text: 'Pagar',
			icon: 'attach_money',
			/*  to: '', */ onClick: () => toast.error('Em desenvolvimento'),
		},
		{ text: 'Cartão', icon: 'credit_card', to: 'cobrancas-credito', onClick: () => setOpen(false) },
		{
			text: 'Boleto',
			to: 'boleto',
			IconComponent: () => (
				<AiOutlineBarcode style={{ color: theme.palette.secondary.main, fontSize: '1.8rem' }} />
			),
			onClick: () => setOpen(false),
		},
		{
			text: 'Carnê',
			to: 'carne',
			IconComponent: () => (
				<MdCollectionsBookmark style={{ color: theme.palette.secondary.main, fontSize: '1.8rem' }} />
			),
			onClick: () => setOpen(false),
		},
		{ text: 'Cobrança Recorrente', icon: 'sync', to: 'assinaturas', onClick: () => setOpen(false) },
		{ text: 'Link de Pagamento', icon: 'link', to: 'link-pagamento', onClick: () => setOpen(false) },
		{
			text: 'Histórico de Transação',
			icon: 'history',
			to: 'historico-de-transacoes',
			onClick: () => setOpen(false),
		},
		{ text: 'Lançamento Futuro', icon: 'update', to: 'lancamentos-futuros', onClick: () => setOpen(false) },
		{ text: 'Pagadores', icon: 'group', to: 'pagadores', onClick: () => setOpen(false) },
	];

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};
	const handleLogout = () => {
		localStorage.removeItem('@auth');
		history.push('/login');
	};
	return (
		<>
			<Box className={classes.root}>
				<IconButton
					style={{
						alignSelf: 'center',
						position: 'absolute',
						left: 15,
					}}
					aria-label="open drawer"
					onClick={handleDrawerOpen}
					edge="start"
					className={clsx(classes.menuButton, open && classes.hide)}
				>
					{<MenuIcon />}
				</IconButton>
				<Drawer
					className={classes.drawer}
					variant="temporary"
					anchor="left"
					open={open}
					classes={{
						paper: classes.drawerPaper,
					}}
				>
					<Box className={classes.drawerHeader}>
						<IconButton onClick={handleDrawerClose} color="secondary">
							{theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
						</IconButton>
					</Box>
					<Divider />
					<UserMenu />
					<List>
						{menu.map((item) => (
							<ListItem
								component={Link}
								button
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									padding: '20px',
								}}
								to={item.to}
								onClick={item.onClick ? item.onClick : () => null}
							>
								<ListItemAvatar>
									{item.icon ? (
										<span
											class="material-icons"
											style={{ color: theme.palette.secondary.main, fontSize: '1.8rem' }}
										>
											{item.icon}
										</span>
									) : (
										<item.IconComponent />
									)}
								</ListItemAvatar>
								<ListItemText>
									<Typography variant="subtitle2">{item.text}</Typography>
								</ListItemText>
							</ListItem>
						))}
					</List>

					<Box alignSelf="center" padding="20px 0">
						<CustomButton color="black" onClick={handleLogout}>
							Sair
						</CustomButton>
					</Box>
				</Drawer>
			</Box>
		</>
	);
};

export default DrawerSideBar;
