import {
	faBarcode,
	faCheck,
	faCopy,
	faCreditCard,
	faDesktop,
	faDollarSign,
	faForward,
	faHandHoldingUsd,
	faHistory,
	faLink,
	faMobile,
	faSignOutAlt,
	faUndo,
	faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import AccountCollectionItem from '../../reusable/Content/AccountCollections/AccountCollectionItem/AccountCollectionItem';
import GradientButton from '../../reusable/GradientButton/GradientButton';

const useStyles = makeStyles((theme) => ({
	responsiveWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		[theme.breakpoints.down(850)]: {
			flexDirection: 'column',
		},
	},
}));

const AdmLists = () => {
	const classes = useStyles();
	const { id } = useParams();
	const history = useHistory();
	const contaSelecionada = useSelector((state) => state.conta);

	return (
		<Box display="flex" flexDirection="column">
			<Box>
				<Box
					/* style={{ color: 'white' }} */
					display="flex"
					flexDirection="column"
					alignContent="center"
					alignItems="center"
				>
					<Typography variant="h5">Conta Selecionada: </Typography>
					<Typography>{contaSelecionada.nome ? contaSelecionada.nome : null}</Typography>
					<Typography>{contaSelecionada.razao_social ? contaSelecionada.razao_social : null}</Typography>
					<Typography>{contaSelecionada.documento ? contaSelecionada.documento : null}</Typography>
					<Typography>{contaSelecionada.cnpj ? contaSelecionada.cnpj : null}</Typography>
					<Typography>
						{contaSelecionada.saldo ? (
							<CurrencyFormat
								value={contaSelecionada.saldo.valor.replace('.', ',')}
								displayType={'text'}
								thousandSeparator={'.'}
								decimalSeparator={','}
								prefix={'R$ '}
								renderText={(value) => <div> Saldo: {value}</div>}
							/>
						) : null}
					</Typography>
				</Box>
			</Box>
			<Box className={classes.responsiveWrapper}>
				<Box display="flex">
					<AccountCollectionItem
						text="Histórico de Transações"
						link="historico-de-transacoes"
						icon={faDesktop}
					/>
					<AccountCollectionItem text="Extratos" link="extrato" icon={faDollarSign} />
					<AccountCollectionItem
						text="Histórico de Transferencias"
						link="historico-transferencia"
						icon={faHistory}
					/>
				</Box>
			</Box>
			<Box className={classes.responsiveWrapper}>
				<Box display="flex">
					<AccountCollectionItem text="Lista de Pagadores" link="pagadores" icon={faUsers} />
					<AccountCollectionItem
						text="Lista de Máquinas Virtuais"
						link="cobrancas-credito"
						icon={faCreditCard}
					/>
					<AccountCollectionItem text="Lista de Boletos" link="boleto" icon={faBarcode} />
				</Box>
			</Box>
			<Box className={classes.responsiveWrapper}>
				<Box display="flex">
					<AccountCollectionItem text="Lista de Carnês" link="carne" icon={faCopy} />
					<AccountCollectionItem
						text="Lista de Links de Pagamento"
						link="link-pagamento"
						icon={faLink}
					/>
					<AccountCollectionItem text="Cobrança Recorrente" link="assinaturas" icon={faUndo} />
				</Box>
			</Box>
			<Box className={classes.responsiveWrapper}>
				<GradientButton buttonText="Voltar" onClick={() => history.push('/dashboard/contas')} />
			</Box>
		</Box>
	);
};

export default AdmLists;
