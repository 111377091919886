import React from 'react';
import { MuiThemeProvider } from '@material-ui/core';
import { theme, cssVariables } from './constants/theme';
import Root from './components/Root/Root';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { ToastContainer } from 'react-toastify';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
	return (
		<Provider store={store}>
			<MuiThemeProvider theme={{ ...theme, ...cssVariables }}>
				<Root />
				<ToastContainer autoClose={3000} />
			</MuiThemeProvider>
		</Provider>
	);
};

export default App;
