import { Button, Typography } from '@material-ui/core';

import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	customButton: {
		boxShadow: '0px 0px 30px -3px #3D3935',
		height: '42px',
		padding: '0px 40px',
	},
	'&:hover': {
		boxShadow: '0px 0px 30px -3px #3D3935',
	},
}));

const CustomButton = (props) => {
	const classes = useStyles();
	return (
		<Button
			{...props}
			className={props.color !== 'white' ? classes.customButton : null}
			variant="contained"
			style={
				props.color === 'black'
					? { backgroundColor: '#443D38' }
					: props.color === 'yellow'
					? { backgroundColor: '#ffdc00' }
					: { backgroundColor: 'white' }
			}
		>
			<Typography
				variant="subtitle2"
				style={
					props.color === 'black'
						? { color: 'white', fontSize: '0.8rem' }
						: props.color === 'yellow'
						? { color: 'black', fontSize: '0.8rem' }
						: { color: '#443D38', fontSize: '0.8rem' }
				}
			>
				{props.children}
			</Typography>
		</Button>
	);
};

export default CustomButton;
