import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, makeStyles, Paper, Typography } from '@material-ui/core';

import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import useAuth from '../../../../hooks/useAuth';
import { loadCarneId, loadCarneIdBoleto, loadPagadorId } from '../../../../actions/actions';
import { toast } from 'react-toastify';
import LoadingScreen from '../../../reusable/LoadingScreen/LoadingScreen';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	layout: {
		width: '800px',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	paper: {
		marginBottom: theme.spacing(6),
		padding: theme.spacing(3),
		borderRadius: '27px',
		width: '850px',
		alignSelf: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
}));

const PaymentSlipFinished = ({ carneConcluido }) => {
	const classes = useStyles();
	const token = useAuth();
	const history = useHistory();
	const carneId = useSelector((state) => state.carne);
	const { valor, created_at, pagador_id, descricao, id } = carneId;
	const [loading, setLoading] = useState(false);
	const date = new Date(created_at);
	const option = {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	};
	const formatted = date.toLocaleDateString('pt-br', option);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(loadPagadorId(token, pagador_id));
	}, [pagador_id]);
	useEffect(() => {
		dispatch(loadCarneId(token, carneConcluido.id));
	}, [carneConcluido.id]);

	const pagador = useSelector((state) => state.pagador);

	const handleVoltar = () => {
		history.push('/dashboard/carne');
	};

	const handleVisualizar = async () => {
		setLoading(true);
		const res = await dispatch(loadCarneIdBoleto(token, id));
		if (res.data && res && res.status === 200) {
			const newWindow = window.open('', 'Carnê', 'height=1000,width=1000');
			await newWindow.document.write(res.data);
			setLoading(false);
		} else {
			toast.error('Erro ao visualizar');
			setLoading(false);
		}
	};

	return (
		<Box className={classes.root}>
			<LoadingScreen isLoading={loading} />
			<Paper className={classes.paper}>
				<Box display="flex" flexDirection="column">
					<Typography variant="h6" align="center">
						Carnê criado com sucesso!
					</Typography>
					<Typography variant="h6">Valor</Typography>
					<Typography variant="overline">R${valor}</Typography>
					<Divider />
					<Typography variant="h6">Data</Typography>
					<Typography variant="overline">{formatted}</Typography>
					<Divider />
					<Typography variant="h6">Descrição</Typography>
					<Typography variant="overline">{descricao}</Typography>
					<Divider />
					<Typography variant="h6">Pagador</Typography>
					<Typography variant="overline">
						{pagador.nome ? pagador.nome : pagador.razao_social}
					</Typography>
					<Typography variant="overline">{pagador.email}</Typography>
					<Typography variant="overline">{pagador.celular}</Typography>
					<Divider />

					<Typography variant="h6">Identificação do carnê </Typography>
					<Typography>{id}</Typography>
					<Box display="flex" alignSelf="flex-end">
						<Button
							variant="outlined"
							onClick={handleVoltar}
							style={{ borderRadius: '27px', marginRight: '6px' }}
						>
							Voltar
						</Button>

						<Button
							variant="contained"
							color="secondary"
							style={{ borderRadius: '27px', color: 'white' }}
							onClick={handleVisualizar}
						>
							Visualizar
						</Button>
					</Box>
				</Box>
			</Paper>
		</Box>
	);
};

export default PaymentSlipFinished;
