import {
	Box,
	Button,
	LinearProgress,
	Menu,
	MenuItem,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { delConta, loadContas, loadContasFilter } from '../../../actions/actions';
import { generatePath, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import CustomBreadcrumbs from '../../reusable/CustomBreadcrumbs/CustomBreadcrumbs';
import CustomTable from '../../reusable/CustomTable/CustomTable';
import GradientButton from '../../reusable/GradientButton/GradientButton';
import { Link } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';
import SearchBar from '../../reusable/SearchBar/SearchBar';
import useAuth from '../../../hooks/useAuth';
import useDebounce from '../../../hooks/useDebounce';

const columns = [
	{
		headerText: 'Conta',
		key: '',
		Teste: (i) => {
			return (
				<>
					<Typography>{i.nome !== undefined ? i.nome : null}</Typography>
					<Typography>
						{i.tipo === 'Pessoa Jurídica' && i.razao_social !== undefined ? i.razao_social : null}
					</Typography>
				</>
			);
		},
	},
	{
		headerText: 'Documento',
		key: '',
		Teste: (i) => {
			return (
				<>
					<Typography>{i.documento !== undefined ? i.documento : null}</Typography>
					<Typography>{i.tipo === 'Pessoa Jurídica' && i.cnpj !== undefined ? i.cnpj : null}</Typography>
				</>
			);
		},
	},
	{ headerText: 'E-mail', key: 'email' },
	{ headerText: 'Contato', key: 'celular' },
	{ headerText: 'Ações', key: 'menu' },
];

const DigitalAccounts = () => {
	const token = useAuth();
	const history = useHistory();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const [filters, setFilters] = useState({
		like: '',
		order: '',
		mostrar: '',
	});
	const dispatch = useDispatch();
	const [page, setPage] = useState(1);
	const digitalAccounts = useSelector((state) => state.contas);
	const debouncedLike = useDebounce(filters.like, 800);

	useEffect(() => {
		dispatch(loadContasFilter(token, page, debouncedLike, filters.order, filters.mostrar));
	}, [page, debouncedLike, filters.order, filters.mostrar]);

	const handleChangePage = (e, value) => {
		setPage(value);
	};

	const Editar = ({ row }) => {
		const [anchorEl, setAnchorEl] = useState(null);
		const handleClick = (event) => {
			setAnchorEl(event.currentTarget);
		};
		const handleClose = () => {
			setAnchorEl(null);
		};

		const handleExcluir = async () => {
			dispatch(delConta(token, row.id));
			setAnchorEl(null);
		};

		const handleEditar = (row) => {
			const path = generatePath('/dashboard/contas-digitais/:id/editar', {
				id: row.id,
			});
			history.push(path);
		};

		return (
			<Box>
				<Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
					<span class="material-icons" style={{ color: '#CC9B00', fontSize: '25px' }}>
						menu
					</span>
				</Button>
				<Menu
					id="simple-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<MenuItem onClick={() => handleEditar(row)}>Editar</MenuItem>
					<MenuItem onClick={() => handleExcluir(row)}>Excluir</MenuItem>
				</Menu>
			</Box>
		);
	};
	return (
		<Box display="flex" flexDirection="column">
			<CustomBreadcrumbs path1="Contas Digitais" />
			<Box display="flex" justifyContent="space-between" flexDirection={matches ? 'column' : null}>
				<Typography style={{ marginTop: '8px' }} variant="h4">
					Contas Digitais
				</Typography>

				<Link to="criar-conta-digital">
					<GradientButton buttonText="+ Nova Conta Digital" />
				</Link>
			</Box>
			<Box marginTop="16px" marginBottom="16px">
				<SearchBar
					fullWidth
					placeholder="Pesquisar por nome, documento..."
					value={filters.like}
					onChange={(e) =>
						setFilters({
							...filters,
							like: e.target.value,
						})
					}
				/>
			</Box>

			{digitalAccounts.data && digitalAccounts.per_page ? (
				<CustomTable columns={columns} data={digitalAccounts.data} Editar={Editar} />
			) : (
				<LinearProgress />
			)}
			<Box alignSelf="flex-end" marginTop="8px">
				{
					<Pagination
						variant="outlined"
						color="secondary"
						size="large"
						count={digitalAccounts.last_page}
						onChange={handleChangePage}
						page={page}
					/>
				}
			</Box>
		</Box>
	);
};

export default DigitalAccounts;
