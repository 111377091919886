import { Box, Button, makeStyles, Paper } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import useAuth from '../../../../hooks/useAuth';
import SlipRegistration from './SlipRegistration/SlipRegistration';
import NewPayer from '../../../reusable/Payer/NewPayer/NewPayer';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { postBoletosAction, postPagadores } from '../../../../actions/actions';

import { useParams } from 'react-router';
import EditPayer from '../../Payers/EditPayer/EditPayer';
import PaymentSlipFinished from './PaymentSlipFinished/PaymentSlipFinished';
import CustomBreadcrumbs from '../../../reusable/CustomBreadcrumbs/CustomBreadcrumbs';
import LoadingScreen from '../../../reusable/LoadingScreen/LoadingScreen';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '800px',
		marginLeft: 'auto',
		marginRight: 'auto',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},

	paper: {
		marginBottom: theme.spacing(6),
		marginTop: theme.spacing(3),
		padding: theme.spacing(3),
		borderRadius: '27px',
		width: '850px',
		alignSelf: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	stepper: {
		padding: theme.spacing(3, 0, 5),
		height: '60px',
	},
	buttons: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	button: {
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(1),
		color: 'white',
	},
	headerPaper: {
		padding: theme.spacing(3),
		borderRadius: '27px 0 0 0 ',
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
}));

const STEPS = {
	DADOS_DO_PAGADOR: 'Dados do pagador', //payer registration
	DADOS_DO_CARTAO: 'Dados do boleto', // slip registration
	CONCLUSAO: 'Conclusão',
};

const NewPaymentSlip = () => {
	const classes = useStyles();
	const token = useAuth();
	const dispatch = useDispatch();
	const userData = useSelector((state) => state.userData);
	const [errosPagador, setErrosPagador] = useState({});
	const [errosBoleto, setErrosBoleto] = useState({});
	const { id } = useParams();
	const [loading, setLoading] = useState(false);

	const [pagador, setPagador] = useState({
		conta_id: null,
		id: null,
		documento: '',
		nome: '',
		celular: '',
		email: '',
		data_nascimento: '',
		endereco: {
			cep: ' ',
			rua: '',
			numero: '',
			complemento: '',
			bairro: '',
			cidade: '',
			estado: '',
		},
	});

	useEffect(() => {
		setPagador({ ...pagador, conta_id: userData.id });
	}, [userData.id]);

	const [activeStep, setActiveStep] = useState(0);
	const [steps] = useState([STEPS.DADOS_DO_PAGADOR, STEPS.DADOS_DO_CARTAO, STEPS.CONCLUSAO]);

	const handleFirstClick = async () => {
		setLoading(true);
		if (id) {
			setActiveStep(activeStep + 1);
			setLoading(false);
		} else {
			let newPagador = pagador;
			setPagador(newPagador);

			const resPagador = await dispatch(postPagadores(newPagador));

			if (resPagador) {
				setErrosPagador(resPagador);
				setActiveStep(0);
				setLoading(false);
			} else {
				toast.success('Cadastro efetuado com sucesso!');
				setActiveStep(activeStep + 1);
				setLoading(false);
			}
		}
	};

	const pagadorID = useSelector((state) => state.pagador);

	const [boleto, setBoleto] = useState({
		pagador_id: '',
		valor: '',
		descricao: '',
		instrucao1: '',
		instrucao2: '',
		instrucao3: '',
		data_vencimento: '',
		tipo_multa: 1,
		valor_multa: 0,
		tipo_juros: 1,
		valor_juros: 0,
		tipo_desconto: 1,
		valor_desconto: 0,
		data_limite_valor_desconto: '',
	});

	useEffect(() => {
		setBoleto({ ...boleto, pagador_id: pagadorID.id });
	}, [pagadorID]);

	const handleSecondClick = async () => {
		setLoading(true);
		let newBoleto = boleto;
		setBoleto(newBoleto);

		const resBoleto = await dispatch(postBoletosAction(token, boleto));

		if (resBoleto) {
			setErrosBoleto(resBoleto);
			setActiveStep(1);
			setLoading(false);
		} else {
			toast.success('Boleto criado com sucesso');
			setActiveStep(activeStep + 1);
			setLoading(false);
		}
	};

	const boletoConcluido = useSelector((state) => state.boletos);

	const STEPS_COMPONENTS = {
		[STEPS.DADOS_DO_PAGADOR]: id ? (
			<EditPayer diableBreadcrumbs />
		) : (
			<NewPayer pagador={pagador} setPagador={setPagador} errosPagador={errosPagador} />
		),
		[STEPS.DADOS_DO_CARTAO]: (
			<SlipRegistration boleto={boleto} setBoleto={setBoleto} errosBoleto={errosBoleto} />
		),
		[STEPS.CONCLUSAO]: <PaymentSlipFinished boletoConcluido={boletoConcluido} />,
	};

	return (
		<>
			<CustomBreadcrumbs path1="Boletos" to1="/dashboard/boleto" path2="Novo Boleto" />
			<Box className={classes.root}>
				<Paper className={classes.paper}>
					<LoadingScreen isLoading={loading} />

					<Box className={classes.layout}>
						{STEPS_COMPONENTS[steps[activeStep]]}
						<Box className={classes.buttons}>
							{activeStep !== 2 ? (
								<Button
									style={{ borderRadius: '27px' }}
									variant="contained"
									color="secondary"
									onClick={activeStep === 0 ? handleFirstClick : handleSecondClick}
									className={classes.button}
								>
									Próximo
								</Button>
							) : null}
						</Box>
					</Box>
				</Paper>
			</Box>
		</>
	);
};

export default NewPaymentSlip;
