export const LOAD_LINK_PAGAMENTOS = 'LOAD_LINK_PAGAMENTOS';
export const LOAD_BOLETOS = 'LOAD_BOLETOS';
export const LOAD_BOLETOS_ID = 'LOAD_BOLETOS_ID';
export const LOAD_AUTH = 'LOAD_AUTH';
export const LOAD_USER_DATA = 'LOAD_USER_DATA';
export const POST_LINK_PAGAMENTOS = 'POST_LINK_PAGAMENTOS';
export const LOAD_LINK_PAGAMENTOS_ID = 'LOAD_LINK_PAGAMENTOS_ID';
export const POST_PAGADOR = 'POST_PAGADOR';
export const PAGAR_LINK_PAGAMENTO = 'PAGAR_LINK_PAGAMENTO';
export const LOAD_PAGADOR_ID = 'LOAD_PAGADOR_ID';
export const LOAD_SIGN_OUT = 'LOAD_SIGN_OUT';
export const LOAD_PAGADORES = 'LOAD_PAGADORES';
export const DEL_PAGADOR = 'DEL_PAGADOR';
export const PUT_PAGADOR = 'PUT_PAGADOR';
export const SET_STATE = ' SET_STATE';
export const LOAD_CONTAS = 'LOAD_CONTAS';
export const LOAD_USER_CONTA = 'LOAD_USER_CONTA';
export const POST_USER_CONTA = 'POST_USER_CONTA';
export const POST_CONTA = 'POST_CONTA';
export const LOAD_CONTA_ID = 'LOAD_CONTA_ID';
export const POST_BOLETOS = 'POST_BOLETOS';
export const LOAD_EXTRATO = 'LOAD_EXTRATO';
export const LOAD_LANCAMENTOS_FUTUROS = 'LOAD_LANCAMENTOS_FUTUROS';
export const UPDATE_USER_CONTA = 'UPDATE_USER_CONTA';
export const UPDATE_USER_PASS = 'UPDATE_USER_PASS';
export const LOAD_BANCOS = 'LOAD_BANCOS';
export const POST_CONTA_BANCARIA = 'POST_CONTA_BANCARIA';
export const LOAD_CONTA_BANCARIA = 'LOAD_CONTA_BANCARIA';
export const DEL_CONTA_BANCARIA = 'DEL_CONTA_BANCARIA';
export const POST_PRIMEIRO_ACESSO = 'POST_PRIMEIRO_ACESSO';
export const LOAD_HISTORICO_TRANSACAO = 'LOAD_HISTORICO_TRANSACAO';
export const LOAD_TRANSACAO = 'LOAD_TRANSACAO';
export const ENVIAR_BOLETO = 'ENVIAR_BOLETO';
export const AUTH_ME = 'AUTH_ME';
export const POST_SAQUE = 'POST_SAQUE';
export const LOAD_HISTORICO_TRANSFERENCIA = 'LOAD_HISTORICO_TRANSFERENCIA';
export const POST_TRANSFERENCIA = 'POST_TRANSFERENCIA';
export const DEL_CONTA = 'DEL_CONTA';
export const LOAD_EXTRATO_FILTER = 'LOAD_EXTRATO_FILTER';
export const LOAD_CARNE = 'LOAD_CARNE';
export const LOAD_CARNE_ID = 'LOAD_CARNE_ID';
export const DEL_CARNE = 'DEL_CARNE';
export const POST_CARNE = 'POST_CARNE';
export const POST_COBRANCA_CARTAO = 'POST_COBRANCA_CARTAO';
export const LOAD_COBRANCAS_CARTAO = 'LOAD_COBRANCAS_CARTAO';
export const POST_CAPTURA = 'POST_CAPTURA';
export const LOAD_PLANO_ID = 'LOAD_PLANO_ID';
export const LOAD_PLANOS = 'LOAD_PLANOS';
export const POST_PLANO = 'POST_PLANO';
export const PUT_PLANO = 'PUT_PLANO';
export const DEL_PLANO = 'DEL_PLANO';
export const LOAD_ASSINATURAS = 'LOAD_ASSINATURAS';
export const LOAD_ASSINATURA_ID = 'LOAD_ASSINATURA_ID';
export const POST_ASSINATURA = 'POST_ASSINATURA';
export const PUT_ASSINATURA = 'PUT_ASSINATURA';
export const DEL_ASSINATURA = 'DEL_ASSINATURA';
export const POST_CARTAO_PAGADOR = 'POST_CARTAO_PAGADOR';
export const POST_SAQUE_AUTOMATICO = 'POST_SAQUE_AUTOMATICO';
export const CLEAR_TRANSACAO = 'CLEAR_TRANSACAO';
export const POST_SLIPT = 'POST_SLIPT';
export const LOAD_RESUMO_TRANSACAO = 'LOAD_RESUMO_TRANSACAO';
export const LOAD_TOKENS = 'LOAD_TOKENS';
export const POST_TOKENS = 'POST_TOKENS';
export const DEL_TOKENS = 'DEL_TOKENS';
export const CLEAR_TOKEN = 'CLEAR_TOKEN';
export const POST_DOCUMENTO = 'POST_DOCUMENTO';
export const LOAD_PERMISSAO = 'LOAD_PERMISSAO';
export const LOAD_PERMISSAO_GERENCIAR = 'LOAD_PERMISSAO_GERENCIAR';
export const POST_PERMISSAO = 'POST_PERMISSAO';
export const DEL_PERMISSAO = 'DEL_PERMISSAO';
export const POST_AUTHME = 'POST_AUTHME';
export const DEL_USER = 'DEL_USER';
export const LOAD_CONTA_DIGITAL_DOCUMENTO = 'LOAD_CONTA_DIGITAL_DOCUMENTO';
export const LOAD_EXPORT_TRANSACAO = 'LOAD_EXPORT_TRANSACAO';
export const LOAD_EXPORT_TRANSFERENCIA = 'LOAD_EXPORT_TRANSFERENCIA';
export const LOAD_EXPORT_EXTRATO = 'LOAD_EXPORT_EXTRATO';
export const LOAD_DOCUMENTO = 'LOAD_DOCUMENTO';
export const DELETE_DOCUMENTO = 'DELETE_DOCUMENTO';
export const REENVIAR_TOKEN = 'REENVIAR_TOKEN';
export const LOAD_EXTRATO_RESUMO = 'LOAD_EXTRATO_RESUMO';
export const GERAR_QR_CODE_IMAGEM = 'GERAR_QR_CODE_IMAGEM';
export const SHOW_QR_CODE = ' SHOW_QR_CODE';
