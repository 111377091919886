import { Box, Button, Grid, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useState } from 'react';

import InputMask from 'react-input-mask';
import { getCep } from '../../../../services/services';
import { toast } from 'react-toastify';

const NewPayer = ({ pagador, setPagador, errosPagador, disableEditar, disableEditarAll }) => {
	const [pessoaJuridica, setPessoaJuridica] = useState(false);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const handlerCep = async () => {
		try {
			const response = await getCep(pagador.endereco.cep);
			setPagador({
				...pagador,
				endereco: {
					...pagador.endereco,
					cep: response.data.cep,
					rua: response.data.logradouro,
					complemento: response.data.complemento,
					bairro: response.data.bairro,
					cidade: response.data.localidade,
					estado: response.data.uf,
				},
			});
		} catch (error) {
			toast.error('Error ao puxar dados do cep');
		}
	};

	return (
		<Box display="flex" flexDirection="column" alignItems="center">
			<Typography align="center" variant="h4" style={{ marginTop: '12px', marginBottom: '12px' }}>
				Dados do pagador
			</Typography>
			<Box style={matches ? { display: 'flex', flexDirection: 'column' } : null}>
				<Button
					disabled={disableEditar || disableEditarAll}
					variant="contained"
					color="primary"
					style={{
						margin: '5px',
						borderRadius: '27px',
					}}
					onClick={() => setPessoaJuridica(false)}
				>
					Pessoa Física
				</Button>
				<Button
					disabled={disableEditar || disableEditarAll}
					style={{ margin: '5px', borderRadius: '27px' }}
					variant="contained"
					color="secondary"
					onClick={() => setPessoaJuridica(true)}
				>
					Pessoa Jurídica
				</Button>
			</Box>
			<form>
				<Box display="flex" flexDirection="column" alignItems="center" marginTop="15px">
					<Grid container spacing={3}>
						<Grid item xs={12} sm={4}>
							<InputMask
								mask={pessoaJuridica ? '99.999.999/9999-99' : '999.999.999-99'}
								value={pagador ? pagador.documento : null}
								onChange={(e) =>
									setPagador({
										...pagador,
										documento: e.target.value,
									})
								}
								disabled={disableEditar || disableEditarAll}
							>
								{() => (
									<TextField
										variant="outlined"
										error={errosPagador.documento}
										helperText={errosPagador.documento ? errosPagador.documento.join(' ') : null}
										name="documento"
										fullWidth
										required
										label={pessoaJuridica ? 'CNPJ' : 'CPF'}
										disabled={disableEditar || disableEditarAll}
									/>
								)}
							</InputMask>
						</Grid>
						<Grid item xs={12} sm={8}>
							<TextField
								variant="outlined"
								disabled={disableEditar || disableEditarAll}
								error={errosPagador.nome}
								helperText={errosPagador.nome ? errosPagador.nome.join(' ') : null}
								value={pagador ? pagador.nome : null}
								onChange={(e) =>
									setPagador({
										...pagador,
										nome: e.target.value,
									})
								}
								fullWidth
								required
								label={'Primeiro e Segundo nome'}
							/>
						</Grid>

						<Grid item xs={12} sm={4}>
							<TextField
								variant="outlined"
								disabled={disableEditar || disableEditarAll}
								error={errosPagador.data_nascimento}
								helperText={errosPagador.data_nascimento ? errosPagador.nome.join(' ') : null}
								fullWidth
								InputLabelProps={{
									shrink: true,
									pattern: 'd {4}- d {2}- d {2} ',
								}}
								type="date"
								label="Data de Nascimento"
								value={pagador ? pagador.data_nascimento : null}
								onChange={(e) =>
									setPagador({
										...pagador,
										data_nascimento: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<InputMask
								disabled={disableEditarAll}
								mask="99999999"
								maskChar=" "
								value={pagador ? pagador.endereco.cep : null}
								onChange={(e) =>
									setPagador({
										...pagador,
										endereco: {
											...pagador.endereco,
											cep: e.target.value,
										},
									})
								}
								onBlur={handlerCep}
							>
								{() => (
									<TextField
										variant="outlined"
										disabled={disableEditarAll}
										error={errosPagador['endereco.cep']}
										helperText={
											errosPagador['endereco.cep'] ? errosPagador['endereco.cep'].join(' ') : null
										}
										fullWidth
										required
										label="CEP"
									/>
								)}
							</InputMask>
						</Grid>
						<Grid item xs={12} sm={4}>
							<TextField
								variant="outlined"
								disabled={disableEditarAll}
								error={errosPagador['endereco.rua']}
								helperText={
									errosPagador['endereco.rua'] ? errosPagador['endereco.rua'].join(' ') : null
								}
								value={pagador ? pagador.endereco.rua : null}
								onChange={(e) =>
									setPagador({
										...pagador,
										endereco: {
											...pagador.endereco,
											rua: e.target.value,
										},
									})
								}
								fullWidth
								required
								label="Rua"
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<TextField
								variant="outlined"
								disabled={disableEditarAll}
								error={errosPagador['endereco.numero']}
								helperText={
									errosPagador['endereco.numero'] ? errosPagador['endereco.numero'].join(' ') : null
								}
								value={pagador ? pagador.endereco.numero : null}
								onChange={(e) =>
									setPagador({
										...pagador,
										endereco: {
											...pagador.endereco,
											numero: e.target.value,
										},
									})
								}
								fullWidth
								label="Número"
							/>
						</Grid>
						<Grid item xs={12} sm={5}>
							<TextField
								variant="outlined"
								disabled={disableEditarAll}
								error={errosPagador['endereco.bairro']}
								helperText={
									errosPagador['endereco.bairro'] ? errosPagador['endereco.bairro'].join(' ') : null
								}
								value={pagador ? pagador.endereco.bairro : null}
								onChange={(e) =>
									setPagador({
										...pagador,
										endereco: {
											...pagador.endereco,
											bairro: e.target.value,
										},
									})
								}
								fullWidth
								required
								label="Bairro"
							/>
						</Grid>
						<Grid item xs={12} sm={5}>
							<TextField
								variant="outlined"
								shrink
								disabled={disableEditarAll}
								value={pagador ? pagador.endereco.complemento : null}
								onChange={(e) =>
									setPagador({
										...pagador,
										endereco: {
											...pagador.endereco,
											complemento: e.target.value,
										},
									})
								}
								fullWidth
								label="Complemento"
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<TextField
								variant="outlined"
								disabled={disableEditarAll}
								error={errosPagador['endereco.cidade']}
								helperText={
									errosPagador['endereco.cidade'] ? errosPagador['endereco.cidade'].join(' ') : null
								}
								value={pagador ? pagador.endereco.cidade : null}
								onChange={(e) =>
									setPagador({
										...pagador,
										endereco: {
											...pagador.endereco,
											cidade: e.target.value,
										},
									})
								}
								fullWidth
								required
								label="Cidade"
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<TextField
								variant="outlined"
								disabled={disableEditarAll}
								error={errosPagador['endereco.estado']}
								helperText={
									errosPagador['endereco.estado'] ? errosPagador['endereco.estado'].join(' ') : null
								}
								value={pagador ? pagador.endereco.estado : null}
								onChange={(e) =>
									setPagador({
										...pagador,
										endereco: {
											...pagador.endereco,
											estado: e.target.value,
										},
									})
								}
								fullWidth
								required
								label="Estado"
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<InputMask
								disabled={disableEditarAll}
								mask="(99) 99999-9999"
								value={pagador ? pagador.celular : null}
								onChange={(e) =>
									setPagador({
										...pagador,
										celular: e.target.value,
									})
								}
							>
								{() => (
									<TextField
										variant="outlined"
										disabled={disableEditarAll}
										error={errosPagador.celular}
										helperText={errosPagador.celular ? errosPagador.celular.join(' ') : null}
										fullWidth
										required
										label="Celular"
										type="tel"
									/>
								)}
							</InputMask>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								disabled={disableEditarAll}
								error={errosPagador.email}
								helperText={errosPagador.email ? errosPagador.email.join(' ') : null}
								value={pagador ? pagador.email : null}
								onChange={(e) =>
									setPagador({
										...pagador,
										email: e.target.value,
									})
								}
								fullWidth
								required
								label="E-mail"
								type="email"
							/>
						</Grid>
					</Grid>
				</Box>
			</form>
		</Box>
	);
};

export default NewPayer;
