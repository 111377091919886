import {
	CLEAR_TOKEN,
	CLEAR_TRANSACAO,
	DELETE_DOCUMENTO,
	DEL_ASSINATURA,
	DEL_CARNE,
	DEL_CONTA,
	DEL_CONTA_BANCARIA,
	DEL_PAGADOR,
	DEL_PERMISSAO,
	DEL_PLANO,
	DEL_TOKENS,
	DEL_USER,
	GERAR_QR_CODE_IMAGEM,
	LOAD_ASSINATURAS,
	LOAD_ASSINATURA_ID,
	LOAD_AUTH,
	LOAD_BANCOS,
	LOAD_BOLETOS,
	LOAD_BOLETOS_ID,
	LOAD_CARNE,
	LOAD_CARNE_ID,
	LOAD_COBRANCAS_CARTAO,
	LOAD_CONTAS,
	LOAD_CONTA_BANCARIA,
	LOAD_CONTA_DIGITAL_DOCUMENTO,
	LOAD_CONTA_ID,
	LOAD_DOCUMENTO,
	LOAD_EXPORT_EXTRATO,
	LOAD_EXPORT_TRANSACAO,
	LOAD_EXPORT_TRANSFERENCIA,
	LOAD_EXTRATO,
	LOAD_EXTRATO_RESUMO,
	LOAD_HISTORICO_TRANSACAO,
	LOAD_HISTORICO_TRANSFERENCIA,
	LOAD_LANCAMENTOS_FUTUROS,
	LOAD_LINK_PAGAMENTOS,
	LOAD_LINK_PAGAMENTOS_ID,
	LOAD_PAGADORES,
	LOAD_PAGADOR_ID,
	LOAD_PERMISSAO,
	LOAD_PERMISSAO_GERENCIAR,
	LOAD_PLANOS,
	LOAD_PLANO_ID,
	LOAD_RESUMO_TRANSACAO,
	LOAD_TOKENS,
	LOAD_TRANSACAO,
	LOAD_USER_CONTA,
	LOAD_USER_DATA,
	PAGAR_LINK_PAGAMENTO,
	POST_ASSINATURA,
	POST_AUTHME,
	POST_BOLETOS,
	POST_CAPTURA,
	POST_CARNE,
	POST_CARTAO_PAGADOR,
	POST_COBRANCA_CARTAO,
	POST_CONTA,
	POST_CONTA_BANCARIA,
	POST_DOCUMENTO,
	POST_LINK_PAGAMENTOS,
	POST_PAGADOR,
	POST_PERMISSAO,
	POST_PLANO,
	POST_PRIMEIRO_ACESSO,
	POST_SAQUE,
	POST_SAQUE_AUTOMATICO,
	POST_SLIPT,
	POST_TOKENS,
	POST_TRANSFERENCIA,
	POST_USER_CONTA,
	PUT_ASSINATURA,
	PUT_PAGADOR,
	PUT_PLANO,
	REENVIAR_TOKEN,
	SET_STATE,
	SHOW_QR_CODE,
	UPDATE_USER_CONTA,
	UPDATE_USER_PASS,
} from '../constants/actionsStrings';
import {
	authMe,
	deleteAssinatura,
	deleteCarneId,
	deleteConta,
	deleteContaBancaria,
	deleteDocumento,
	deletePagador,
	deletePermissao,
	deletePlano,
	deleteTokens,
	deleteUser,
	enviarBoleto,
	getAssinaturas,
	getAssinaturasFilters,
	getAssinaturasId,
	getBancos,
	getBoletoId,
	getBoletos,
	getBoletosFilter,
	getCarne,
	getCarneFilters,
	getCarneId,
	getCarneIdBoleto,
	getCobrancasCartao,
	getCobrancasCartaoFilters,
	getContaBancaria,
	getContaDigitalDocumento,
	getContaId,
	getContas,
	getContasAll,
	getContasFilter,
	getDocumento,
	getExportExtrato,
	getExportHistoricoTransacao,
	getExportHistoricoTransferencia,
	getExtratoFilters,
	getExtratoPage,
	getExtratoResumo,
	getHistoricoTransacao,
	getHistoricoTransacaoFilters,
	getHistoricoTransferencia,
	getHistoricoTransferenciaFilters,
	getLancamentosFuturos,
	getLinkPagamentoId,
	getLinkPagamentos,
	getLinkPagamentosFilter,
	getPagadorId,
	getPagadores,
	getPagadoresFilter,
	getPermissao,
	getPlanoId,
	getPlanos,
	getPlanosAll,
	getPlanosFilters,
	getReenviarToken,
	getResumoTransacao,
	getTokens,
	getTransacaoId,
	getUserConta,
	getUserContaFilters,
	getUserData,
	postAssinaturas,
	postAuth,
	postBoletos,
	postCapturaCobranca,
	postCarne,
	postCartaoAssinatura,
	postCobrancaCartao,
	postCobrancaCartaoSimples,
	postCobrancaEstornar,
	postContaBancaria,
	postContas,
	postDocumentos,
	postDocumentosAdm,
	postFirstAcess,
	postLinkPagamento,
	postLinkPagamentoPagar,
	postPagador,
	postPermissao,
	postPlano,
	postSaque,
	postSaqueAutomatico,
	postSenhaLink,
	postSplit,
	postTokens,
	postTransferencia,
	postUserConta,
	putAssinaturas,
	putConta,
	putPagador,
	putPlano,
	putUserConta,
	putUserPassword,
	gerarImagemQrCode,
	showQrCode,
} from '../services/services';

import { toast } from 'react-toastify';
const REACT_QR_CODE_API_URL =
	'https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=';

export const loadAuth = (documento, senha) => async (dispatch) => {
	try {
		const res = await postAuth(documento, senha);
		dispatch({
			type: LOAD_AUTH,
			payload: res.data,
		});
		return res;
	} catch (err) {
		console.log(err);
		return false;
	}
};

export const loadPagadorId = (token, id) => async (dispatch) => {
	try {
		const res = await getPagadorId(token, id);
		dispatch({
			type: LOAD_PAGADOR_ID,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const loadPagadores = (token, page) => async (dispatch) => {
	try {
		const res = await getPagadores(token, page);
		dispatch({
			type: LOAD_PAGADORES,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};
export const loadExtratoPage = (token, page) => async (dispatch) => {
	try {
		const res = await getExtratoPage(token, page);
		dispatch({
			type: LOAD_EXTRATO,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const loadPagadoresFilter =
	(token, page, like, order, mostrar, conta_id) => async (dispatch) => {
		try {
			const res = await getPagadoresFilter(
				token,
				page,
				like,
				order,
				mostrar,
				conta_id
			);
			dispatch({
				type: LOAD_PAGADORES,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
		}
	};

export const loadLinkPagamentos = (token, page) => async (dispatch) => {
	try {
		const res = await getLinkPagamentos(token, page);
		dispatch({
			type: LOAD_LINK_PAGAMENTOS,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const loadLancamentosFuturos =
	(token, page, data_liberacao) => async (dispatch) => {
		try {
			const res = await getLancamentosFuturos(token, page, data_liberacao);
			dispatch({
				type: LOAD_LANCAMENTOS_FUTUROS,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
		}
	};

export const loadLinkPagamentoId = (token, id) => async (dispatch) => {
	try {
		const res = await getLinkPagamentoId(token, id);
		dispatch({
			type: LOAD_LINK_PAGAMENTOS_ID,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const delPagador = (token, id) => async (dispatch, getState) => {
	const state = getState();
	try {
		dispatch({
			type: DEL_PAGADOR,
			payload: id,
		});
		await deletePagador(token, id);
	} catch (err) {
		dispatch({
			type: SET_STATE,
			payload: state,
		});
		toast.error('Erro ao excluir o pagador');
	}
};

export const delConta = (token, id) => async (dispatch, getState) => {
	const state = getState();
	try {
		dispatch({
			type: DEL_CONTA,
			payload: id,
		});
		await deleteConta(token, id);
	} catch (err) {
		dispatch({
			type: SET_STATE,
			payload: state,
		});
		toast.error('Erro ao excluir a conta');
	}
};

export const postLinkPagamentos =
	(token, linkPagamentos) => async (dispatch) => {
		try {
			const res = await postLinkPagamento(token, linkPagamentos);
			dispatch({
				type: POST_LINK_PAGAMENTOS,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
			if (err.response.status === 422) {
				return err.response.data.errors;
			} else {
				toast.error('Erro no cadastro');
				return err;
			}
		}
	};

export const postPagadores = (pagador) => async (dispatch) => {
	try {
		const res = await postPagador(pagador);
		dispatch({
			type: POST_PAGADOR,
			payload: res.data,
		});
		return null;
	} catch (err) {
		console.log(err);
		if (err.response.status === 422) {
			return err.response.data.errors;
		} else {
			toast.error('Erro no cadastro');
			return err;
		}
	}
};

export const loadBoletos = (token, page) => async (dispatch) => {
	try {
		const res = await getBoletos(token, page);
		dispatch({
			type: LOAD_BOLETOS,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const loadBoletoId = (token, id) => async (dispatch) => {
	try {
		const res = await getBoletoId(token, id);
		dispatch({
			type: LOAD_BOLETOS_ID,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const postBoletosAction = (token, boleto) => async (dispatch) => {
	try {
		const res = await postBoletos(token, boleto);
		dispatch({
			type: POST_BOLETOS,
			payload: res.data,
		});
		return null;
	} catch (err) {
		console.log(err);
		if (err.response.status === 422) {
			return err.response.data.errors;
		} else {
			toast.error('Erro no cadastro');
			return err;
		}
	}
};

export const loadUserData = (token) => async (dispatch) => {
	try {
		const res = await getUserData(token);
		dispatch({
			type: LOAD_USER_DATA,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const postLinkPagamentoPay =
	(linkPagamentoPagar, id) => async (dispatch) => {
		try {
			const res = await postLinkPagamentoPagar(linkPagamentoPagar, id);
			dispatch({
				type: PAGAR_LINK_PAGAMENTO,
				payload: res.data,
			});
		} catch (err) {
			if (err.response.status === 422) {
				return err.response.data.errors;
			} else {
				return err.response.data;
			}
		}
	};

export const postCobrancaCartaoAction =
	(token, cobrancaCartao) => async (dispatch) => {
		try {
			const res = await postCobrancaCartao(token, cobrancaCartao);
			dispatch({
				type: POST_COBRANCA_CARTAO,
				payload: res.data,
			});
		} catch (err) {
			if (err.response.status === 422) {
				return err.response.data.errors;
			} else {
				return err;
			}
		}
	};
export const postCobrancaCartaoSimplesAction =
	(token, cobrancaCartao) => async (dispatch) => {
		try {
			const res = await postCobrancaCartaoSimples(token, cobrancaCartao);
			dispatch({
				type: POST_COBRANCA_CARTAO,
				payload: res.data,
			});
		} catch (err) {
			if (err.response.status === 422) {
				return err.response.data.errors;
			} else {
				return err;
			}
		}
	};

export const loadLinkPagamentoFilter =
	(token, page, like, order, mostrar, conta_id) => async (dispatch) => {
		try {
			const res = await getLinkPagamentosFilter(
				token,
				page,
				like,
				order,
				mostrar,
				conta_id
			);
			dispatch({
				type: LOAD_LINK_PAGAMENTOS,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
		}
	};

export const loadBoletosFilter =
	(token, page, like, order, mostrar, conta_id) => async (dispatch) => {
		try {
			const res = await getBoletosFilter(
				token,
				page,
				like,
				order,
				mostrar,
				conta_id
			);
			dispatch({
				type: LOAD_BOLETOS,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
		}
	};

export const EditPagador = (token, pagador, id) => async (dispatch) => {
	try {
		const res = await putPagador(token, pagador, id);
		dispatch({
			type: PUT_PAGADOR,
			payload: res.data,
		});
		return null;
	} catch (err) {
		console.log(err);
		if (err.response.status === 422) {
			return err.response.data.errors;
		} else {
			toast.error('Erro no cadastro');
			return err;
		}
	}
};

export const loadContas = (token, page) => async (dispatch) => {
	try {
		const res = await getContas(token, page);
		dispatch({
			type: LOAD_CONTAS,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};
export const loadContasAll = (token) => async (dispatch) => {
	try {
		const res = await getContasAll(token);
		dispatch({
			type: LOAD_CONTAS,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const loadContasFilter =
	(token, page, like, mostrar) => async (dispatch) => {
		try {
			const res = await getContasFilter(token, page, like, mostrar);
			dispatch({
				type: LOAD_CONTAS,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
		}
	};

export const loadUserContaFilter =
	(token, page, like, order, mostrar) => async (dispatch) => {
		try {
			const res = await getUserContaFilters(
				token,
				page,
				like,
				order,
				mostrar
			);
			dispatch({
				type: LOAD_USER_CONTA,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
		}
	};

export const loadContaId = (token, id) => async (dispatch) => {
	try {
		const res = await getContaId(token, id);
		dispatch({
			type: LOAD_CONTA_ID,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const postNewConta = (token, conta) => async (dispatch) => {
	try {
		const res = await postContas(token, conta);
		dispatch({
			type: POST_CONTA,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
		if (err.response.status === 422) {
			return err.response.data.errors;
		} else {
			toast.error('Erro no cadastro');
			return err;
		}
	}
};

export const updateConta = (token, conta, id) => async (dispatch) => {
	try {
		const res = await putConta(token, conta, id);
		dispatch({
			type: POST_CONTA,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
		if (err.response.status === 422) {
			return err.response.data.errors;
		} else {
			toast.error('Erro no cadastro');
			return err;
		}
	}
};

export const updateUserConta = (token, conta) => async (dispatch) => {
	try {
		const res = await putUserConta(token, conta);
		dispatch({
			type: UPDATE_USER_CONTA,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
		if (err.response.status === 422) {
			return err.response.data.errors;
		} else {
			toast.error('Erro no cadastro');
			return null;
		}
	}
};

export const updateUserPassword = (token, userPass) => async (dispatch) => {
	try {
		const res = await putUserPassword(token, userPass);
		dispatch({
			type: UPDATE_USER_PASS,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
		if (err.response.status === 422) {
			return err.response.data.errors;
		} else {
			toast.error('Erro no cadastro');
			return null;
		}
	}
};

export const loadUserConta = (token, id) => async (dispatch) => {
	try {
		const res = await getUserConta(token, id);
		dispatch({
			type: LOAD_USER_CONTA,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const delUser = (token, id) => async (dispatch, getState) => {
	const state = getState();
	try {
		dispatch({
			type: DEL_USER,
			payload: id,
		});
		await deleteUser(token, id);
	} catch (err) {
		dispatch({
			type: SET_STATE,
			payload: state,
		});
		toast.error('Erro ao excluir conta usuário');
		console.error(err);
	}
};

export const postUsuarioConta = (token, user) => async (dispatch) => {
	try {
		const res = await postUserConta(token, user);
		dispatch({
			type: POST_USER_CONTA,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
		if (err.response.status === 422) {
			return err.response.data.errors;
		} else {
			toast.error('Erro no cadastro');
			return null;
		}
	}
};

export const loadBancos = (token) => async (dispatch) => {
	try {
		const res = await getBancos(token);
		dispatch({
			type: LOAD_BANCOS,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const postContaBancariaAction =
	(token, conta, conta_id) => async (dispatch) => {
		try {
			const res = await postContaBancaria(token, conta, conta_id);
			dispatch({
				type: POST_CONTA_BANCARIA,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
			if (err.response.status === 422) {
				return err.response.data.errors;
			} else {
				toast.error('Erro no cadastro');
				return null;
			}
		}
	};

export const loadContaBancaria = (token, conta_id) => async (dispatch) => {
	try {
		const res = await getContaBancaria(token, conta_id);
		dispatch({
			type: LOAD_CONTA_BANCARIA,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const delContaBancaria =
	(token, id, conta_id) => async (dispatch, getState) => {
		const state = getState();
		try {
			dispatch({
				type: DEL_CONTA_BANCARIA,
				payload: id,
			});
			await deleteContaBancaria(token, id, conta_id);
		} catch (err) {
			dispatch({
				type: SET_STATE,
				payload: state,
			});
			toast.error('Erro ao excluir conta Bancaria');
			console.error(err);
		}
	};

export const postPrimeiroAcesso = (user) => async (dispatch) => {
	try {
		const res = await postFirstAcess(user);
		dispatch({
			type: POST_PRIMEIRO_ACESSO,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
		if (err.response.status === 422) {
			return err.response.data.errors;
		} else {
			toast.error('Erro no cadastro');
			return null;
		}
	}
};

export const loadHistoricoTransacaoFilter =
	(
		token,
		page,
		day,
		order,
		mostrar,
		status,
		like,
		payment_type,
		data_inicial,
		data_final,
		id,
		documento,
		vencimento_inicial,
		vencimento_final,
		pagamento_inicial,
		pagamento_final,
		conta_id
	) =>
	async (dispatch) => {
		try {
			const res = await getHistoricoTransacaoFilters(
				token,
				page,
				day,
				order,
				mostrar,
				status,
				like,
				payment_type,
				data_inicial,
				data_final,
				id,
				documento,
				vencimento_inicial,
				vencimento_final,
				pagamento_inicial,
				pagamento_final,
				conta_id
			);
			dispatch({
				type: LOAD_HISTORICO_TRANSACAO,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
		}
	};

export const loadHistoricoTransacao = (token, page) => async (dispatch) => {
	try {
		const res = await getHistoricoTransacao(token, page);
		dispatch({
			type: LOAD_HISTORICO_TRANSACAO,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const loadTransacaoId = (token, id) => async (dispatch) => {
	try {
		const res = await getTransacaoId(token, id);
		dispatch({
			type: LOAD_TRANSACAO,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const clearTransacao = () => async (dispatch) => {
	dispatch({
		type: CLEAR_TRANSACAO,
	});
};
export const clearToken = () => async (dispatch) => {
	dispatch({
		type: CLEAR_TOKEN,
	});
};

export const enviarBoletoAction = (token, id) => async () => {
	try {
		const res = await enviarBoleto(token, id);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err);
	}
};

export const authMeAction = (token) => async () => {
	try {
		const res = await authMe(token);
		return res.data;
	} catch (err) {
		console.log(err);
	}
};

export const authMe2 = (token) => async (dispatch) => {
	try {
		const res = await authMe(token);
		dispatch({
			type: POST_AUTHME,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const postSaqueAction = (token, saqueInfos) => async (dispatch) => {
	try {
		const res = await postSaque(token, saqueInfos);
		dispatch({
			type: POST_SAQUE,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
		if (err.response.status === 422) {
			return err.response.data.errors;
		} else {
			console.log(err);
			toast.error('erro');
			return null;
		}
	}
};

export const loadHistoricoTransferencia = (token, page) => async (dispatch) => {
	try {
		const res = await getHistoricoTransferencia(token, page);
		dispatch({
			type: LOAD_HISTORICO_TRANSFERENCIA,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const loadHistoricoTransferenciaFilters =
	(token, page, like, valor, data, conta_id) => async (dispatch) => {
		try {
			const res = await getHistoricoTransferenciaFilters(
				token,
				page,
				like,
				valor,
				data,
				conta_id
			);
			dispatch({
				type: LOAD_HISTORICO_TRANSFERENCIA,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
		}
	};

export const postSenhaLinkAction = (senha, id) => async () => {
	try {
		const res = await postSenhaLink(senha, id);
		return res;
	} catch (err) {
		toast.error('Senha incorreta');
		console.log(err);
	}
};
export const loadExtratoFilter =
	(token, page, id, day, order, mostrar, tipo, conta_id) =>
	async (dispatch) => {
		try {
			const res = await getExtratoFilters(
				token,
				page,
				id,
				day,
				order,
				mostrar,
				tipo,
				conta_id
			);
			dispatch({
				type: LOAD_EXTRATO,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
		}
	};

export const loadCarne = (token, page) => async (dispatch) => {
	try {
		const res = await getCarne(token, page);
		dispatch({
			type: LOAD_CARNE,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const loadCarneFilters =
	(token, page, like, order, mostrar, conta_id) => async (dispatch) => {
		try {
			const res = await getCarneFilters(
				token,
				page,
				like,
				order,
				mostrar,
				conta_id
			);
			dispatch({
				type: LOAD_CARNE,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
		}
	};

export const loadCarneId = (token, id) => async (dispatch) => {
	try {
		const res = await getCarneId(token, id);
		dispatch({
			type: LOAD_CARNE_ID,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};
export const loadCarneIdBoleto = (token, id) => async () => {
	try {
		const res = await getCarneIdBoleto(token, id);
		return res;
		// dispatch({
		// 	type: LOAD_CARNE_ID,
		// 	payload: res.data,
		// });
	} catch (err) {
		console.log(err);
	}
};

export const delCarne = (token, id) => async (dispatch, getState) => {
	const state = getState();
	try {
		dispatch({
			type: DEL_CARNE,
			payload: id,
		});
		await deleteCarneId(token, id);
	} catch (err) {
		dispatch({
			type: SET_STATE,
			payload: state,
		});
		toast.error('Erro ao excluir o carnê');
	}
};

export const postCarneAction = (token, carne) => async (dispatch) => {
	try {
		const res = await postCarne(token, carne);
		dispatch({
			type: POST_CARNE,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
		if (err.response.status === 422) {
			return err.response.data.errors;
		} else {
			console.log(err);
			toast.error('erro');
			return null;
		}
	}
};

export const postTransferenciaAction =
	(token, transferenciaInfos) => async (dispatch) => {
		try {
			const res = await postTransferencia(token, transferenciaInfos);
			dispatch({
				type: POST_TRANSFERENCIA,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
			if (err.response.status === 422) {
				return err.response.data.errors;
			} else {
				console.log(err);
				toast.error('erro');
				return null;
			}
		}
	};

export const loadCobrancasCartao = (token, id) => async (dispatch) => {
	try {
		const res = await getCobrancasCartao(token, id);
		dispatch({
			type: LOAD_COBRANCAS_CARTAO,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const loadCobrancasCartaoFilters =
	(token, page, like, order, mostrar, conta_id) => async (dispatch) => {
		try {
			const res = await getCobrancasCartaoFilters(
				token,
				page,
				like,
				order,
				mostrar,
				conta_id
			);
			dispatch({
				type: LOAD_COBRANCAS_CARTAO,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
		}
	};

export const postCapturaCobrancaAction =
	(token, id, valor) => async (dispatch) => {
		try {
			const res = await postCapturaCobranca(token, id, valor);
			dispatch({
				type: POST_CAPTURA,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
			if (err.response.status === 422) {
				return err.response.data.errors;
			} else {
				console.log(err);
				toast.error('erro');
				return null;
			}
		}
	};

export const postCobrancaEstornarAction = (token, id) => async () => {
	try {
		const res = await postCobrancaEstornar(token, id);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err);
	}
};

export const loadPlanos = (token, page) => async (dispatch) => {
	try {
		const res = await getPlanos(token, page);
		dispatch({
			type: LOAD_PLANOS,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};
export const loadPlanosAll = (token) => async (dispatch) => {
	try {
		const res = await getPlanosAll(token);
		dispatch({
			type: LOAD_PLANOS,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const loadPlanosFilters =
	(token, page, like, order, mostrar) => async (dispatch) => {
		try {
			const res = await getPlanosFilters(token, page, like, order, mostrar);
			dispatch({
				type: LOAD_PLANOS,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
		}
	};

export const loadPlanoId = (token, id) => async (dispatch) => {
	try {
		const res = await getPlanoId(token, id);
		dispatch({
			type: LOAD_PLANO_ID,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const postPlanoAction = (token, plano) => async (dispatch) => {
	try {
		const res = await postPlano(token, plano);
		dispatch({
			type: POST_PLANO,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
		if (err.response.status === 422) {
			return err.response.data.errors;
		} else {
			console.log(err);
			toast.error('erro');
			return null;
		}
	}
};

export const updatePlano = (token, id, plano) => async (dispatch) => {
	try {
		const res = await putPlano(token, id, plano);
		dispatch({
			type: PUT_PLANO,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
		if (err.response.status === 422) {
			return err.response.data.errors;
		} else {
			toast.error('Erro no cadastro');
			return null;
		}
	}
};

export const delPlano = (token, id) => async (dispatch, getState) => {
	const state = getState();
	try {
		dispatch({
			type: DEL_PLANO,
			payload: id,
		});
		await deletePlano(token, id);
	} catch (err) {
		console.log(err);
		dispatch({
			type: SET_STATE,
			payload: state,
		});
		toast.error('Erro ao excluir o plano');
	}
};

export const loadAssinaturas = (token, page) => async (dispatch) => {
	try {
		const res = await getAssinaturas(token, page);
		dispatch({
			type: LOAD_ASSINATURAS,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const loadAssinaturasFilters =
	(token, page, like, plano, order, mostrar, conta_id) => async (dispatch) => {
		try {
			const res = await getAssinaturasFilters(
				token,
				page,
				like,
				plano,
				order,
				mostrar,
				conta_id
			);
			dispatch({
				type: LOAD_ASSINATURAS,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
		}
	};

export const loadAssinaturaId = (token, id) => async (dispatch) => {
	try {
		const res = await getAssinaturasId(token, id);
		dispatch({
			type: LOAD_ASSINATURA_ID,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const postAssinaturaAction = (token, plano) => async (dispatch) => {
	try {
		const res = await postAssinaturas(token, plano);
		dispatch({
			type: POST_ASSINATURA,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
		if (err.response.status === 422) {
			return err.response.data.errors;
		} else {
			console.log(err);
			toast.error('erro');
			return err;
		}
	}
};

export const updateAssinatura = (token, id, plano) => async (dispatch) => {
	try {
		const res = await putAssinaturas(token, id, plano);
		dispatch({
			type: PUT_ASSINATURA,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
		if (err.response.status === 422) {
			return err.response.data.errors;
		} else {
			toast.error('Erro no cadastro');
			return null;
		}
	}
};

export const delAssinatura = (token, id) => async (dispatch, getState) => {
	const state = getState();
	try {
		dispatch({
			type: DEL_ASSINATURA,
			payload: id,
		});
		await deleteAssinatura(token, id);
	} catch (err) {
		console.log(err);
		dispatch({
			type: SET_STATE,
			payload: state,
		});
		toast.error('Erro ao excluir a assinatura');
	}
};

export const postCartaoAssinaturaAction =
	(token, id, cartao) => async (dispatch) => {
		try {
			const res = await postCartaoAssinatura(token, id, cartao);
			dispatch({
				type: POST_CARTAO_PAGADOR,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
			if (err.response.status === 422) {
				return err.response.data.errors;
			} else {
				toast.error('Erro no cadastro');
				return err;
			}
		}
	};

export const postSaqueAutomaticoAction =
	(token, saqueAutomatico) => async (dispatch) => {
		try {
			const res = await postSaqueAutomatico(token, saqueAutomatico);
			dispatch({
				type: POST_SAQUE_AUTOMATICO,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
			if (err.response && err.response.status === 422) {
				return err.response.data.errors;
			} else {
				toast.error('Erro');
				return err;
			}
		}
	};
export const postSlipAction = (token, transacao) => async (dispatch) => {
	try {
		const res = await postSplit(token, transacao);
		dispatch({
			type: POST_SLIPT,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
		if (err.response && err.response.status === 422) {
			return err.response.data.errors;
		} else {
			toast.error('Erro');
			return err;
		}
	}
};

export const loadResumoTransacao = (token) => async (dispatch) => {
	try {
		const res = await getResumoTransacao(token);
		dispatch({
			type: LOAD_RESUMO_TRANSACAO,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};
export const loadTokens = (token) => async (dispatch) => {
	try {
		const res = await getTokens(token);
		dispatch({
			type: LOAD_TOKENS,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const postTokenAction = (token, tokenInfos) => async (dispatch) => {
	try {
		const res = await postTokens(token, tokenInfos);
		dispatch({
			type: POST_TOKENS,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const delTokens = (token, index) => async (dispatch, getState) => {
	const state = getState();
	try {
		dispatch({
			type: DEL_TOKENS,
			payload: index,
		});
		await deleteTokens(token, index);
	} catch (err) {
		console.log(err);
		dispatch({
			type: SET_STATE,
			payload: state,
		});
		toast.error('Erro ao excluir token');
	}
};

export const postDocumentoAction =
	(token, documento, categoria) => async (dispatch) => {
		const documentoObjeto = { ...documento };
		try {
			const res = await postDocumentos(
				token,
				documentoObjeto[0].file,
				categoria,
				documentoObjeto[0].file.type
			);
			dispatch({
				type: POST_DOCUMENTO,
				payload: res.data,
			});
			toast.success('Documento adicionado com sucesso');
		} catch (err) {
			console.log(err);
			toast.error('Erro ao adicionar o documento');
		}
	};

export const postDocumentoActionAdm =
	(token, documento, categoria, conta_id) => async (dispatch) => {
		const documentoObjeto = { ...documento };
		try {
			const res = await postDocumentosAdm(
				token,
				documentoObjeto[0].file,
				categoria,
				conta_id,
				documentoObjeto[0].file.type
			);
			dispatch({
				type: POST_DOCUMENTO,
				payload: res.data,
			});
			toast.success('Documento adicionado com sucesso');
		} catch (err) {
			console.log(err);
			toast.error('Erro ao adicionar o documento');
		}
	};

export const delDocumento = (token, id) => async (dispatch, getState) => {
	const state = getState();
	try {
		dispatch({
			type: DELETE_DOCUMENTO,
			payload: id,
		});
		await deleteDocumento(token, id);
	} catch (err) {
		dispatch({
			type: SET_STATE,
			payload: state,
		});
		toast.error('Erro ao Documento');
	}
};

export const loadDocumentos = (token, conta_id) => async (dispatch) => {
	try {
		const res = await getDocumento(token, conta_id);
		dispatch({
			type: LOAD_DOCUMENTO,
			payload: res.data.data,
		});
	} catch (err) {
		console.log(err);
	}
};
export const loadPermissao = (token, id) => async (dispatch) => {
	try {
		const res = await getPermissao(token, id);
		dispatch({
			type: LOAD_PERMISSAO,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const loadPermissaoGerenciar = (token, id) => async (dispatch) => {
	try {
		const res = await getPermissao(token, id);
		dispatch({
			type: LOAD_PERMISSAO_GERENCIAR,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const postPermissaoAction =
	(token, id, tipoPermissao) => async (dispatch) => {
		try {
			const res = await postPermissao(token, id, tipoPermissao);
			dispatch({
				type: POST_PERMISSAO,
				payload: res.data,
			});
		} catch (err) {
			console.log(err);
		}
	};

export const delPermissao = (token, id, tipoPermissao) => async (dispatch) => {
	try {
		const res = await deletePermissao(token, id, tipoPermissao);
		dispatch({
			type: DEL_PERMISSAO,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const loadContaDigitalDocumento =
	(token, documento) => async (dispatch) => {
		try {
			const res = await getContaDigitalDocumento(token, documento);
			dispatch({
				type: LOAD_CONTA_DIGITAL_DOCUMENTO,
				payload: res.data,
			});
			return true;
		} catch (err) {
			console.log(err);
			return false;
		}
	};

export const loadExportHistoricoTransacao =
	(
		token,
		page,
		day,
		order,
		mostrar,
		status,
		like,
		payment_type,
		data_inicial,
		data_final,
		id,
		documento,
		vencimento_inicial,
		vencimento_final,
		pagamento_inicial,
		pagamento_final,
		conta_id
	) =>
	async (dispatch) => {
		try {
			const res = await getExportHistoricoTransacao(
				token,
				page,
				day,
				order,
				mostrar,
				status,
				like,
				payment_type,
				data_inicial,
				data_final,
				id,
				documento,
				vencimento_inicial,
				vencimento_final,
				pagamento_inicial,
				pagamento_final,
				conta_id
			);
			dispatch({
				type: LOAD_EXPORT_TRANSACAO,
				payload: res.data,
			});
			return res.data;
		} catch (err) {
			console.log(err);
			toast.error('Erro ao exportar');
			return null;
		}
	};

export const loadExportHistoricoTransferencia =
	(token, page, like, valor, data, conta_id) => async (dispatch) => {
		try {
			const res = await getExportHistoricoTransferencia(
				token,
				page,
				like,
				valor,
				data,
				conta_id
			);
			dispatch({
				type: LOAD_EXPORT_TRANSFERENCIA,
				payload: res.data,
			});
			return res.data;
		} catch (err) {
			console.log(err);
			toast.error('Erro ao exportar');
			return null;
		}
	};

export const loadExportExtrato =
	(token, page, id, day, order, mostrar, tipo, conta_id) =>
	async (dispatch) => {
		try {
			const res = await getExportExtrato(
				token,
				page,
				id,
				day,
				order,
				mostrar,
				tipo,
				conta_id
			);
			dispatch({
				type: LOAD_EXPORT_EXTRATO,
				payload: res.data,
			});
			return res.data;
		} catch (err) {
			console.log(err);
			toast.error('Erro ao exportar');
			return null;
		}
	};
export const loadReenviarToken = (token, conta_id) => async (dispatch) => {
	try {
		const res = await getReenviarToken(token, conta_id);
		dispatch({
			type: REENVIAR_TOKEN,
			payload: res.data,
		});
		toast.success('Token reenviado com sucesso!');
	} catch (err) {
		console.log(err);
		toast.error('Erro ao enviar');
	}
};

export const loadExtratoResumoAction = (token) => async (dispatch) => {
	try {
		const res = await getExtratoResumo(token);
		dispatch({
			type: LOAD_EXTRATO_RESUMO,
			payload: res.data,
		});
	} catch (err) {
		console.log(err);
	}
};

export const getQrCodeImagemAction = (qrCodeString) => async (dispatch) => {
	console.log(qrCodeString);
	try {
		await gerarImagemQrCode(qrCodeString);
		dispatch({
			type: GERAR_QR_CODE_IMAGEM,
			payload: `${REACT_QR_CODE_API_URL}${qrCodeString}`,
		});
		return null;
	} catch (err) {
		console.log(err);
		return true;
	}
};

export const showQrCodeAction = (id) => async (dispatch) => {
	try {
		const res = await showQrCode(id);
		dispatch({
			type: SHOW_QR_CODE,
			payload: res.data,
		});
		return true;
	} catch (err) {
		console.log(err);
		return false;
	}
};
