import {
	Box,
	Dialog,
	DialogTitle,
	FormHelperText,
	Paper,
	Radio,
	TextField,
	Typography,
	makeStyles,
} from '@material-ui/core';
import React, { useEffect } from 'react';

import CurrencyInput from 'react-currency-input';
import GradientButton from '../../../GradientButton/GradientButton';

const useStyles = makeStyles((theme) => ({
	UserInfosContainer: {
		width: '40%',
		display: 'flex',
		flexDirection: 'column',
		color: theme.palette.primary.main,
	},
	userContentsContainer: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: '#ddf0f4',
		padding: '8px',
		borderRadius: '27px',
	},
	userContentItem: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.palette.background.paper,
		padding: '12px',
		margin: '8px',
		borderRadius: '27px',
	},
	saqueModal: {
		padding: '20px',
	},
	saqueHeader: {
		background: theme.gradient.main,
		color: 'white',
	},
	currency: {
		font: 'inherit',
		color: 'currentColor',
		width: '100%',
		border: '0px',
		borderBottom: '1px solid gray',
		height: '1.1876em',
		margin: 0,
		display: 'block',
		padding: '6px 0 7px',
		minWidth: 0,
		background: 'none',
		boxSizing: 'content-box',
		animationName: 'mui-auto-fill-cancel',
		letterSpacing: 'inherit',
		animationDuration: '10ms',
		appearance: 'textfield',
		textAlign: 'start',
		paddingLeft: '5px',
	},
}));

const SaqueModal = ({
	onClose,
	selectedValue,
	open,
	handleSacar,
	contasBancariasUser,
	saqueInfos,
	setSaqueInfos,
	errosSacar,
}) => {
	// modal do saque
	const classes = useStyles();

	const handleClose = () => {
		onClose(selectedValue);
	};
	return (
		<Dialog onClose={handleClose} open={open} className={classes.saqueModal}>
			<Box>
				<DialogTitle className={classes.saqueHeader}>
					<Typography align="center" variant="h6">
						Realizar Saque
					</Typography>
				</DialogTitle>
				<Box display="flex" flexDirection="column" padding="24px">
					<Box display="flex" flexDirection="column">
						<Typography
							style={{
								alignSelf: 'center',
							}}
							variant="h6"
						>
							Valor do Saque
						</Typography>
						<FormHelperText style={{ alignSelf: 'center' }}>Minímo: R$ 3,00</FormHelperText>
						<CurrencyInput
							className={classes.currency}
							decimalSeparator=","
							thousandSeparator="."
							prefix="R$ "
							value={saqueInfos.valor}
							onChangeEvent={(event, maskedvalue, floatvalue) =>
								setSaqueInfos({ ...saqueInfos, valor: floatvalue })
							}
							style={{
								marginBottom: '6px',
								width: '60%',
								alignSelf: 'center',
							}}
						/>

						<TextField
							variant="outlined"
							fullWidth
							InputLabelProps={{ shrink: true }}
							label="Descrição do Saque"
							value={saqueInfos.descricao}
							onChange={(e) =>
								setSaqueInfos({
									...saqueInfos,
									descricao: e.target.value,
								})
							}
							style={{
								marginBottom: '6px',
								width: '60%',
								alignSelf: 'center',
							}}
							required
							error={errosSacar.descricao ? true : false}
							helperText={errosSacar.descricao ? errosSacar.descricao.join(' ') : null}
							placeholder="Descrição"
						/>
					</Box>

					<Box display="flex" flexDirection="column" alignItems="center" marginTop="20px">
						<Typography variant="h6">Escolha a conta</Typography>
						{errosSacar.conta_bancaria_id ? (
							<FormHelperText style={{ color: 'red' }}>
								{errosSacar.conta_bancaria_id.join(' ')}
							</FormHelperText>
						) : null}
						{contasBancariasUser.map((contaBancaria) => (
							<Paper
								style={{
									margin: '12px',
									display: 'flex',
								}}
							>
								<Radio
									value={contaBancaria.id}
									checked={saqueInfos.conta_bancaria_id === contaBancaria.id}
									onChange={(e) =>
										setSaqueInfos({
											...saqueInfos,
											conta_bancaria_id: e.target.value,
										})
									}
								/>
								<Box display="flex" flexDirection="column" width="400px">
									<Typography variant="body2" align="center">
										{contaBancaria.banco}
									</Typography>
									<Box display="flex">
										<Typography variant="overline" style={{ margin: '6px' }}>
											Ag: <b>{contaBancaria.agencia}</b>
										</Typography>
										<Typography variant="overline" style={{ margin: '6px' }}>
											Conta: <b>{contaBancaria.conta}</b>
										</Typography>
										<Typography variant="overline" style={{ margin: '6px' }}>
											Tipo: <b>{contaBancaria.tipo}</b>
										</Typography>
									</Box>
								</Box>
							</Paper>
						))}
					</Box>
					<Box alignSelf="flex-end">
						<GradientButton buttonText="Sacar" onClick={handleSacar} />
					</Box>
				</Box>
			</Box>
		</Dialog>
	);
};

export default SaqueModal;
