import { Box, Button, LinearProgress, Menu, MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { delContaBancaria, loadContaBancaria } from '../../../../actions/actions';
import { useDispatch, useSelector } from 'react-redux';

import CustomTable from '../../../reusable/CustomTable/CustomTable';
import useAuth from '../../../../hooks/useAuth';
import { useParams } from 'react-router';

const columns = [
	{ headerText: 'Banco', key: 'banco' },
	{ headerText: 'Agência', key: 'agencia' },
	{ headerText: 'Tipo', key: 'tipo' },
	{ headerText: 'Número da conta', key: 'conta' },
	{ headerText: 'Excluir', key: 'menu' },
];

const ListBankAccounts = () => {
	const contasBancariasUser = useSelector((state) => state.contasBancarias);
	const { id } = useParams();
	const token = useAuth();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(loadContaBancaria(token, id));
	}, []);

	const Editar = ({ row }) => {
		const [anchorEl, setAnchorEl] = useState(null);
		const handleClick = (event) => {
			setAnchorEl(event.currentTarget);
		};
		const handleClose = () => {
			setAnchorEl(null);
		};

		const handleExcluir = async (row) => {
			dispatch(delContaBancaria(token, row.id, id));
			setAnchorEl(null);
		};

		return (
			<Box>
				<Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
					<span class="material-icons" style={{ color: '#CC9B00', fontSize: '25px' }}>
						menu
					</span>
				</Button>
				<Menu
					id="simple-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<MenuItem onClick={() => handleExcluir(row)}>Excluir</MenuItem>
				</Menu>
			</Box>
		);
	};

	return (
		<>
			{contasBancariasUser ? (
				<CustomTable columns={columns} data={contasBancariasUser} Editar={Editar} compacta="true" />
			) : (
				<LinearProgress />
			)}
		</>
	);
};

export default ListBankAccounts;
