import {
	Box,
	Button,
	LinearProgress,
	Menu,
	MenuItem,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { faCalendarAlt, faInfo } from '@fortawesome/free-solid-svg-icons';
import { loadBoletos, loadBoletosFilter, loadContasAll } from '../../../actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import CustomBreadcrumbs from '../../reusable/CustomBreadcrumbs/CustomBreadcrumbs';
import CustomTable from '../../reusable/CustomTable/CustomTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GradientButton from '../../reusable/GradientButton/GradientButton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Pagination from '@material-ui/lab/Pagination';
import SearchBar from '../../reusable/SearchBar/SearchBar';
import SplitModal from '../../reusable/SplitModal/SplitModal';
import useAuth from '../../../hooks/useAuth';
import useDebounce from '../../../hooks/useDebounce';

const columns = [
	{
		headerText: 'Criado em',
		key: 'created_at',
		CustomValue: (data) => {
			const p = data.split(/\D/g);
			const dataFormatada = [p[2], p[1], p[0]].join('/');
			return (
				<Box display="flex">
					<FontAwesomeIcon icon={faCalendarAlt} size="lg" />
					<Typography style={{ marginLeft: '6px' }}>{dataFormatada}</Typography>
				</Box>
			);
		},
	},
	{
		headerText: 'Situação',
		key: 'status',
		CustomValue: (status) => {
			if (status === 'Pendente') {
				return (
					<Typography
						style={{
							color: '#dfad06',
							fontWeight: 'bold',
						}}
					>
						PENDENTE
					</Typography>
				);
			}
			if (status === 'Concluido') {
				return (
					<Typography
						style={{
							color: 'green',
							fontWeight: 'bold',
						}}
					>
						CONCLUIDO
					</Typography>
				);
			}
		},
	},
	{
		headerText: 'Pagador',
		key: 'pagador',
		CustomValue: (pagador) => {
			return (
				<Box>
					<Typography>{pagador.nome ? pagador.nome : pagador.razao_social}</Typography>
					<Typography>{pagador.documento ? pagador.documento : null}</Typography>
				</Box>
			);
		},
	},
	{
		headerText: 'Descrição',
		key: 'descricao',
		CustomValue: (descricao) => {
			return (
				<Box>
					<Tooltip title={descricao}>
						<InfoOutlinedIcon color="" />
					</Tooltip>
				</Box>
			);
		},
	},
	{
		headerText: 'Ações',
		key: 'menu',
	},
];

const PaymentSlipList = () => {
	const boletos = useSelector((state) => state.boletos);
	const token = useAuth();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const dispatch = useDispatch();
	const [page, setPage] = useState(1);
	const history = useHistory();
	const contasUser = useSelector((state) => state.contas);
	const userData = useSelector((state) => state.userData);
	const { id } = useParams();

	const [filters, setFilters] = useState({
		like: '',
		order: '',
		mostrar: '',
	});

	const debouncedLike = useDebounce(filters.like, 800);

	useEffect(() => {
		dispatch(loadBoletosFilter(token, page, debouncedLike, filters.order, filters.mostrar, id));
	}, [page, debouncedLike, filters.order, filters.mostrar, id]);

	useEffect(() => {
		return () => {
			setFilters({ ...filters });
		};
	}, []);

	const handleChangePage = (e, value) => {
		setPage(value);
	};

	const handleNewBoleto = () => {
		history.push('/dashboard/gerar-boleto');
	};

	const Editar = ({ row }) => {
		const [anchorEl, setAnchorEl] = useState(null);
		const handleClick = (event) => {
			setAnchorEl(event.currentTarget);
		};
		const handleClose = () => {
			setAnchorEl(null);
		};
		const [openSplit, setOpenSplit] = useState(false);

		return (
			<Box>
				{token && userData === '' ? null : (
					<>
						<Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
							<span class="material-icons" style={{ color: '#CC9B00', fontSize: '25px' }}>
								menu
							</span>
						</Button>
						<Menu
							id="simple-menu"
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={handleClose}
						>
							<MenuItem
								onClick={() => {
									setAnchorEl(null);
									window.open(`${row.url}`, 'Boleto', 'height=1000,width=1000');
								}}
							>
								Visualizar
							</MenuItem>
							<MenuItem
								onClick={() => {
									setAnchorEl(null);
									setOpenSplit(true);
								}}
							>
								Repartir valor
							</MenuItem>
						</Menu>
						{openSplit ? (
							<SplitModal
								row={row}
								open={openSplit}
								onClose={() => setOpenSplit(false)}
								contasUser={contasUser.data}
							/>
						) : null}
					</>
				)}
			</Box>
		);
	};

	return (
		<Box display="flex" flexDirection="column">
			{token && userData === '' ? (
				<CustomBreadcrumbs path1="Gerenciar Listas" to1="goBack" path2="Boletos" />
			) : (
				<CustomBreadcrumbs path1="Boletos" />
			)}
			<Box display="flex" justifyContent="space-between" flexDirection={matches ? 'column' : null}>
				<Typography style={{ marginTop: '8px' }} variant="h4">
					Boletos
				</Typography>

				{token && userData === '' ? null : (
					<Box>
						<GradientButton onClick={handleNewBoleto} buttonText="Nova Cobrança" />
					</Box>
				)}
			</Box>
			<Box marginTop="16px" marginBottom="16px">
				<SearchBar
					fullWidth
					placeholder="Procurar por documento, nome..."
					value={filters.like}
					onChange={(e) =>
						setFilters({
							...filters,
							like: e.target.value,
						})
					}
				/>
			</Box>

			<>
				{boletos.data && boletos.per_page ? (
					<CustomTable columns={columns} data={boletos.data} Editar={Editar} />
				) : (
					<LinearProgress />
				)}
				<Box alignSelf="flex-end" marginTop="8px">
					<Pagination
						variant="outlined"
						color="secondary"
						size="large"
						count={boletos.last_page}
						onChange={handleChangePage}
						page={page}
					/>
				</Box>
			</>
		</Box>
	);
};

export default PaymentSlipList;
