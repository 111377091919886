import {
	Box,
	Button,
	CircularProgress,
	FormControlLabel,
	IconButton,
	Switch,
	Tooltip,
	Typography,
	makeStyles,
	useTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import {
	authMe2,
	loadContaBancaria,
	loadPermissao,
	loadUserData,
	postSaqueAction,
	postSaqueAutomaticoAction,
} from '../../../../actions/actions';
import { useDispatch, useSelector } from 'react-redux';

import CurrencyFormat from 'react-currency-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GradientButton from '../../GradientButton/GradientButton';
import { Link } from 'react-router-dom';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import SaqueModal from './SaqueModal/SaqueModal';
import { Skeleton } from '@material-ui/lab';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import useAuth from '../../../../hooks/useAuth';

const useStyles = makeStyles((theme) => ({
	UserInfosContainer: {
		width: '40%',
		display: 'flex',
		flexDirection: 'column',
		color: theme.palette.primary.main,
		[theme.breakpoints.down(850)]: {
			width: '100%',
		},
	},
	userContentsContainer: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: '#ddf0f4',
		padding: '8px',
		borderRadius: '27px',
	},
	userContentItem: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.palette.background.paper,
		padding: '12px',
		margin: '8px',
		borderRadius: '27px',
	},
	saqueModal: {
		padding: '20px',
	},
	saqueHeader: {
		background: theme.gradient.main,
		color: 'white',
	},
	'@keyframes myEffect': {
		'0%': {
			opacity: 0,
			transform: 'translateX(10%)',
		},
		'100%': {
			opacity: 1,
			transform: 'translateX(0)',
		},
	},
}));

const UserInfos = () => {
	const classes = useStyles();
	const token = useAuth();
	const theme = useTheme();
	const userData = useSelector((state) => state.userData);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [saqueAutomatico, setSaqueAutomatico] = useState(false);
	const [open, setOpen] = useState(false);
	const contasBancariasUser = useSelector((state) => state.contasBancarias);
	const [saldoVisivel, setSaldoVisivel] = useState(false);
	const [valorFuturoVisivel, setValorFuturoVisivel] = useState(false);
	const [errosSacar, setErrosSacar] = useState({});

	useEffect(() => {
		dispatch(loadContaBancaria(token));
	}, []);

	useEffect(() => {
		dispatch(loadUserData(token));
	}, []);

	const [saqueInfos, setSaqueInfos] = useState({
		conta_bancaria_id: '',
		valor: 3,
		descricao: '',
	});

	const handleOpenSaqueModal = () => {
		setOpen(true);
	};

	const handleSacar = async () => {
		setLoading(true);
		const resSaque = await dispatch(postSaqueAction(token, saqueInfos));
		if (resSaque) {
			toast.error('Erro ao sacar');
			setErrosSacar(resSaque);
			setLoading(false);
		} else {
			toast.success('Saque realizado com sucesso!');
			setOpen(false);
			dispatch(loadUserData(token));
			setLoading(false);
		}
	};

	const [realNome, setRealNome] = useState('');

	useEffect(() => {
		if (userData && userData.nome === ' ') {
			setRealNome(userData.razao_social);
		} else {
			setRealNome(userData.nome);
		}
	}, [userData]);

	const handleSaqueAutomatico = async () => {
		setLoading(true);
		if (saqueAutomatico === false || userData.saque_automatico === null) {
			const resSaqueAutomatico = await dispatch(postSaqueAutomaticoAction(token, true));
			if (resSaqueAutomatico) {
				toast.error('Erro ao mudar saque automático');
				setLoading(false);
			} else {
				setSaqueAutomatico(true);
				setLoading(false);
			}
		}
		if (saqueAutomatico === true) {
			const resSaqueAutomatico = await dispatch(postSaqueAutomaticoAction(token, false));
			if (resSaqueAutomatico) {
				toast.error('Erro ao mudar saque automático');
				setLoading(false);
			} else {
				setSaqueAutomatico(false);
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		setSaqueAutomatico(userData.saque_automatico);
	}, [userData.saque_automatico]);

	return (
		<Box className={classes.UserInfosContainer}>
			<LoadingScreen isLoading={loading} />
			<Box display="flex">
				{userData && (userData.nome || userData.razao_social) ? (
					<Typography style={{ fontSize: '1.6rem' }}>
						Olá, <b>{realNome}</b>
					</Typography>
				) : (
					<Skeleton variant="text" animation="pulse" width="90%" height="40px" />
				)}
			</Box>
			<Box className={classes.userContentsContainer}>
				<Box className={classes.userContentItem}>
					<Typography color="primary" style={{ margin: '2px' }}>
						SALDO DISPONÍVEL:
					</Typography>
					{/* <Box display="flex" alignItems="center">
						{userData && userData.saldo.valor && saldoVisivel ? (
							<Typography color="secondary" style={{ fontSize: '1.6rem', fontWeight: 'bold' }}>
								<CurrencyFormat
									value={userData.saldo.valor.replace('.', ',')}
									displayType={'text'}
									thousandSeparator={'.'}
									decimalSeparator={','}
									prefix={'R$ '}
									renderText={(value) => <div>{value}</div>}
								/>
							</Typography>
						) : (
							<Skeleton variant="text" animation={false} width="30%" height="3rem" />
						)}
						<Box marginLeft="6px">
							<IconButton onClick={() => setSaldoVisivel(!saldoVisivel)}>
								{saldoVisivel ? <VisibilityOff height="30px" /> : <Visibility height="30px" />}
							</IconButton>
						</Box>
					</Box> */}

					<Box>
						<Button component={Link} to="extrato" style={{ margin: '2px' }}>
							Ver Extrato &gt;
						</Button>
					</Box>
				</Box>
				<Box className={classes.userContentItem}>
					<Tooltip title="(Se esta opção estiver desmarcada, seu saldo acumulará até que você solicite o saque para sua conta bancária.)">
						<Box display="flex" alignItems="center">
							<Typography style={{ margin: '2px' }}>Saque automático</Typography>
							<Box marginLeft="12px">
								<FontAwesomeIcon icon={faInfo} />
							</Box>
						</Box>
					</Tooltip>
					<Box display="flex" alignItems="center">
						<Typography style={{ marginRight: '6px' }}>Não</Typography>
						<FormControlLabel
							control={
								<Switch
									checked={saqueAutomatico === true ? true : false}
									onClick={handleSaqueAutomatico}
									name="saqueAutomatico"
								/>
							}
							label="Sim"
						/>
					</Box>
					<Box alignSelf="center">
						<GradientButton buttonText="REALIZAR SAQUE" onClick={handleOpenSaqueModal} />
					</Box>
					<SaqueModal
						open={open}
						onClose={() => {
							setOpen(false);
							setErrosSacar({});
						}}
						contasBancariasUser={contasBancariasUser}
						saqueInfos={saqueInfos}
						setSaqueInfos={setSaqueInfos}
						handleSacar={handleSacar}
						errosSacar={errosSacar}
					/>
				</Box>
				<Box className={classes.userContentItem}>
					<Typography>VALOR FUTURO</Typography>
					<Box display="flex" alignItems="center">
						{userData && userData.saldo.valor_futuro && valorFuturoVisivel ? (
							<Typography
								variant="h5"
								color="secondary"
								style={{ fontSize: '1.6rem', fontWeight: 'bold' }}
							>
								<CurrencyFormat
									value={userData.saldo.valor_futuro.replace('.', ',')}
									displayType={'text'}
									thousandSeparator={'.'}
									decimalSeparator={','}
									prefix={'R$ '}
									renderText={(value) => <div>{value}</div>}
								/>
							</Typography>
						) : (
							<Skeleton variant="text" animation={false} width="30%" height="3rem" />
						)}
						<Box marginLeft="6px">
							<IconButton onClick={() => setValorFuturoVisivel(!valorFuturoVisivel)}>
								{valorFuturoVisivel ? <VisibilityOff height="30px" /> : <Visibility height="30px" />}
							</IconButton>
						</Box>
					</Box>

					<Box>
						<Button component={Link} to="lancamentos-futuros" style={{ margin: '2px' }}>
							Ver mais &gt;{' '}
						</Button>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default UserInfos;
