import { Button, Typography } from '@material-ui/core';

import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	buttonGradient: {
		color: '#fff',
		borderRadius: '37px',
		background: theme.gradient.main,
	},
}));

const GradientButton = (props) => {
	const classes = useStyles();
	return (
		<Button {...props} className={classes.buttonGradient} variant="contained">
			<Typography variant="subtitle2" style={{ color: 'white', fontSize: '0.85rem' }}>
				{props.buttonText}
			</Typography>
		</Button>
	);
};

export default GradientButton;
