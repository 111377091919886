import NumberFormat from 'react-number-format';
import React from 'react';

const CustomCurrencyFormat = ({ ...rest }) => {
	const numberFormatProps = {
		displayType: 'text',
		thousandSeparator: '.',
		decimalSeparator: ',',
		thousandsGroupStyle: 'thousand',
		decimalScale: 2,
		fixedDecimalScale: true,
		prefix: 'R$ ',
	};

	return <NumberFormat {...numberFormatProps} {...rest} />;
};

export default CustomCurrencyFormat;
