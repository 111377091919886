import { Box, LinearProgress, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '@material-ui/lab/Pagination';

import { generatePath, useHistory } from 'react-router';
import GradientButton from '../../../reusable/GradientButton/GradientButton';
import useAuth from '../../../../hooks/useAuth';
import { loadPagadores, loadPagadoresFilter } from '../../../../actions/actions';
import CustomBreadcrumbs from '../../../reusable/CustomBreadcrumbs/CustomBreadcrumbs';
import SearchBar from '../../../reusable/SearchBar/SearchBar';
import CustomTable from '../../../reusable/CustomTable/CustomTable';
import useDebounce from '../../../../hooks/useDebounce';

const columns = [
	{ headerText: 'Nome', key: 'nome' },
	{ headerText: 'Documento', key: 'documento' },
	{ headerText: 'E-mail', key: 'email' },
	{ headerText: 'Contato', key: 'celular' },
	{
		headerText: 'Cobrar',
		key: 'id',
		CustomValue: (id) => {
			const history = useHistory();
			const handleRedirecionar = () => {
				const path = generatePath('/dashboard/novo-carne/:id/cobrar', {
					id: id,
				});
				history.push(path);
			};
			return <GradientButton onClick={handleRedirecionar} buttonText="Cobrar" />;
		},
	},
];

const NewPaymentBooklet = () => {
	const pagadoresList = useSelector((state) => state.pagadores);
	const token = useAuth();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const dispatch = useDispatch();
	const [page, setPage] = useState(1);

	const [filters, setFilters] = useState({
		like: '',
		order: '',
		mostrar: '',
	});

	useEffect(() => {
		dispatch(loadPagadores(token, page));
	}, [page]);

	const debouncedLike = useDebounce(filters.like, 800);

	useEffect(() => {
		dispatch(
			loadPagadoresFilter(token, undefined, debouncedLike, filters.order, filters.mostrar, undefined)
		);
	}, [token, filters.order, filters.mostrar, debouncedLike]);

	const handleChangePage = (e, value) => {
		setPage(value);
	};

	return (
		<Box display="flex" flexDirection="column">
			<CustomBreadcrumbs path1="Carnê" to1="/dashboard/carne" path2="Novo Carnê" />
			<Box display="flex" justifyContent="space-between" flexDirection={matches ? 'column' : null}>
				<Typography style={{ marginTop: '8px' }} variant="h4">
					Novo Carnê
				</Typography>
			</Box>
			<Box marginTop="16px" marginBottom="16px">
				<SearchBar
					fullWidth
					placeholder="Procurar por documento, nome..."
					value={filters.like}
					onChange={(e) =>
						setFilters({
							...filters,
							like: e.target.value,
						})
					}
				/>
			</Box>

			<>
				{pagadoresList.data && pagadoresList.per_page ? (
					<CustomTable columns={columns} data={pagadoresList.data} />
				) : (
					<LinearProgress />
				)}
				<Box alignSelf="flex-end" marginTop="8px">
					<Pagination
						variant="outlined"
						color="secondary"
						size="large"
						count={pagadoresList.last_page}
						onChange={handleChangePage}
						page={page}
					/>
				</Box>
			</>
		</Box>
	);
};

export default NewPaymentBooklet;
