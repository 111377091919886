import {
	Box,
	Card,
	CardActionArea,
	CardMedia,
	Grid,
	IconButton,
	makeStyles,
	Paper,
	Step,
	StepLabel,
	Stepper,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import {
	delDocumento,
	loadDocumentos,
	postDocumentoActionAdm,
	postNewConta,
} from '../../../../../actions/actions';
import useAuth from '../../../../../hooks/useAuth';
import NewAccount from '../../../../reusable/Account/NewAccount/NewAccount';
import CustomBreadcrumbs from '../../../../reusable/CustomBreadcrumbs/CustomBreadcrumbs';
import GradientButton from '../../../../reusable/GradientButton/GradientButton';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import ClearIcon from '@material-ui/icons/Clear';
import LoadingScreen from '../../../../reusable/LoadingScreen/LoadingScreen';
import { PictureAsPdf } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	layout: {
		width: '800px',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	card: {
		margin: theme.spacing(1),
		padding: 0,
	},
	paper: {
		marginBottom: theme.spacing(6),
		padding: theme.spacing(3),
		borderRadius: '27px',
		width: '850px',
		alignSelf: 'center',
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	dropzoneAreaBaseClasses: {
		width: '70%',
		height: '250px',
	},
	dropzoneContainer: {
		margin: '6px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '12px',
		minHeight: '564px',
		fontSize: '12px',
	},
	textoDropzone: {
		fontSize: '1.2rem',
	},
}));

const STEPS = {
	DADOS_DA_CONTA: 'Dados da Conta',
	DOCUMENTOS: 'Documentos',
};

const CreateNewAccount = () => {
	const classes = useStyles();
	const token = useAuth();
	const theme = useTheme();
	const dispatch = useDispatch();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [errosConta, setErrosConta] = useState({});
	const [steps, setsteps] = useState([STEPS.DADOS_DA_CONTA, STEPS.DOCUMENTOS]);
	const [activeStep, setActiveStep] = useState(0);
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const [conta, setConta] = useState({
		documento: '',
		nome: '',
		razao_social: '',
		cnpj: '',
		celular: '',
		data_nascimento: '',
		email: '',
		site: '',
		endereco: {
			cep: '',
			rua: '',
			numero: '',
			complemento: '',
			bairro: '',
			cidade: '',
			estado: '',
		},
	});

	const handleClick = async () => {
		setLoading(true);
		if (activeStep === 0) {
			let newConta = conta;
			const resConta = await dispatch(postNewConta(token, newConta));
			if (resConta) {
				setErrosConta(resConta);
				setLoading(false);
			} else {
				toast.success('Cadastro efetuado com sucesso!');
				setLoading(false);
				setActiveStep(activeStep + 1);
			}
		}
		if (activeStep === 1) {
			history.push('/dashboard/contas');
		}
	};

	const arquivoDocumento = useSelector((state) => state.arquivoDocumento);

	const contaCriada = useSelector((state) => state.conta);

	useEffect(() => {
		dispatch(loadDocumentos(token, contaCriada.id));
	}, [contaCriada.id]);

	const handleExcluirArquivo = async (item) => {
		setLoading(true);
		await dispatch(delDocumento(token, item.id));
		await dispatch(loadDocumentos(token, contaCriada.id));
		setLoading(false);
	};

	const onDropIdentificacao = async (picture, file) => {
		setLoading(true);
		const categoria = 'IDENTIFICACAO';
		await dispatch(postDocumentoActionAdm(token, picture, categoria, contaCriada.id));
		await dispatch(loadDocumentos(token, contaCriada.id));
		setLoading(false);
	};
	const onDropComprovanteResidencia = async (picture, file) => {
		setLoading(true);
		const categoria = 'COMPROVANTE_RESIDENCIA';
		await dispatch(postDocumentoActionAdm(token, picture, categoria, contaCriada.id));
		await dispatch(loadDocumentos(token, contaCriada.id));
		setLoading(false);
	};
	const onDropAtividade = async (picture, file) => {
		setLoading(true);
		const categoria = 'COMPROVANTE_ATIVIDADE';
		await dispatch(postDocumentoActionAdm(token, picture, categoria, contaCriada.id));
		await dispatch(loadDocumentos(token, contaCriada.id));
		setLoading(false);
	};
	const onDropOutros = async (picture, file) => {
		setLoading(true);
		const categoria = 'OUTROS';
		await dispatch(postDocumentoActionAdm(token, picture, categoria, contaCriada.id));
		await dispatch(loadDocumentos(token, contaCriada.id));
		setLoading(false);
	};

	const STEPS_COMPONENTS = {
		[STEPS.DADOS_DA_CONTA]: <NewAccount conta={conta} setConta={setConta} errosConta={errosConta} />,
		[STEPS.DOCUMENTOS]: (
			<Box display="flex" style={matches ? { flexDirection: 'column' } : null} justifyContent="center">
				<Box display="flex" flexDirection="column" margin="8px">
					<Box className={classes.dropzoneContainer} boxShadow={3}>
						<Typography variant="h6">IDENTIFICAÇÃO</Typography>
						<Typography>RG, CPF ou CNH.</Typography>
						<DropzoneAreaBase
							dropzoneParagraphClass={classes.textoDropzone}
							maxFileSize={3145728}
							onDropRejected={() => {
								toast.error('Tamanho máximo: 3mb ');
								toast.error('Arquivos suportados: .pdf .png .jpg .jpeg');
							}}
							acceptedFiles={['image/*', 'application/pdf']}
							dropzoneClass={classes.dropzoneAreaBaseClasses}
							onAdd={onDropIdentificacao}
							filesLimit={1}
							dropzoneText="Arraste e solte o arquivo aqui ou clique para escolher"
							showPreviews={false}
							showPreviewsInDropzone={false}
						/>
						<Box width="300px">
							<Grid container>
								{arquivoDocumento.map((item) =>
									item.categoria === 'IDENTIFICACAO' ? (
										<Grid item xs={6}>
											<Card className={classes.card}>
												<CardActionArea>
													<Box position="absolute">
														<IconButton
															onClick={() => handleExcluirArquivo(item)}
															size="small"
															style={{ color: 'white', backgroundColor: 'red' }}
														>
															<ClearIcon />
														</IconButton>
													</Box>

													{item.descricao === 'application/pdf' ? (
														<PictureAsPdf
															style={{ height: '100px', width: '100%' }}
															onClick={() => window.open(item.arquivo)}
														/>
													) : (
														<CardMedia
															component="img"
															alt="Arquivo de Identificação"
															height="100"
															image={item.arquivo}
															onClick={() => window.open(item.arquivo)}
														/>
													)}
												</CardActionArea>
											</Card>
										</Grid>
									) : (
										false
									)
								)}
							</Grid>
						</Box>
					</Box>
					<Box className={classes.dropzoneContainer} boxShadow={3}>
						<Typography variant="h6">COMPROVANTE DE RESIDÊNCIA</Typography>
						<Typography>Conta de luz, telefone e água.</Typography>
						<DropzoneAreaBase
							dropzoneParagraphClass={classes.textoDropzone}
							maxFileSize={3145728}
							onDropRejected={() => {
								toast.error('Tamanho máximo: 3mb ');
								toast.error('Arquivos suportados: .pdf .png .jpg .jpeg');
							}}
							acceptedFiles={['image/*', 'application/pdf']}
							dropzoneClass={classes.dropzoneAreaBaseClasses}
							onAdd={onDropComprovanteResidencia}
							filesLimit={1}
							dropzoneText="Arraste e solte o arquivo aqui ou clique para escolher"
							showPreviews={false}
							showPreviewsInDropzone={false}
						/>
						<Box width="300px">
							<Grid container>
								{arquivoDocumento.map((item) =>
									item.categoria === 'COMPROVANTE_RESIDENCIA' ? (
										<Grid item xs={6}>
											<Card className={classes.card}>
												<CardActionArea>
													<Box position="absolute">
														<IconButton
															onClick={() => handleExcluirArquivo(item)}
															size="small"
															style={{ color: 'white', backgroundColor: 'red' }}
														>
															<ClearIcon />
														</IconButton>
													</Box>

													{item.descricao === 'application/pdf' ? (
														<PictureAsPdf
															style={{ height: '100px', width: '100%' }}
															onClick={() => window.open(item.arquivo)}
														/>
													) : (
														<CardMedia
															component="img"
															alt="Comrpovante de Residência"
															height="100"
															image={item.arquivo}
															onClick={() => window.open(item.arquivo)}
														/>
													)}
												</CardActionArea>
											</Card>
										</Grid>
									) : (
										false
									)
								)}
							</Grid>
						</Box>
					</Box>
				</Box>
				<Box display="flex" flexDirection="column" margin="8px">
					<Box className={classes.dropzoneContainer} boxShadow={3}>
						<Typography variant="h6">COMPROVANTE DE ATIVIDADE</Typography>
						<Typography>Nota fiscal ou cartão de visita</Typography>
						<DropzoneAreaBase
							dropzoneParagraphClass={classes.textoDropzone}
							maxFileSize={3145728}
							onDropRejected={() => {
								toast.error('Tamanho máximo: 3mb ');
								toast.error('Arquivos suportados: .pdf .png .jpg .jpeg');
							}}
							acceptedFiles={['image/*', 'application/pdf']}
							dropzoneClass={classes.dropzoneAreaBaseClasses}
							onAdd={onDropAtividade}
							filesLimit={1}
							dropzoneText="Arraste e solte o arquivo aqui ou clique para escolher"
							showPreviews={false}
							showPreviewsInDropzone={false}
						/>
						<Box width="300px">
							<Grid container>
								{arquivoDocumento.map((item) =>
									item.categoria === 'COMPROVANTE_ATIVIDADE' ? (
										<Grid item xs={6}>
											<Card className={classes.card}>
												<CardActionArea>
													<Box position="absolute">
														<IconButton
															onClick={() => handleExcluirArquivo(item)}
															size="small"
															style={{ color: 'white', backgroundColor: 'red' }}
														>
															<ClearIcon />
														</IconButton>
													</Box>

													{item.descricao === 'application/pdf' ? (
														<PictureAsPdf
															style={{ height: '100px', width: '100%' }}
															onClick={() => window.open(item.arquivo)}
														/>
													) : (
														<CardMedia
															component="img"
															alt="Comrpovante de Residência"
															height="100"
															image={item.arquivo}
															onClick={() => window.open(item.arquivo)}
														/>
													)}
												</CardActionArea>
											</Card>
										</Grid>
									) : (
										false
									)
								)}
							</Grid>
						</Box>
					</Box>
					<Box className={classes.dropzoneContainer} boxShadow={3}>
						<Typography variant="h6">OUTROS</Typography>
						<Typography>Contrato social.</Typography>
						<DropzoneAreaBase
							dropzoneParagraphClass={classes.textoDropzone}
							maxFileSize={3145728}
							onDropRejected={() => {
								toast.error('Tamanho máximo: 3mb ');
								toast.error('Arquivos suportados: .pdf .png .jpg .jpeg');
							}}
							acceptedFiles={['image/*', 'application/pdf']}
							dropzoneClass={classes.dropzoneAreaBaseClasses}
							onAdd={onDropOutros}
							filesLimit={1}
							dropzoneText="Arraste e solte o arquivo aqui ou clique para escolher"
							showPreviews={false}
							showPreviewsInDropzone={false}
						/>
						<Box width="300px">
							<Grid container>
								{arquivoDocumento.map((item) =>
									item.categoria === 'OUTROS' ? (
										<Grid item xs={6}>
											<Card className={classes.card}>
												<CardActionArea>
													<Box position="absolute">
														<IconButton
															onClick={() => handleExcluirArquivo(item)}
															size="small"
															style={{ color: 'white', backgroundColor: 'red' }}
														>
															<ClearIcon />
														</IconButton>
													</Box>

													{item.descricao === 'application/pdf' ? (
														<PictureAsPdf
															style={{ height: '100px', width: '100%' }}
															onClick={() => window.open(item.arquivo)}
														/>
													) : (
														<CardMedia
															component="img"
															alt="Comrpovante de Residência"
															height="100"
															image={item.arquivo}
															onClick={() => window.open(item.arquivo)}
														/>
													)}
												</CardActionArea>
											</Card>
										</Grid>
									) : (
										false
									)
								)}
							</Grid>
						</Box>
					</Box>
				</Box>
			</Box>
		),
	};

	return (
		<Box className={classes.root}>
			<CustomBreadcrumbs path1="Contas" path2="Criar Conta" to1="/dashboard/contas" />
			<LoadingScreen isLoading={loading} />
			<Paper className={classes.paper}>
				<Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel>
					{steps.map((label) => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>
				{STEPS_COMPONENTS[steps[activeStep]]}
				<Box alignSelf="flex-end" marginTop="16px">
					<GradientButton
						buttonText={activeStep === 0 ? 'Cadastrar' : 'Finalizar'}
						onClick={handleClick}
					/>
				</Box>
			</Paper>
		</Box>
	);
};
export default CreateNewAccount;
