import {
	Box,
	Button,
	LinearProgress,
	Menu,
	MenuItem,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { loadContas, loadContasFilter } from '../../../../actions/actions';
import { useDispatch, useSelector } from 'react-redux';

import CustomBreadcrumbs from '../../../reusable/CustomBreadcrumbs/CustomBreadcrumbs';
import CustomTable from '../../../reusable/CustomTable/CustomTable';
import Pagination from '@material-ui/lab/Pagination';
import SearchBar from '../../../reusable/SearchBar/SearchBar';
import useAuth from '../../../../hooks/useAuth';
import useDebounce from '../../../../hooks/useDebounce';

const columns = [
	/* { headerText: 'Nome', key: 'nome' }, */
	{
		headerText: 'Conta',
		key: '',
		Teste: (i) => {
			return (
				<>
					<Typography>{i.nome !== undefined ? i.nome : null}</Typography>
					<Typography>
						{i.tipo === 'Pessoa Jurídica' && i.razao_social !== undefined ? i.razao_social : null}
					</Typography>
				</>
			);
		},
	},
	{ headerText: 'Tipo', key: 'tipo' },
	{
		headerText: 'Documento',
		key: '',
		Teste: (i) => {
			return (
				<>
					<Typography>{i.documento !== undefined ? i.documento : null}</Typography>
					<Typography>{i.tipo === 'Pessoa Jurídica' && i.cnpj !== undefined ? i.cnpj : null}</Typography>
				</>
			);
		},
	},
	{ headerText: 'E-mail', key: 'email' },
	{ headerText: 'Contato', key: 'celular' },
	{
		headerText: 'Editar',
		key: 'menu',
		CustomValue: (value) => <h1>{value}</h1>,
	},
];

const Accounts = () => {
	const token = useAuth();
	const history = useHistory();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const dispatch = useDispatch();
	const [page, setPage] = useState(1);
	const userData = useSelector((state) => state.userData);
	const contas = useSelector((state) => state.contas);

	const [filters, setFilters] = useState({
		like: '',
		order: '',
		mostrar: '',
	});

	const debouncedLike = useDebounce(filters.like, 800);

	useEffect(() => {
		dispatch(loadContasFilter(token, page, debouncedLike, filters.order, filters.mostrar));
	}, [page, filters.order, filters.mostrar, debouncedLike]);

	useEffect(() => {
		return () => {
			setFilters({ ...filters });
		};
	}, []);

	const handleChangePage = (e, value) => {
		setPage(value);
	};
	const handleCreateAccount = () => {
		const path = generatePath('/dashboard/contas-criar');
		history.push(path);
	};

	const Editar = ({ row }) => {
		const [anchorEl, setAnchorEl] = useState(null);
		const handleClick = (event) => {
			setAnchorEl(event.currentTarget);
		};
		const handleClose = () => {
			setAnchorEl(null);
		};

		const handleEditar = (row) => {
			const path = generatePath('/dashboard/contas/:id/editar', {
				id: row.id,
			});
			history.push(path);
		};

		const handleUsuarios = (row) => {
			const path = generatePath('/dashboard/contas/:id/users', {
				id: row.id,
			});
			history.push(path);
		};

		const handleGerenciarListas = (row) => {
			const path = generatePath('/dashboard/contas/:id/listas', {
				id: row.id,
			});
			history.push(path);
		};

		return (
			<Box>
				<Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
					<span class="material-icons" style={{ color: '#CC9B00', fontSize: '25px' }}>
						menu
					</span>
				</Button>
				<Menu
					id="simple-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<MenuItem onClick={() => handleEditar(row)}>Editar</MenuItem>
					<MenuItem onClick={() => handleUsuarios(row)}>Gerenciar Usuários</MenuItem>
					<MenuItem onClick={() => handleGerenciarListas(row)}>Gerenciar Listas</MenuItem>
				</Menu>
			</Box>
		);
	};

	return (
		<Box display="flex" flexDirection="column">
			<CustomBreadcrumbs path1="Contas" />
			<Box display="flex" justifyContent="space-between" flexDirection={matches ? 'column' : null}>
				<Typography style={{ marginTop: '8px', marginBottom: '12px' }} variant="h4">
					Contas
				</Typography>

				<Button
					variant="outlined"
					style={{ marginTop: '8px', marginBottom: '12px' }}
					onClick={handleCreateAccount}
				>
					Criar conta
				</Button>
			</Box>
			<Box marginBottom="16px">
				<SearchBar
					fullWidth
					placeholder="Pesquisar por nome, documento, e-mail..."
					value={filters.like}
					onChange={(e) =>
						setFilters({
							...filters,
							like: e.target.value,
						})
					}
				/>
			</Box>

			<>
				{contas.data && contas.per_page ? (
					<CustomTable columns={columns} data={contas.data} Editar={Editar} />
				) : (
					<LinearProgress />
				)}
				<Box alignSelf="flex-end" marginTop="8px">
					{
						<Pagination
							variant="outlined"
							color="secondary"
							size="large"
							count={contas.last_page}
							onChange={handleChangePage}
							page={page}
						/>
					}
				</Box>
			</>
		</Box>
	);
};

export default Accounts;
