import {
	Box,
	Button,
	LinearProgress,
	Menu,
	MenuItem,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { delPlano, loadPlanosFilters } from '../../../../actions/actions';
import { generatePath, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import CustomBreadcrumbs from '../../../reusable/CustomBreadcrumbs/CustomBreadcrumbs';
import CustomTable from '../../../reusable/CustomTable/CustomTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GradientButton from '../../../reusable/GradientButton/GradientButton';
import Pagination from '@material-ui/lab/Pagination';
import SearchBar from '../../../reusable/SearchBar/SearchBar';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import useAuth from '../../../../hooks/useAuth';

const columns = [
	{
		headerText: 'Criado em',
		key: 'created_at',
		CustomValue: (data) => {
			const p = data.split(/\D/g);
			const dataFormatada = [p[2], p[1], p[0]].join('/');
			return (
				<Box display="flex" justifyContent="center">
					<FontAwesomeIcon icon={faCalendarAlt} size="lg" />
					<Typography style={{ marginLeft: '6px' }}>{dataFormatada}</Typography>
				</Box>
			);
		},
	},
	// { headerText: 'Situação', key: 'situacao' },
	{ headerText: 'Plano', key: 'nome' },
	{ headerText: 'Frequência', key: 'frequencia' },
	{ headerText: 'Descrição', key: 'descricao' },
	{
		headerText: 'Valor',
		key: 'valor',
		CustomValue: (valor) => {
			return (
				<Typography variant="" style={{ fontSize: 17, fontWeight: 600, color: 'green' }}>
					R$ {valor}
				</Typography>
			);
		},
	},
	{ headerText: 'Ações', key: 'menu' },
];

const SubscriptionPlans = () => {
	const history = useHistory();
	const token = useAuth();

	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const [filters, setFilters] = useState({
		like: '',
		order: '',
		mostrar: '',
	});
	const dispatch = useDispatch();
	const [page, setPage] = useState(1);

	const planosList = useSelector((state) => state.planosList);

	useEffect(() => {
		dispatch(loadPlanosFilters(token, page, filters.like, filters.order, filters.mostrar));
	}, [page, filters, planosList.data.total]);

	const handleNewPlano = () => {
		history.push('/dashboard/novo-plano');
	};

	const handleChangePage = (e, value) => {
		setPage(value);
	};

	const Editar = ({ row }) => {
		const [anchorEl, setAnchorEl] = useState(null);
		const handleClick = (event) => {
			setAnchorEl(event.currentTarget);
		};
		const handleClose = () => {
			setAnchorEl(null);
		};

		const handleExcluir = async () => {
			await dispatch(delPlano(token, row.id));
			setAnchorEl(null);
		};
		const handleEditar = (row) => {
			const path = generatePath('/dashboard/novo-plano/:id/editar', {
				id: row.id,
			});
			history.push(path);
		};

		return (
			<Box>
				<Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
					<span class="material-icons" style={{ color: '#CC9B00', fontSize: '25px' }}>
						menu
					</span>
				</Button>
				<Menu
					id="simple-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<MenuItem onClick={() => handleEditar(row)}>Editar</MenuItem>
					<MenuItem onClick={() => handleExcluir(row)}>Excluir</MenuItem>
				</Menu>
			</Box>
		);
	};

	return (
		<Box display="flex" flexDirection="column">
			<CustomBreadcrumbs path1="Planos" />
			<Box display="flex" justifyContent="space-between" flexDirection={matches ? 'column' : null}>
				<Typography style={{ marginTop: '8px' }} variant="h4">
					Planos
				</Typography>
				<Box>
					<GradientButton buttonText="+ Novo Plano" onClick={handleNewPlano} />
				</Box>
			</Box>
			<Box marginTop="16px" marginBottom="16px">
				<SearchBar
					fullWidth
					placeholder="Pesquisar por nome, descrição..."
					value={filters.like}
					onChange={(e) =>
						setFilters({
							...filters,
							like: e.target.value,
						})
					}
				/>
			</Box>

			{planosList.data && planosList.per_page ? (
				<CustomTable columns={columns} data={planosList.data} Editar={Editar} />
			) : (
				<LinearProgress />
			)}
			<Box alignSelf="flex-end" marginTop="8px">
				<Pagination
					variant="outlined"
					color="secondary"
					size="large"
					count={planosList.last_page}
					onChange={handleChangePage}
					page={page}
				/>
			</Box>
		</Box>
	);
};

export default SubscriptionPlans;
