import '../fonts/humanist-777-bt.ttf';
import '../fonts/microgramma-d-bold-extended.otf';
import '../fonts/humanist-777-bold-bt.ttf';
import '../fonts/humanist-777-black-bt.ttf';

import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
	palette: {
		background: {
			default: '#FFDD00',
			paper: '#F0F0F0',
			gradient: 'linear-gradient(135deg, rgba(2,149,59,1) 15%, rgba(75,75,150,1) 100%)'
		},

		primary: {
			main: '#FFDD00',
			dark: '#CC9B00',
			light: '#fff5b1',
		},
		secondary: {
			main: '#3D3935',
		},
	},
	typography: {
		fontFamily: 'Humanist-777-bt',
		allVariants: {
			color: '#3D3935',
		},
		h1: {
			fontFamily: 'microgramma-d-bold-extended',
			fontSize: '48px',
		},
		subtitle1: {
			fontFamily: 'Humanist-777-lt-bt-light',
			fontSize: '16px',
		},
		subtitle2: {
			fontFamily: 'Humanist-777-black-bt',
			fontSize: '16px',
		},
		h4: {
			fontFamily: 'Humanist-777-black-bt',
		},
	},
	shape: {
		borderRadius: '27px',
	},
	overrides: {
		MuiOutlinedInput: {
			root: {
				height: '45px',
				'&$cssFocused $notchedOutline': {
					borderWidth: '0px',
				},
				boxShadow: '0px 0px 2px 2px rgba(61, 57, 53, 0.3)',

				borderWidth: '0px',
				'& :-webkit-autofill': {
					'-webkit-box-shadow': '0 0 0px 1000px white inset',
					'-webkit-padding-after': '13px',
					'-webkit-padding-before': '13px',
					'-webkit-padding-end': '13px',
					'-webkit-padding-start': '13px',
				},
				'& $notchedOutline': {
					borderWidth: 0,
				},
				'&:hover $notchedOutline': {
					borderWidth: 0,
				},
				'&$focused $notchedOutline': {
					borderWidth: 0,
					boxShadow: '0px 0px 2px 2px rgba(61, 57, 53, 0.4)',
				},
			},
			focused: {
				borderWidth: '0px',
			},
			notchedOutline: {
				borderWidth: '0px',
			},
			input: {
				'&::placeholder': {
					textOverflow: 'ellipsis !important',
					color: '#CC9B00',
					fontWeight: '600',
					fontSize: '14px',
				},
				backgroundColor: 'white',
				borderRadius: '27px',
				height: '10px',
			},
		},

		MuiTextField: {
			root: {
				margin: '10px 0px 0px 0px',
			},
		},
		MuiInputLabel: {
			outlined: {
				transform: 'translate(14px, 15px) scale(1)',
				'&$shrink': {
					transform: 'translate(14px, -20px) scale(0.8)',
					color: '#3D3935',
					fontFamily: 'Humanist-777-black-bt',
				},
			},
		},
		MuiButton: {
			contained: {
				fontFamily: 'Humanist-777-black-bt',
				fontSize: '0.8rem',
			},
		},
	},
});

export const cssVariables = {
	gradient: {
		main: 'linear-gradient(to right top, #cc9b00, #cc9b00);',
	},
};
