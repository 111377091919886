import { Box, TextField } from '@material-ui/core';

import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	textField: {
		backgroundColor: 'white',
		borderRadius: '27px',
		boxShadow: '0px 0px 30px -3px #CC9B00',
		height: '45px !important',
	},

	cssLabel: {},
	placeHolderColor: {
		'&::placeholder': {
			textOverflow: 'ellipsis !important',
			color: '#CC9B00',
			fontWeight: '600',
			fontSize: '14px',
		},
	},

	cssOutlinedInput: {
		borderRadius: '27px',
		height: '45px',
		'&$cssFocused $notchedOutline': {
			borderWidth: '0',
		},
		'& :-webkit-autofill': {
			'-webkit-box-shadow': '0 0 0px 1000px white inset',
			'-webkit-padding-after': '13px',
			'-webkit-padding-before': '13px',
			'-webkit-padding-end': '13px',
			'-webkit-padding-start': '13px',
		},
	},

	cssFocused: {
		borderWidth: '0',
	},

	notchedOutline: {
		borderWidth: '0',
	},
}));

const CustomTextField = (rest) => {
	const classes = useStyles();
	return (
		<TextField
			variant="outlined"
			{...rest}
			className={classes.textField}
			InputProps={{
				classes: {
					root: classes.cssOutlinedInput,
					focused: classes.cssFocused,
					notchedOutline: classes.notchedOutline,
					input: classes.placeHolderColor,
				},
			}}
		/>
	);
};

export default CustomTextField;
