import { Box, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { authMe2, loadPermissao } from '../../../actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import AccountRegistration from '../AccountRegistration/AccountRegistration';
import AccountStatement from '../AccountStatement/AccountStatement';
import AccountSubscriptions from '../AccountSubscriptions/AccountSubscriptions';
import Accounts from '../Adm/Accounts/Accounts';
import Adm from '../Adm/Adm';
import AdmLists from '../AdmLists/AdmLists';
import ContentAreas from '../ContentAreas/ContentAreas';
import CreateNewAccount from '../Adm/Accounts/CreateNewAccount/CreateNewAccount';
import CreateNewPayer from '../Payers/CreateNewPayer/CreateNewPayer';
import CreditCard from '../CreditCard/CreditCard';
import CreditCardBillingList from '../CreditCard/CreditCardBillingList/CreditCardBillingList';
import DigitalAccounts from '../DigitalAccounts/DigitalAccounts';
import DrawerSideBar from '../../reusable/DrawerSideBar/DrawerSideBar';
import EditAccount from '../Adm/Accounts/EditAccount/EditAccount';
import EditDigitalAccount from '../EditDigitalAccount/EditDigitalAccount';
import EditLoginAccount from '../EditLoginAccount/EditLoginAccount';
import EditPayer from '../Payers/EditPayer/EditPayer';
import Fade from 'react-reveal/Fade';
import FutureTransactions from '../FutureTransactions/FutureTransactions';
import Home from '../Home/Home';
import MakeTransfer from '../MakeTransfer/MakeTransfer';
import NewAccountSubscriptions from '../AccountSubscriptions/NewAccountSubscriptions/NewAccountSubscriptions';
import NewPaymentBooklet from '../PaymentBooklet/NewPaymentBooklet/NewPaymentBooklet';
import NewPaymentLink from '../PaymentLink/NewPaymentLink/NewPaymentLink';
import NewPaymentSlip from '../PaymentSlip/NewPaymentSlip/NewPaymentSlip';
import NewPublicToken from '../PublicTokens/NewPublicToken/NewPublicToken';
import NewSubscriptionPlans from '../AccountSubscriptions/NewSubscriptionPlans/NewSubscriptionPlans';
import NewTypedSale from '../CreditCard/NewTypedSale/NewTypedSale';
import NewUser from '../Adm/Users/NewUser/NewUser';
import Payers from '../Payers/Payers';
import PaymentBooklet from '../PaymentBooklet/PaymentBooklet';
import PaymentBookletDetails from '../PaymentBooklet/PaymentBookletDetails/PaymentBookletDetails';
import PaymentBookletRegistration from '../PaymentBooklet/PaymentBookletRegistration/PaymentBookletRegistration';
import PaymentLink from '../PaymentLink/PaymentLink';
import PaymentLinkDetails from '../PaymentLink/PaymentLinkDetails/PaymentLinkDetails';
import PaymentSlip from '../PaymentSlip/PaymentSlip';
import PaymentSlipList from '../PaymentSlipList/PaymentSlipList';
import SimpleSale from '../CreditCard/SimpleSale/SimpleSale';
import SubscriptionPlans from '../AccountSubscriptions/SubscriptionPlans/SubscriptionPlans';
import TransactionDetails from '../TransactionDetails/TransactionDetails';
import TransactionHistory from '../TransactionHistory/TransactionHistory';
import TransferHistory from '../TransferHistory/TransferHistory';
import UserPermissions from '../UserPermissions/UserPermissions';
import Users from '../Adm/Users/Users';
import Zoom from 'react-reveal/Zoom';
import backgroundLogoYellow from '../../../assets/logos/backgroundLogoYellow.svg';
import { get } from 'lodash';
import headerLogo from '../../../assets/logos/headerLogo.svg';
import useAuth from '../../../hooks/useAuth';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	homeRoot: {
		display: 'flex',
		flexDirection: 'column',
		height: '100vh',
		width: '100vw',
		margin: '0px',
		padding: '0px',
		background: `linear-gradient(0deg, ${theme.palette.background.paper} 70%, ${theme.palette.background.default} 50%)`,
	},
	pagesRoot: {
		display: 'flex',
		flexDirection: 'column',
		height: '100vh',
		margin: '0px',
		padding: '0px',
		background: `linear-gradient(0deg, ${theme.palette.background.paper} 90%, ${theme.palette.background.default} 50%)`,
	},
	homeContentContainer: {
		[theme.breakpoints.down('md')]: {
			padding: '40px 100px',
		},
		padding: '40px 300px',
	},
	smallHomeContentContainer: {},
	pagesContentContainer: {
		[theme.breakpoints.down('md')]: {
			padding: '40px 100px',
		},
		[theme.breakpoints.down(850)]: {
			padding: '40px 40px',
		},
		padding: '40px 250px',
	},
}));

const Dashboard = () => {
	const classes = useStyles();
	const authMe = useSelector((state) => state.authMe);
	const dispatch = useDispatch();
	const [permissoes, setPermissoes] = useState([]);
	const token = useAuth();
	const userPermissao = useSelector((state) => state.userPermissao);
	const { section, id, subsection } = useParams();
	let location = useLocation();

	useEffect(() => {
		dispatch(authMe2(token));
	}, []);

	useEffect(() => {
		if (authMe.id !== undefined) {
			dispatch(loadPermissao(token, authMe.id));
		}
	}, [authMe.id]);

	useEffect(() => {
		const { permissao } = userPermissao;
		setPermissoes(permissao.map((item) => item.tipo));
	}, [userPermissao]);

	useEffect(() => {
		return () => {
			setPermissoes([]);
		};
	}, []);

	let content = null;

	switch (section) {
		case 'home':
			content = permissoes.includes('Administrador') ? null : <Home />;
			break;

		case 'solicitacoes-de-cobranca':
			content = permissoes.includes('Administrador') ? null : <ContentAreas />;
			break;

		case 'financas':
			content = permissoes.includes('Administrador') ? null : <ContentAreas />;
			break;

		case 'transferencias':
			content = permissoes.includes('Administrador') ? null : <ContentAreas />;
			break;

		case 'outros-servicos':
			content = permissoes.includes('Administrador') ? null : <ContentAreas />;
			break;

		case 'link-pagamento':
			content =
				permissoes.includes('Administrador') || permissoes.includes('Cobranca - Link Pagamento') ? (
					<PaymentLink />
				) : null;
			break;

		case 'novo-link-pagamento':
			content = permissoes.includes('Cobranca - Link Pagamento') ? <NewPaymentLink /> : null;
			break;

		case 'boleto':
			content =
				permissoes.includes('Administrador') || permissoes.includes('Cobranca - Boleto') ? (
					<PaymentSlipList />
				) : null;
			break;

		case 'gerar-boleto':
			content = permissoes.includes('Cobranca - Boleto') ? <PaymentSlip /> : null;
			break;

		case 'novo-boleto':
			switch (id) {
				default:
					content = permissoes.includes('Cobranca - Boleto') ? <NewPaymentSlip /> : null;
					switch (subsection) {
						default:
							content = permissoes.includes('Cobranca - Boleto') ? <NewPaymentSlip /> : null;
					}
			}
			break;

		case 'editar-conta':
			content = permissoes.includes('Administrador') ? null : <EditLoginAccount />;
			break;

		case 'novo-token-publico':
			content = permissoes.includes('Administrador') ? null : <NewPublicToken />;
			break;

		case 'extrato':
			content =
				permissoes.includes('Administrador') || permissoes.includes('Financa - Extrato') ? (
					<AccountStatement />
				) : null;
			break;

		case 'lancamentos-futuros':
			content =
				permissoes.includes('Administrador') || permissoes.includes('Financa - Lancamento Funturos') ? (
					<FutureTransactions />
				) : null;
			break;

		case 'assinaturas':
			content =
				permissoes.includes('Administrador') || permissoes.includes('Cobranca - Assinatura') ? (
					<AccountSubscriptions />
				) : null;
			break;

		case 'planos-de-assinaturas':
			content = permissoes.includes('Cobranca - Assinatura') ? <SubscriptionPlans /> : null;
			break;

		case 'nova-assinatura':
			content = permissoes.includes('Cobranca - Assinatura') ? <NewAccountSubscriptions /> : null;
			break;

		case 'criar-conta-digital':
			content = permissoes.includes('Outros - Contas Digitais') ? <AccountRegistration /> : null;
			break;

		case 'contas-digitais':
			switch (id) {
				default:
					content =
						permissoes.includes('Administrador') || permissoes.includes('Outros - Contas Digitais') ? (
							<DigitalAccounts />
						) : null;
					switch (subsection) {
						case 'editar':
							content =
								permissoes.includes('Administrador') ||
								permissoes.includes('Outros - Contas Digitais') ? (
									<EditDigitalAccount />
								) : null;
							break;
						default:
					}
			}

			break;

		case 'credito':
			content = permissoes.includes('Cobranca - Cartao') ? <CreditCard /> : null;
			break;

		case 'cobrancas-credito':
			content =
				permissoes.includes('Administrador') || permissoes.includes('Cobranca - Cartao') ? (
					<CreditCardBillingList />
				) : null;
			break;

		case 'venda-digitada':
			switch (id) {
				default:
					content = permissoes.includes('Cobranca - Cartao') ? <NewTypedSale /> : null;
					switch (subsection) {
						default:
							content = permissoes.includes('Cobranca - Cartao') ? <NewTypedSale /> : null;
					}
			}
			break;

		case 'pagadores':
			switch (id) {
				default:
					content =
						permissoes.includes('Administrador') || permissoes.includes('Outros - Pagadores') ? (
							<Payers />
						) : null;
					switch (subsection) {
						case 'editar':
							content = permissoes.includes('Outros - Pagadores') ? <EditPayer /> : null;
							break;
						default:
					}
			}
			break;

		case 'novo-pagadores':
			content = permissoes.includes('Outros - Pagadores') ? <CreateNewPayer /> : null;
			break;

		case 'venda-digitada-simples':
			content = permissoes.includes('Cobranca - Cartao') ? <SimpleSale /> : null;
			break;

		case 'carne':
			content =
				permissoes.includes('Administrador') || permissoes.includes('Cobranca - Carne') ? (
					<PaymentBooklet />
				) : null;
			break;

		case 'novo-carne':
			switch (id) {
				default:
					content = permissoes.includes('Cobranca - Carne') ? <NewPaymentBooklet /> : null;
					switch (subsection) {
						case 'cobrar':
							content = permissoes.includes('Cobranca - Carne') ? (
								<PaymentBookletRegistration />
							) : null;
							break;
						default:
					}
			}
			break;

		case 'adm':
			content = permissoes.includes('Administrador') ? <Adm /> : null;
			break;

		case 'contas-criar':
			content = permissoes.includes('Administrador') ? <CreateNewAccount /> : null;
			break;

		case 'contas':
			switch (id) {
				default:
					content = permissoes.includes('Administrador') ? <Accounts /> : null;
					switch (subsection) {
						case 'users':
							content = permissoes.includes('Administrador') ? <Users /> : null;
							break;
						case 'users-novo':
							content = permissoes.includes('Administrador') ? <NewUser /> : null;
							break;
						case 'listas':
							content = permissoes.includes('Administrador') ? <AdmLists /> : null;
							break;
						case 'editar':
							content = permissoes.includes('Administrador') ? <EditAccount /> : null;
							break;
						case 'historico-de-transacoes':
							content = permissoes.includes('Administrador') ? <TransactionHistory /> : null;
							break;
						case 'extrato':
							content = permissoes.includes('Administrador') ? <AccountStatement /> : null;
							break;
						case 'historico-transferencia':
							content = permissoes.includes('Administrador') ? <TransferHistory /> : null;
							break;
						case 'pagadores':
							content = permissoes.includes('Administrador') ? <Payers /> : null;
							break;
						case 'cobrancas-credito':
							content = permissoes.includes('Administrador') ? <CreditCardBillingList /> : null;
							break;
						case 'boleto':
							content = permissoes.includes('Administrador') ? <PaymentSlipList /> : null;
							break;
						case 'carne':
							content = permissoes.includes('Administrador') ? <PaymentBooklet /> : null;
							break;
						case 'link-pagamento':
							content = permissoes.includes('Administrador') ? <PaymentLink /> : null;
							break;
						case 'assinaturas':
							content = permissoes.includes('Administrador') ? <AccountSubscriptions /> : null;
							break;
						default:
					}
			}
			break;

		case 'user':
			switch (id) {
				default:
					switch (subsection) {
						case 'permissoes':
							content = permissoes.includes('Administrador') ? <UserPermissions /> : null;
							break;
						default:
					}
			}
			break;

		case 'novo-plano':
			switch (id) {
				default:
					content = permissoes.includes('Cobranca - Assinatura') ? <NewSubscriptionPlans /> : null;
					switch (subsection) {
						default:
							content = permissoes.includes('Cobranca - Assinatura') ? <NewSubscriptionPlans /> : null;
					}
			}
			break;

		case 'nova-transferencia':
			content = permissoes.includes('Transferencia - Realizar Transferencia') ? <MakeTransfer /> : null;
			break;

		case 'historico-transferencia':
			content =
				permissoes.includes('Administrador') ||
				permissoes.includes('Transferencia - Historico Transferencia') ? (
					<TransferHistory />
				) : null;
			break;

		case 'detalhes-carne':
			switch (id) {
				default:
					content =
						permissoes.includes('Administrador') || permissoes.includes('Cobranca - Carne') ? (
							<PaymentBookletDetails />
						) : null;
					break;
			}
			break;

		case 'historico-de-transacoes':
			content =
				permissoes.includes('Administrador') || permissoes.includes('Financa - Historico Transacoes') ? (
					<TransactionHistory />
				) : null;

			break;

		case 'detalhes-transacao':
			switch (id) {
				default:
					content =
						permissoes.includes('Administrador') ||
						permissoes.includes('Financa - Historico Transacoes') ? (
							<TransactionDetails />
						) : null;
					break;
			}
			break;

		case 'detalhes-link':
			switch (id) {
				default:
					content =
						permissoes.includes('Administrador') || permissoes.includes('Cobranca - Link Pagamento') ? (
							<PaymentLinkDetails />
						) : null;
					break;
			}
			break;

		default:
			content = null;
			break;
	}

	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const small = useMediaQuery(theme.breakpoints.down(1090));
	const validarRenderizacao = get(userPermissao, 'permissao[0].id');
	const history = useHistory();

	if (validarRenderizacao === undefined) {
		return null;
	} else {
		if (section === 'home') {
			return (
				<Box className={classes.homeRoot}>
					<Box style={{ display: 'flex', width: '100%', flexDirection: 'row' }}>
						<img
							alt="Logo de fundo"
							src={backgroundLogoYellow}
							style={{ height: '30%', position: 'absolute', right: 0 }}
						/>

						<DrawerSideBar />

						{!matches ? (
							<img
								alt="Logo itabank"
								src={headerLogo}
								style={{ height: '150px', position: 'absolute', left: 80, cursor: 'pointer' }}
								onClick={() => history.push('/')}
							/>
						) : null}
					</Box>
					<Box className={classes.homeContentContainer}>{content}</Box>
				</Box>
			);
		} else {
			return (
				<Box className={classes.pagesRoot}>
					<Box style={{ display: 'flex', width: '100%', flexDirection: 'row' }}>
						{!small ? (
							<img
								alt="Logo de fundo"
								src={backgroundLogoYellow}
								style={{ height: '346px', position: 'absolute', right: 0 }}
							/>
						) : null}
						<DrawerSideBar />
						{!matches ? (
							<img
								alt="Logo itabank"
								src={headerLogo}
								style={{
									position: 'absolute',
									left: 250,
									cursor: 'pointer',
									height: '150px',
									zIndex: 3,
								}}
								onClick={() => history.push('/')}
							/>
						) : null}
					</Box>
					<Box className={classes.pagesContentContainer}>
						<Fade appear={true} spy={section}>
							{content !== null ? content : <Box />}
						</Fade>
					</Box>
				</Box>
			);
		}
	}
};

export default Dashboard;
