import {
	Box,
	Button,
	FormControlLabel,
	Grid,
	Paper,
	Radio,
	RadioGroup,
	Step,
	StepLabel,
	Stepper,
	TextField,
	Typography,
	makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
	loadContaDigitalDocumento,
	postNewConta,
	postUsuarioConta,
	updateConta,
} from '../../../actions/actions';
import { useDispatch, useSelector } from 'react-redux';

import CreateBankAccount from '../BankAccounts/CreateBankAccount/CreateBankAccount';
import CustomBreadcrumbs from '../../reusable/CustomBreadcrumbs/CustomBreadcrumbs';
import GradientButton from '../../reusable/GradientButton/GradientButton';
import InputMask from 'react-input-mask';
import LoadingScreen from '../../reusable/LoadingScreen/LoadingScreen';
import NewAccount from '../../reusable/Account/NewAccount/NewAccount';
import { toast } from 'react-toastify';
import useAuth from '../../../hooks/useAuth';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	paper: {
		marginBottom: theme.spacing(6),
		padding: theme.spacing(3),
		borderRadius: '27px',
		width: '850px',
		alignSelf: 'center',
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
}));

const STEPS = {
	TIPO_DE_CADASTRO: 'Tipo de Cadastro',
	CADASTRO_DA_CONTA: 'Dados da Conta',
	ACESSO_PORTAL: 'Acesso ao portal',
	CONTA_BANCARIA: 'Dados da Conta Bancária',
};

const AccountRegistration = () => {
	const classes = useStyles();
	const history = useHistory();
	const token = useAuth();
	const dispatch = useDispatch();
	const [steps, setSteps] = useState([
		STEPS.TIPO_DE_CADASTRO,
		STEPS.CADASTRO_DA_CONTA,
		STEPS.ACESSO_PORTAL,
		STEPS.CONTA_BANCARIA,
	]);
	const [activeStep, setActiveStep] = useState(0);
	const [loading, setLoading] = useState(false);

	//state de contas
	const [errosConta, setErrosConta] = useState({});
	const [conta, setConta] = useState({
		documento: '',
		nome: '',
		razao_social: '',
		cnpj: '',
		celular: '',
		data_nascimento: '',
		email: '',
		site: '',
		endereco: {
			cep: '',
			rua: '',
			numero: '',
			complemento: '',
			bairro: '',
			cidade: '',
			estado: '',
		},
	});

	const GeneratedConta = useSelector((state) => state.conta);

	//state de gerar usuario
	const [portalAcess, setPortalAcess] = useState('0');
	const [dadosUser, setDadosUser] = useState({
		conta_id: '',
		email: '',
	});
	useEffect(() => {
		setDadosUser({
			...dadosUser,
			conta_id: GeneratedConta.id,
			email: GeneratedConta.email,
		});
	}, [GeneratedConta]);

	//state de conta bancaria
	const [contaBancaria, setContaBancaria] = useState({
		conta_id: '',
		banco: '',
		agencia: '',
		tipo: '1',
		conta: '',
	});

	useEffect(() => {
		setContaBancaria({
			...contaBancaria,
			conta_id: GeneratedConta.id,
		});
	}, [GeneratedConta]);

	const [disableCadastro, setDisableCadastro] = useState(false);
	const [pessoaJuridica, setPessoaJuridica] = useState(false);
	const [buscarConta, setBuscarConta] = useState('');
	const [contaPreExistente, setContaPreExistente] = useState(false);
	const contaDigitalDocumento = useSelector((state) => state.contaDigitalDocumento);

	useEffect(() => {
		if (contaPreExistente === true) {
			setConta({ ...contaDigitalDocumento });
		}
	}, [contaDigitalDocumento, contaPreExistente]);

	const STEPS_COMPONENTS = {
		[STEPS.TIPO_DE_CADASTRO]: (
			<>
				<Box display="flex" flexDirection="column" alignItems="center">
					<Typography align="center" variant="h6" style={{ marginTop: '12px' }}>
						Digite o CPF/CNPJ
					</Typography>
					<Box>
						<Button
							variant="contained"
							style={{
								margin: '5px',
								borderRadius: '27px',
								backgroundColor: '#1E396D',
								color: 'white',
							}}
							onClick={() => setPessoaJuridica(false)}
						>
							Pessoa Física
						</Button>
						<Button
							style={{ margin: '5px', borderRadius: '27px', color: 'white' }}
							variant="contained"
							color="secondary"
							onClick={() => setPessoaJuridica(true)}
						>
							Pessoa Jurídica
						</Button>
					</Box>
					<Grid container justify="center">
						{pessoaJuridica ? (
							<Grid item sm={6} xs={12}>
								<InputMask
									mask={'99.999.999/9999-99'}
									value={buscarConta}
									onChange={(e) => setBuscarConta(e.target.value)}
								>
									{() => (
										<TextField
											variant="outlined"
											InputLabelProps={{ shrink: true }}
											name="CNPJ"
											fullWidth
											required
											label={'CNPJ'}
										/>
									)}
								</InputMask>
							</Grid>
						) : (
							<Grid item sm={6} xs={12}>
								<InputMask
									mask={'999.999.999-99'}
									value={buscarConta}
									onChange={(e) => setBuscarConta(e.target.value)}
								>
									{() => (
										<TextField
											variant="outlined"
											InputLabelProps={{ shrink: true }}
											name="documento"
											fullWidth
											required
											label={'CPF'}
										/>
									)}
								</InputMask>
							</Grid>
						)}
					</Grid>
				</Box>
			</>
		),
		[STEPS.CADASTRO_DA_CONTA]: <NewAccount conta={conta} setConta={setConta} errosConta={errosConta} />,
		[STEPS.ACESSO_PORTAL]: (
			<>
				<Box display="flex" flexDirection="column" alignItems="center">
					<Typography>Gerar acesso ao portal?</Typography>
					<RadioGroup row value={portalAcess} onChange={(e) => setPortalAcess(e.target.value)}>
						<FormControlLabel value="0" control={<Radio />} label="Não" />
						<FormControlLabel value="1" control={<Radio />} label="Sim" />
					</RadioGroup>
					{portalAcess === '1' ? (
						<TextField
							variant="outlined"
							style={{ width: '50%' }}
							type="email"
							value={GeneratedConta.email}
							label="E-mail"
							disabled
						/>
					) : null}
				</Box>
			</>
		),
		[STEPS.CONTA_BANCARIA]: (
			<CreateBankAccount
				contaBancaria={contaBancaria}
				setContaBancaria={setContaBancaria}
				disableCadastro={disableCadastro}
				setDisableCadastro={setDisableCadastro}
			/>
		),
	};

	const handleClick = async () => {
		setLoading(true);
		if (activeStep === 0) {
			const resBuscarConta = await dispatch(loadContaDigitalDocumento(token, buscarConta));
			if (resBuscarConta === false) {
				setContaPreExistente(false);
				setActiveStep(activeStep + 1);
				setLoading(false);
			}
			if (resBuscarConta === true) {
				setContaPreExistente(true);
				setActiveStep(activeStep + 1);
				setLoading(false);
				/* toast.warning(''); */
			}
		}
		if (activeStep === 1) {
			if (contaPreExistente === true) {
				let newConta = conta;
				const resConta = await dispatch(updateConta(token, newConta, newConta.id));
				if (resConta) {
					setErrosConta(resConta);
					setActiveStep(1);
					setLoading(false);
				} else {
					toast.success('Conta cadastrada com sucesso!');
					setActiveStep(activeStep + 1);
					setLoading(false);
				}
			} else {
				let newConta = conta;
				const resConta = await dispatch(postNewConta(token, newConta));
				if (resConta) {
					setErrosConta(resConta);
					setActiveStep(1);
					setLoading(false);
				} else {
					toast.success('Conta cadastrada com sucesso!');
					setActiveStep(activeStep + 1);
					setLoading(false);
				}
			}
		}
		if (activeStep === 2) {
			if (portalAcess === '1') {
				const resUser = await dispatch(postUsuarioConta(token, dadosUser));
				if (resUser) {
					toast.error('Erro ao cadastrar novo usuario');
					setLoading(false);
				} else {
					toast.success('Usuario cadastrado com sucesso');
					setActiveStep(activeStep + 1);
					setLoading(false);
				}
			} else {
				setActiveStep(activeStep + 1);
				setLoading(false);
			}
		}
		if (activeStep === steps.length - 1) {
			toast.success('Cadastro de conta digital concluido com sucesso');
			history.push('/dashboard/outros-servicos');
			setLoading(false);
		}
	};

	return (
		<Box className={classes.root}>
			<LoadingScreen isLoading={loading} />
			<CustomBreadcrumbs path1="Nova Conta Digital" />
			<Paper className={classes.paper}>
				<Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel>
					{steps.map((label) => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>
				{STEPS_COMPONENTS[steps[activeStep]]}
				<Box alignSelf="flex-end" marginTop="16px">
					<GradientButton
						buttonText={activeStep === steps.length - 1 ? 'Finalizar' : 'Próximo'}
						onClick={handleClick}
					/>
				</Box>
			</Paper>
		</Box>
	);
};

export default AccountRegistration;
