import '../../../fonts/humanist-777-bt.ttf';
import '../../../fonts/microgramma-d-bold-extended.otf';
import '../../../fonts/humanist-777-bold-bt.ttf';

import { Box, Typography, useTheme } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import React, { useState } from 'react';

import CustomButton from '../../reusable/CustomButton/CustomButton';
import CustomTextField from '../../reusable/CustomTextField/CustomTextField';
import InputMask from 'react-input-mask';
import backgroundLogoYellow from '../../../assets/logos/backgroundLogoYellow.svg';
import { loadAuth } from '../../../actions/actions';
import loginBackgroundWave from '../../../assets/logos/loginBackgroundWave.svg';
import loginHeaderLogo from '../../../assets/logos/loginHeaderLogo.svg';
import { makeStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		width: '100vw',
		height: '100vh',
		background: theme.palette.background.gradient,
	},
	appBarContainer: {
		width: '100%',
		height: '80px',
		backgroundColor: 'white',
		zIndex: 2,
	},
	contentContainer: {
		display: 'flex',
		width: '100%',
		height: '100%',
	},
	backgroundImage1: {
		height: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'flex-end',
		width: '10%',
	},
	formContainer: {
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '80%',
		zIndex: 2,
	},
	backgroundImage2: {
		height: '100%',
		width: '10%',
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'flex-start',
	},
	cadastrarButton: {
		color: 'black',
		'&:hover': {
			cursor: 'pointer',
			transform: 'scale(1.05)',
		},
	},
}));
export default function SignIn() {
	const classes = useStyles();
	const theme = useTheme();
	const dispatch = useDispatch();
	const history = useHistory();
	const [errorLogin, setErrorLogin] = useState(false);

	const [values, setValues] = useState({
		documento: '',
		senha: '',
	});

	const handleLogin = async (e) => {
		e.preventDefault();

		const resLogin = await dispatch(loadAuth(values.documento, values.senha));
		if (resLogin) {
			await localStorage.setItem(
				'@auth',
				JSON.stringify({
					...resLogin.data,
					login_time: new Date().getTime(),
				})
			);

			history.push('/dashboard/home');
		} else {
			setErrorLogin(true);
			toast.error('Usuário ou senha inválidos');
		}
	};

	return (
		<Box className={classes.root}>
			{/* <Box className={classes.appBarContainer}>
				<img alt="Logo" src={loginHeaderLogo} style={{ width: '80px', padding: '10px 20%' }} />
			</Box>
			<Box className={classes.contentContainer}>
				<Box className={classes.backgroundImage1}>
					<img
						alt="Logo de fundo"
						src={backgroundLogoYellow}
						style={{
							width: '700px',
							position: 'absolute',
							bottom: '0',
							left: '-250px',
						}}
					/>
				</Box>
				<Box className={classes.formContainer}>
					<Box display="flex" flexDirection="column">
						<Typography variant="h1" style={{ fontSize: '48px', textAlign: 'center' }}>
							Bem vindo (a)!
						</Typography>

						<Typography variant="subtitle1" align="center" style={{ fontSize: '21px' }}>
							Digite seu e-mail e senha para ter acesso à plataforma
						</Typography>

						<form
							onSubmit={(e) => handleLogin(e)}
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								marginTop: '120px',
								width: '350px',
								justifyContent: 'center',
								alignSelf: 'center',
							}}
						>
							<InputMask
								value={values.documento}
								onChange={(e) => setValues({ ...values, documento: e.target.value })}
								mask={'999.999.999-99'}
							>
								{() => (
									<CustomTextField
										fullWidth
										style={{ margin: '15px 0' }}
										placeholder="Documento"
										required
										error={errorLogin}
									/>
								)}
							</InputMask>

							<CustomTextField
								value={values.senha}
								onChange={(e) => setValues({ ...values, senha: e.target.value })}
								fullWidth
								placeholder="Senha"
								required
								error={errorLogin}
								type="password"
							/>
							<Box
								display="flex"
								flexDirection="column"
								justifyContent="center"
								alignItems="center"
								marginTop="8px"
							>
								<Typography
									component={Link}
									
									style={{ fontWeight: 'bold', color: theme.palette.primary.dark }}
								>
									Esqueceu sua senha?
								</Typography>

								<Box marginTop="40px">
									<CustomButton variant="contained" type="submit" color="black">
										ENTRAR
									</CustomButton>
								</Box>
							</Box>
						</form>
					</Box>
				</Box>
				<Box className={classes.backgroundImage2}>
					<img
						alt="Imagem de fundo"
						src={loginBackgroundWave}
						style={{ height: '450px', position: 'absolute', top: '10px' }}
					/>
				</Box>
			</Box> */}
		</Box>
	);
}
