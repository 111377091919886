import React, { useState } from 'react';
import NewPayer from '../../../reusable/Payer/NewPayer/NewPayer';
import { Box, makeStyles, Paper } from '@material-ui/core';
import CustomBreadcrumbs from '../../../reusable/CustomBreadcrumbs/CustomBreadcrumbs';
import GradientButton from '../../../reusable/GradientButton/GradientButton';
import { toast } from 'react-toastify';
import { postPagadores } from '../../../../actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import LoadingScreen from '../../../reusable/LoadingScreen/LoadingScreen';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	layout: {
		width: '800px',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	paper: {
		marginBottom: theme.spacing(6),
		padding: theme.spacing(3),
		borderRadius: '27px',
		alignSelf: 'center',
		display: 'flex',
		flexDirection: 'column',
		width: '800px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
}));

const CreateNewPayer = () => {
	const classes = useStyles();
	const [pagador, setPagador] = useState({
		conta_id: null,
		documento: '',
		nome: '',
		celular: '',
		email: '',
		data_nascimento: '',
		endereco: {
			cep: ' ',
			rua: '',
			numero: '',
			complemento: '',
			bairro: '',
			cidade: '',
			estado: '',
		},
	});
	const dispatch = useDispatch();
	const history = useHistory();
	const userData = useSelector((state) => state.userData);
	const [errosPagador, setErrosPagador] = useState({});
	const [loading, setLoading] = useState(false);
	const handleClick = async () => {
		setLoading(true);
		let newPagador = pagador;
		if (userData && userData.id) {
			newPagador = {
				...newPagador,
				conta_id: userData.id,
			};
			setPagador(newPagador);
		}

		const resPagador = await dispatch(postPagadores(newPagador));

		if (resPagador) {
			setErrosPagador(resPagador);
			toast.error('Erro no cadastro');
			setLoading(false);
		} else {
			toast.success('Cadastro efetuado com sucesso!');
			history.push('/dashboard/pagadores');
			setLoading(false);
		}
	};

	return (
		<Box className={classes.root}>
			<CustomBreadcrumbs path1="Pagadores" to1="/dashboard/pagadores" path2="Novo Pagador" />
			<LoadingScreen isLoading={loading} />
			<Paper className={classes.paper}>
				<NewPayer pagador={pagador} setPagador={setPagador} errosPagador={errosPagador} />
				<Box alignSelf="flex-end" marginTop="16px">
					<GradientButton buttonText="Cadastrar" onClick={handleClick} />
				</Box>
			</Paper>
		</Box>
	);
};

export default CreateNewPayer;
