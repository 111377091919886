import {
	Box,
	Collapse,
	IconButton,
	LinearProgress,
	makeStyles,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadResumoTransacao } from '../../../../actions/actions';
import useAuth from '../../../../hooks/useAuth';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
	transactionsArea: {
		display: 'flex',
		flexDirection: 'row',
		marginBottom: '30px',
		justifyContent: 'space-between',
		background: theme.gradient.main,
		padding: '30px',
		color: '#fff',
		borderRadius: '0 0 30px 30px',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			padding: '15px 30px',
		},
		[theme.breakpoints.only('xs')]: {},
	},
	transactionsItem: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	loadingBoxAnimation: {
		backgroundColor: theme.palette.secondary.main,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '30px',
		height: '48px',
		/* 		animation: `$myEffect 1000ms ${theme.transitions.easing.easeInOut}`,
		 */
	},
	'@keyframes myEffect': {
		'0%': {
			opacity: 0,
			transform: 'translateY(-10%)',
		},
		'100%': {
			opacity: 1,
			transform: 'translateY(0)',
		},
	},
	overlineWrapper: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.7rem',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '0.6rem',
		},
	},
	valueWrapper: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.3rem',
		},
	},
	responsiveWrapper: {
		[theme.breakpoints.down('xs')]: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-around',
			margin: '6px',
		},
	},
	expand: {
		transform: 'rotate(0deg)',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
	teste: {
		background: theme.gradient.main,
		borderRadius: '30px 30px 0 0',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

const TransactionsTypes = () => {
	const classes = useStyles();
	const token = useAuth();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up('sm'));
	const [checked, setChecked] = useState(false);

	const dispatch = useDispatch();
	const resumoTransacao = useSelector((state) => state.resumoTransacao);

	useEffect(() => {
		dispatch(loadResumoTransacao(token));
	}, []);

	if (resumoTransacao.aprovado === undefined) {
		return (
			<LinearProgress
				className={classes.teste}
				color="secondary"
				style={{ borderRadius: '30px', height: '48px' }}
			/>
		);
	} else {
		return matches ? (
			<Box display="flex" flexDirection="column">
				<Box className={classes.teste} style={!checked ? { borderRadius: '30px' } : null}>
					<Typography style={{ color: 'white' }} variant="h6">
						Resumo de Transações
					</Typography>
					<IconButton
						style={{ justifySelf: 'flex-end' }}
						className={clsx(classes.expand, {
							[classes.expandOpen]: checked,
						})}
						onClick={() => setChecked(!checked)}
						aria-label="show more"
					>
						<ExpandMoreIcon style={{ color: 'white' }} />
					</IconButton>
				</Box>
				<Collapse in={checked}>
					<Box className={classes.transactionsArea}>
						<Box className={classes.transactionsItem}>
							<Typography variant="overline" className={classes.overlineWrapper}>
								Transacionado no Mês
							</Typography>
							<Typography variant="h3" className={classes.valueWrapper}>
								R${' '}
								{resumoTransacao.transacionado
									? resumoTransacao.transacionado.replace('.', ',').replace(',', '.')
									: null}
							</Typography>
						</Box>
						<Box className={classes.transactionsItem}>
							<Typography variant="overline" className={classes.overlineWrapper}>
								Transações Aprovadas
							</Typography>
							<Typography variant="h3" className={classes.valueWrapper}>
								R${' '}
								{resumoTransacao.aprovado
									? resumoTransacao.aprovado.replace('.', ',').replace(',', '.')
									: null}
							</Typography>
						</Box>
						<Box className={classes.transactionsItem}>
							<Typography variant="overline" className={classes.overlineWrapper}>
								Transações Negadas
							</Typography>
							<Typography variant="h3" className={classes.valueWrapper}>
								R${' '}
								{resumoTransacao.recusado
									? resumoTransacao.recusado.replace('.', ',').replace(',', '.')
									: null}
							</Typography>
						</Box>
						<Box className={classes.transactionsItem}>
							<Typography variant="overline" className={classes.overlineWrapper}>
								Taxa de Conversão
							</Typography>
							<Typography variant="h3" className={classes.valueWrapper}>
								{resumoTransacao.conversao && resumoTransacao.conversao > 0
									? String(resumoTransacao.conversao).replace('.', ',') + '%'
									: '0.00%'}
							</Typography>
						</Box>
					</Box>
				</Collapse>
			</Box>
		) : (
			<Box display="flex" flexDirection="column">
				<Box className={classes.teste} style={!checked ? { borderRadius: '30px' } : null}>
					<Typography style={{ color: 'white' }} variant="h6">
						Resumo de Transações
					</Typography>
					<IconButton
						style={{ justifySelf: 'flex-end' }}
						className={clsx(classes.expand, {
							[classes.expandOpen]: checked,
						})}
						onClick={() => setChecked(!checked)}
						aria-label="show more"
					>
						<ExpandMoreIcon style={{ color: 'white' }} />
					</IconButton>
				</Box>
				<Collapse in={checked}>
					<Box className={classes.transactionsArea}>
						<Box className={classes.responsiveWrapper}>
							<Box className={classes.transactionsItem}>
								<Typography variant="overline" className={classes.overlineWrapper}>
									Transacionado no Mês
								</Typography>
								<Typography variant="h3" className={classes.valueWrapper}>
									R${' '}
									{resumoTransacao.transacionado
										? resumoTransacao.transacionado.replace('.', ',').replace(',', '.')
										: null}
								</Typography>
							</Box>
							<Box className={classes.transactionsItem}>
								<Typography variant="overline" className={classes.overlineWrapper}>
									Transações Aprovadas
								</Typography>
								<Typography variant="h3" className={classes.valueWrapper}>
									R${' '}
									{resumoTransacao.aprovado
										? resumoTransacao.aprovado.replace('.', ',').replace(',', '.')
										: null}
								</Typography>
							</Box>
						</Box>
						<Box className={classes.responsiveWrapper}>
							<Box className={classes.transactionsItem}>
								<Typography variant="overline" className={classes.overlineWrapper}>
									Transações Negadas
								</Typography>
								<Typography variant="h3" className={classes.valueWrapper}>
									R${' '}
									{resumoTransacao.recusado
										? resumoTransacao.recusado.replace('.', ',').replace(',', '.')
										: null}
								</Typography>
							</Box>
							<Box className={classes.transactionsItem}>
								<Typography variant="overline" className={classes.overlineWrapper}>
									Taxa de Conversão
								</Typography>
								<Typography variant="h3" className={classes.valueWrapper}>
									{resumoTransacao.conversao && resumoTransacao.conversao > 0
										? String(resumoTransacao.conversao).replace('.', ',') + '%'
										: '0.00%'}
								</Typography>
							</Box>
						</Box>
					</Box>
				</Collapse>
			</Box>
		);
	}
};

export default TransactionsTypes;
