import {
	faBarcode,
	faCheck,
	faCopy,
	faCreditCard,
	faDesktop,
	faDollarSign,
	faForward,
	faHandHoldingUsd,
	faHistory,
	faLink,
	faMobile,
	faSignOutAlt,
	faUndo,
	faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import AccountCollectionItem from '../../reusable/Content/AccountCollections/AccountCollectionItem/AccountCollectionItem';

const useStyles = makeStyles((theme) => ({
	responsiveWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		[theme.breakpoints.down(850)]: {
			flexDirection: 'column',
		},
	},
}));

const Adm = () => {
	const classes = useStyles();

	return (
		<>
			<Box className={classes.responsiveWrapper}>
				<AccountCollectionItem text="Gerenciar Contas" link="contas" icon={faDesktop} />
			</Box>
		</>
	);
};

export default Adm;
