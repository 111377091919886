import { Avatar, Box, Button, Paper, TextField, Typography, makeStyles, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { loadLinkPagamentoId, postSenhaLinkAction } from '../../../../actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import BlueLogo from '../../../../assets/logos/SecondaryLogo.svg';
import GradientButton from '../../../reusable/GradientButton/GradientButton';
import { Lock } from '@material-ui/icons';
import PaymentLinkPayInfos from './PaymentLinkPaySteps/PaymentLinkPayInfos/PaymentLinkPayInfos';
import PaymentLinkPaySteps from './PaymentLinkPaySteps/PaymentLinkPaySteps';
import useAuth from '../../../../hooks/useAuth';

const useStyles = makeStyles((theme) => ({
	root: {
		borderRadius: '0px',
		display: 'flex',
		justifyContent: 'center',
		width: '100vw',
		height: '100vh',
		background: `linear-gradient(0deg, ${theme.palette.background.paper} 70%, ${theme.palette.background.default} 50%)`,

		[theme.breakpoints.down('sm')]: {},
	},
	cardContainer: {
		display: 'flex',
		flexDirection: 'column',
		borderRadius: '27px',
		marginTop: '24px',
		padding: '12px',
	},
	creditCardContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: '16px 0 16px 0',
	},
	buttonContainer: {
		margin: '24px 6px 6px 0px',
		display: 'flex',
		alignSelf: 'center',
	},
	contentArea: {
		marginBottom: theme.spacing(6),
		padding: theme.spacing(3),
		borderRadius: '27px',
		alignSelf: 'center',
		display: 'flex',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column-reverse',
		},
	},
}));

const PaymentLinkPay = () => {
	const classes = useStyles();
	const token = useAuth();
	const theme = useTheme();
	const history = useHistory();
	const dispatch = useDispatch();
	const { id } = useParams();
	const [, setLoading] = useState(false);
	const linkPagamentoId = useSelector((state) => state.linkPagamentoId);

	useEffect(() => {
		dispatch(loadLinkPagamentoId(token, id));
	}, [token, id]);
	const [senha, setSenha] = useState('');
	const [verificador, setVerificador] = useState(false);

	const handleVerificar = async () => {
		setLoading(true);
		const res = await dispatch(postSenhaLinkAction(senha, id));
		if (res) {
			setVerificador(true);
			setLoading(false);
		}
	};

	if (linkPagamentoId.status === 'Usado') {
		return (
			<Box display="flex" flexDirection="column" alignItems="center">
				<Typography variant="h2">Erro 404 - Link não disponível</Typography>
				<Button variant="outlined" onClick={() => history.push('/dashboard/home')}>
					Voltar
				</Button>
			</Box>
		);
	} else {
		if (linkPagamentoId.privado === true) {
			if (verificador) {
				return (
					<Box className={classes.root}>
						<Box className={classes.cardContainer}>
							<Box className={classes.contentArea}>
								<PaymentLinkPaySteps id={id} pagadorId={linkPagamentoId.pagador_id} />
								<PaymentLinkPayInfos id={id} />
							</Box>
						</Box>
					</Box>
				);
			} else {
				return (
					<Box className={classes.root}>
						<Box display="flex" flexDirection="column" alignItems="center" marginTop="24px">
							<Box>
								<img width="150px" src={BlueLogo} alt="IntegraPay" />
							</Box>
							<Paper
								style={{
									padding: '18px',
									borderRadius: '27px',
								}}
							>
								<Box display="flex" width="100%" flexDirection="column" alignItems="center">
									<Avatar style={{ backgroundColor: 'white' }}>
										<Lock />
									</Avatar>
									<Typography variant="h6">Link privado</Typography>
									<Typography variant="body1">Digite a senha cadastrada para ter acesso</Typography>
									<Box display="flex">
										<Box marginTop="6px">
											<TextField
												variant="outlined"
												fullWidth
												label="Senha"
												value={senha}
												onChange={(e) => setSenha(e.target.value)}
												type="password"
											/>
										</Box>
									</Box>
									<GradientButton
										style={{
											marginTop: '18px',
											alignSelf: 'flex-end',
										}}
										buttonText="Verificar"
										onClick={handleVerificar}
									/>
								</Box>
							</Paper>
						</Box>
					</Box>
				);
			}
		} else {
			return (
				<Box className={classes.root}>
					<Box className={classes.cardContainer}>
						<Box className={classes.contentArea}>
							<PaymentLinkPaySteps id={id} pagadorId={linkPagamentoId.pagador_id} />
							<PaymentLinkPayInfos id={id} />
						</Box>
					</Box>
				</Box>
			);
		}
	}
};

export default PaymentLinkPay;
