import {
	faBan,
	faBarcode,
	faCheck,
	faCopy,
	faCreditCard,
	faDesktop,
	faDollarSign,
	faForward,
	faHandHoldingUsd,
	faHistory,
	faLink,
	faMobile,
	faMoneyBill,
	faMoneyBillWave,
	faSignOutAlt,
	faUndo,
	faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Switch, makeStyles, Typography, useMediaQuery, useTheme, Checkbox } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { delPermissao, loadPermissaoGerenciar, postPermissaoAction } from '../../../actions/actions';
import useAuth from '../../../hooks/useAuth';
import AccountCollectionItem from '../../reusable/Content/AccountCollections/AccountCollectionItem/AccountCollectionItem';
import CustomBreadcrumbs from '../../reusable/CustomBreadcrumbs/CustomBreadcrumbs';
import LoadingScreen from '../../reusable/LoadingScreen/LoadingScreen';

const useStyles = makeStyles((theme) => ({
	responsiveContainer: {
		display: 'flex',
		justifyContent: 'center',
		[theme.breakpoints.down(850)]: {
			flexDirection: 'column',
			alignItems: 'center',
		},
	},
}));

const UserPermissions = () => {
	const classes = useStyles();
	const token = useAuth();
	const history = useHistory();
	const theme = useTheme();
	const [loading, setLoading] = useState(false);
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const { id } = useParams();
	const dispatch = useDispatch();

	const gerenciarPermissao = useSelector((state) => state.gerenciarPermissao);
	const [permissoes, setPermissoes] = useState([]);

	useEffect(() => {
		dispatch(loadPermissaoGerenciar(token, id));
	}, []);

	useEffect(() => {
		const { permissao } = gerenciarPermissao;
		setPermissoes(permissao.map((item) => item.tipo));
	}, [gerenciarPermissao, gerenciarPermissao.permissao.length]);

	useEffect(() => {
		return () => {
			setPermissoes([]);
		};
	}, []);

	const handlePermissoes = async (event) => {
		setLoading(true);
		if (permissoes.includes(event.target.name)) {
			await dispatch(delPermissao(token, id, event.target.value));
			await dispatch(loadPermissaoGerenciar(token, id));
			setLoading(false);
		} else {
			await dispatch(postPermissaoAction(token, id, event.target.value));
			await dispatch(loadPermissaoGerenciar(token, id));
			setLoading(false);
		}
	};

	return (
		<Box display="flex" flexDirection="column">
			<LoadingScreen isLoading={loading} />
			<CustomBreadcrumbs
				path1="Contas"
				path2="Usuários"
				to1="/dashboard/contas"
				to2={`/dashboard/contas/${gerenciarPermissao.conta_id}/users`}
				path3="Gerenciar Permissões"
			/>
			<Typography style={{ marginTop: '8px' }} variant="h4">
				Gerenciar Permissões
			</Typography>

			<Box className={classes.responsiveContainer}>
				<Box display="flex" alignItems="center">
					<AccountCollectionItem text="Máquina Virtual" icon={faCreditCard} />
					<Switch
						name={'Cobranca - Cartao'}
						value={1}
						checked={permissoes.includes('Cobranca - Cartao') ? true : false}
						onClick={handlePermissoes}
					/>
				</Box>
				<Box display="flex" alignItems="center">
					<AccountCollectionItem text="Boleto" icon={faBarcode} />
					<Switch
						name={'Cobranca - Boleto'}
						value={2}
						checked={permissoes.includes('Cobranca - Boleto') ? true : false}
						onClick={handlePermissoes}
					/>
				</Box>
				<Box display="flex" alignItems="center">
					<AccountCollectionItem text="Carnê" icon={faCopy} />
					<Switch
						name={'Cobranca - Carne'}
						value={3}
						checked={permissoes.includes('Cobranca - Carne') ? true : false}
						onClick={handlePermissoes}
					/>
				</Box>
			</Box>

			<Box className={classes.responsiveContainer}>
				<Box display="flex" alignItems="center">
					<AccountCollectionItem text="Cobrança Recorrente" icon={faUndo} />
					<Switch
						name={'Cobranca - Assinatura'}
						value={4}
						checked={permissoes.includes('Cobranca - Assinatura') ? true : false}
						onClick={handlePermissoes}
					/>
				</Box>
				<Box display="flex" alignItems="center">
					<AccountCollectionItem text="Link de Pagamento" icon={faLink} />
					<Switch
						name={'Cobranca - Link Pagamento'}
						value={5}
						checked={permissoes.includes('Cobranca - Link Pagamento') ? true : false}
						onClick={handlePermissoes}
					/>
				</Box>
				<Box display="flex" alignItems="center">
					<AccountCollectionItem text="Pagadores" icon={faUsers} />
					<Switch
						name={'Outros - Pagadores'}
						value={12}
						checked={permissoes.includes('Outros - Pagadores') ? true : false}
						onClick={handlePermissoes}
					/>
				</Box>
			</Box>

			<Box className={classes.responsiveContainer}>
				<Box display="flex" alignItems="center">
					<AccountCollectionItem text="Extrato" icon={faDollarSign} />
					<Switch
						name={'Financa - Extrato'}
						value={6}
						checked={permissoes.includes('Financa - Extrato') ? true : false}
						onClick={handlePermissoes}
					/>
				</Box>
				<Box display="flex" alignItems="center">
					<AccountCollectionItem text="Histórico de Transações" icon={faHistory} />
					<Switch
						name={'Financa - Historico Transacoes'}
						value={7}
						checked={permissoes.includes('Financa - Historico Transacoes') ? true : false}
						onClick={handlePermissoes}
					/>
				</Box>
				<Box display="flex" alignItems="center">
					<AccountCollectionItem text="Lançamentos Futuros" icon={faForward} />
					<Switch
						name={'Financa - Lancamento Funturos'}
						value={8}
						checked={permissoes.includes('Financa - Lancamento Funturos') ? true : false}
						onClick={handlePermissoes}
					/>
				</Box>
			</Box>

			<Box className={classes.responsiveContainer}>
				<Box display="flex" alignItems="center">
					<AccountCollectionItem text="Realizar Transferência" icon={faSignOutAlt} />
					<Switch
						name={'Transferencia - Realizar Transferencia'}
						value={9}
						checked={permissoes.includes('Transferencia - Realizar Transferencia') ? true : false}
						onClick={handlePermissoes}
					/>
				</Box>
				<Box display="flex" alignItems="center">
					<AccountCollectionItem text="Histórico Transferências" icon={faCheck} />
					<Switch
						name={'Transferencia - Historico Transferencia'}
						value={10}
						checked={permissoes.includes('Transferencia - Historico Transferencia') ? true : false}
						onClick={handlePermissoes}
					/>
				</Box>
				<Box display="flex" alignItems="center">
					<AccountCollectionItem text="Contas Digitais" icon={faDesktop} />
					<Switch
						name={'Outros - Contas Digitais'}
						value={11}
						checked={permissoes.includes('Outros - Contas Digitais') ? true : false}
						onClick={handlePermissoes}
					/>
				</Box>
			</Box>
			<Box className={classes.responsiveContainer}>
				<Box display="flex" alignItems="center">
					<AccountCollectionItem text="Menu - Bloquer Saldo" icon={faBan} />
					<Switch
						name={'Menu - Bloquear Saldo'}
						value={14}
						checked={permissoes.includes('Menu - Bloquear Saldo') ? true : false}
						onClick={handlePermissoes}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default UserPermissions;
