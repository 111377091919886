import {
	Box,
	Button,
	LinearProgress,
	makeStyles,
	MenuItem,
	Paper,
	Select,
	Step,
	StepLabel,
	Stepper,
	Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import {
	loadPagadoresFilter,
	loadPlanosAll,
	postAssinaturaAction,
	postCartaoAssinaturaAction,
} from '../../../../actions/actions';
import useAuth from '../../../../hooks/useAuth';
import useDebounce from '../../../../hooks/useDebounce';
import CustomBreadcrumbs from '../../../reusable/CustomBreadcrumbs/CustomBreadcrumbs';
import CustomTable from '../../../reusable/CustomTable/CustomTable';
import GradientButton from '../../../reusable/GradientButton/GradientButton';
import LoadingScreen from '../../../reusable/LoadingScreen/LoadingScreen';
import SearchBar from '../../../reusable/SearchBar/SearchBar';
import SubscriptionCard from './SubscriptionCard/SubscriptionCard';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	paper: {
		marginBottom: theme.spacing(6),
		padding: theme.spacing(3),
		borderRadius: '27px',
		alignSelf: 'center',
		display: 'flex',
		flexDirection: 'column',
		width: '800px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

const STEPS = {
	PAGADOR_CADASTRADO: 'Pagador cadastrado?',
	ESCOLHER_PAGADOR: 'Escolha o pagador',
	CADASTRO_PAGADOR: 'Cadastrar novo pagador',
	PAGAMENTO: 'Pagamento',
	ESCOLHER_PLANO: 'Escolha o plano',
};

const NewAccountSubscriptions = () => {
	const classes = useStyles();
	const history = useHistory();
	const token = useAuth();
	const dispatch = useDispatch();
	const [steps] = useState([STEPS.ESCOLHER_PAGADOR, STEPS.PAGAMENTO, STEPS.ESCOLHER_PLANO]);
	const [activeStep, setActiveStep] = useState(0);

	//state de pagadores
	const [, setErrosPagador] = useState({});

	const [assinatura, setAssinatura] = useState({
		plano_id: '',
		pagador_id: null,
	});
	const pagadoresList = useSelector((state) => state.pagadores);

	const [filters, setFilters] = useState({
		like: '',
		order: '',
		mostrar: 3,
	});

	const planosList = useSelector((state) => state.planosList);

	const debouncedLike = useDebounce(filters.like, 800);

	useEffect(() => {
		dispatch(loadPlanosAll(token));
	}, []);

	useEffect(() => {
		dispatch(loadPagadoresFilter(token, undefined, debouncedLike, filters.order, 3, undefined));
	}, [debouncedLike]);

	const [pagadorCartao, setPagadorCartao] = useState({
		cartao: {
			nome: null,
			numero: '',
			cvv: '',
			mes: '',
			ano: '',
		},
	});

	const [loading, setLoading] = useState(false);

	const handleNext = async () => {
		setLoading(true);

		if (activeStep === 0) {
			if (assinatura.pagador_id === null) {
				toast.error('Selecione um pagador');
				setLoading(false);
			} else {
				setActiveStep(activeStep + 1);
				setLoading(false);
			}
		}
		if (activeStep === 1) {
			const resCartao = await dispatch(
				postCartaoAssinaturaAction(token, assinatura.pagador_id, pagadorCartao.cartao)
			);

			if (resCartao) {
				setErrosPagadorCartao(resCartao);
				toast.error('Erro ao cadastrar cartão');
				setLoading(false);
			} else {
				toast.success('Cartão vinculado com sucesso!');
				setActiveStep(activeStep + 1);
				setLoading(false);
			}
		}
		if (activeStep === 2) {
			const resAssinatura = await dispatch(postAssinaturaAction(token, assinatura));

			if (resAssinatura) {
				setErrosPagador(resAssinatura);
				toast.error('Erro ao cadastrar');
				setLoading(false);
			} else {
				toast.success('Assinatura efetuada com sucesso!');
				history.push('/dashboard/assinaturas');
				setLoading(false);
			}
		}
	};

	const [pagadorSelecionado, setPagadorSelecionado] = useState(false);

	const handleSelecionarPagador = (id) => {
		setPagadorSelecionado(true);
		setAssinatura({ ...assinatura, pagador_id: id });
		setActiveStep(activeStep + 1);
	};

	const columns = [
		{ headerText: 'Nome', key: 'nome' },
		{ headerText: 'Documento', key: 'documento' },
		{
			headerText: 'Ações',
			key: 'id',
			CustomValue: (pagadorId) => {
				return (
					<GradientButton
						style={{ height: '27px' }}
						disabled={pagadorSelecionado ? 'true' : false}
						buttonText="Selecionar"
						onClick={() => handleSelecionarPagador(pagadorId)}
					/>
				);
			},
		},
	];

	const [errosPagadorCartao, setErrosPagadorCartao] = useState({});

	const STEPS_COMPONENTS = {
		[STEPS.ESCOLHER_PAGADOR]: (
			<Box display="flex" flexDirection="column" alignItems="center">
				<SearchBar
					style={{ width: '60%', margin: '12px 0' }}
					placeholder="Pesquisar por nome, documento..."
					value={filters.like}
					onChange={(e) =>
						setFilters({
							...filters,
							like: e.target.value,
						})
					}
				/>
				{pagadoresList.data && pagadoresList.per_page ? (
					<Box display="flex" flexDirection="column">
						<CustomTable columns={columns} data={pagadoresList.data} compacta="true" />
						<Typography align="center" variant="overline">
							Caso seu pagador ainda não tenha cadastro
						</Typography>
						<Box alignSelf="center">
							<Button
								variant="outlined"
								onClick={() => history.push('/dashboard/novo-pagadores')}
								style={{ borderRadius: '27px' }}
							>
								clique aqui
							</Button>
						</Box>
					</Box>
				) : (
					<LinearProgress />
				)}
			</Box>
		),
		[STEPS.PAGAMENTO]: (
			<SubscriptionCard
				pagador={pagadorCartao}
				setPagador={setPagadorCartao}
				errosPagador={errosPagadorCartao}
			/>
		),
		[STEPS.ESCOLHER_PLANO]: (
			<Box alignSelf="center" width="50%">
				<Select
					fullWidth
					variant="standard"
					onChange={(e) => setAssinatura({ ...assinatura, plano_id: e.target.value })}
				>
					{planosList.data.map((plano) => (
						<MenuItem value={plano.id} key={plano.id}>
							{plano.nome}
						</MenuItem>
					))}
				</Select>
			</Box>
		),
	};

	return (
		<Box className={classes.root}>
			<LoadingScreen isLoading={loading} />
			<CustomBreadcrumbs path1="Assinatura" to1="/dashboard/assinaturas" path2="Nova Assinatura" />
			<Paper className={classes.paper}>
				<Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel>
					{steps.map((label) => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>
				{STEPS_COMPONENTS[steps[activeStep]]}
				<Box alignSelf="flex-end" marginTop="16px">
					<GradientButton buttonText={activeStep === 2 ? 'Finalizar' : 'Próximo'} onClick={handleNext} />
				</Box>
			</Paper>
		</Box>
	);
};

export default NewAccountSubscriptions;
