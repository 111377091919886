import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, makeStyles, Paper, Typography } from '@material-ui/core';
import { enviarBoletoAction, loadContaId } from '../../../../../actions/actions';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from '../../../../../hooks/useAuth';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	layout: {
		width: '800px',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	paper: {
		marginBottom: theme.spacing(6),
		padding: theme.spacing(3),
		borderRadius: '27px',
		width: '850px',
		alignSelf: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
}));

const PaymentSlipFinished = ({ boletoConcluido }) => {
	const classes = useStyles();
	const { valor, created_at, pagador, linha_digitavel, descricao, id, url } = boletoConcluido;
	const token = useAuth();
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const date = new Date(created_at);
	const option = {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
	};
	const formatted = date.toLocaleDateString('pt-br', option);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(loadContaId(token, pagador.conta_id));
	}, []);

	const dadosCriador = useSelector((state) => state.conta);

	const handleVoltar = () => {
		history.push('/dashboard/boleto');
	};

	const handleEnviar = async () => {
		setLoading(true);
		const res = await dispatch(enviarBoletoAction(token, id));
		if (res.status === 200) {
			toast.success('Boleto enviado com sucesso');
			setLoading(false);
		} else {
			toast.error('Erro ao enviar');
			setLoading(false);
		}
	};

	return (
		<Box className={classes.root}>
			<Paper className={classes.paper}>
				<Box display="flex" flexDirection="column">
					<Typography variant="h6" align="center">
						Boleto criado com sucesso!
					</Typography>
					<Typography variant="h6">Valor</Typography>
					<Typography variant="overline">R${valor}</Typography>
					<Divider />
					<Typography variant="h6">Data</Typography>
					<Typography variant="overline">{formatted}</Typography>
					<Divider />
					<Typography variant="h6">Descrição</Typography>
					<Typography variant="overline">{descricao}</Typography>
					<Divider />
					<Typography variant="h6">Pagador</Typography>
					<Typography variant="overline">{pagador.nome}</Typography>
					<Typography variant="overline">{pagador.email}</Typography>
					<Typography variant="overline">{pagador.celular}</Typography>
					<Divider />
					<Typography variant="h6">Código de barras</Typography>
					<Typography variant="overline">{linha_digitavel}</Typography>
					<Divider />
					<Typography variant="h6">Cobrado por: </Typography>
					<Typography variant="overline">{dadosCriador.nome}</Typography>
					<Typography variant="overline">{dadosCriador.documento}</Typography>
					<Divider />
					<Typography variant="h6">Identificação do boleto </Typography>
					<Typography>{id}</Typography>
					<Box display="flex" alignSelf="flex-end">
						<Button
							variant="outlined"
							onClick={handleVoltar}
							style={{ borderRadius: '27px', marginRight: '6px' }}
						>
							Voltar
						</Button>
						<Button
							variant="contained"
							style={{ borderRadius: '27px', marginRight: '6px' }}
							onClick={handleEnviar}
						>
							Enviar por email
						</Button>
						<Button
							variant="contained"
							color="secondary"
							style={{ borderRadius: '27px', color: 'white' }}
							onClick={() => window.open(`${url}`, 'Boleto', 'height=1000,width=1000')}
						>
							Visualizar
						</Button>
					</Box>
				</Box>
			</Paper>
		</Box>
	);
};

export default PaymentSlipFinished;
