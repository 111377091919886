import React from 'react';
import { Route, Switch } from 'react-router';
import Login from '../../Root/Login/Login';
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import PaymentLinkPay from '../../Root/PaymentLink/PaymentLinkPay/PaymentLinkPay';
import FirstAcess from '../../Root/FirstAcess/FirstAcess';
import QrCodeCharge from '../../Root/QrCodeCharge/QrCodeCharge';
import Dashboard from '../../Root/Dashboard/Dashboard';
const Routes = () => {
	return (
		<Switch>
			<PrivateRoute path="/dashboard/:section" exact component={Dashboard} />
			<PrivateRoute
				path="/dashboard/:section/:id/:subsection"
				exact
				component={Dashboard}
			/>
			{/* alterar rota para Login */}
			<Route path="/login" exact component={QrCodeCharge} />
			<Route path="/login/cadastro" exact component={FirstAcess} />
			<Route
				path="/link-pagamento/:id/pagar"
				exact
				component={PaymentLinkPay}
			/>
			<Route path="/qr-code/:id" exact component={QrCodeCharge} />
			<PrivateRoute path="/" />
		</Switch>
	);
};

export default Routes;
