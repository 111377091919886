import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Box } from '@material-ui/core';
import TransactionsTypes from '../../reusable/Content/TransactionsTypes/TransactionsTypes';
import UserInfos from '../../reusable/Content/UserInfos/UserInfos';
import AccountCollections from '../../reusable/Content/AccountCollections/AccountCollections';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
	responsiveWrapper: {
		display: 'flex',
		[theme.breakpoints.down(850)]: {
			flexDirection: 'column',
		},
	},
}));

const ContentAreas = () => {
	const { section } = useParams();
	const classes = useStyles();
	const [permissoes, setPermissoes] = useState([]);
	const userPermissao = useSelector((state) => state.userPermissao);

	useEffect(() => {
		console.log(permissoes);
	}, [permissoes]);

	useEffect(() => {
		const { permissao } = userPermissao;
		setPermissoes(permissao.map((item) => item.tipo));
	}, [userPermissao]);

	return (
		<Box>
			<TransactionsTypes />
			<Box className={classes.responsiveWrapper}>
				{section === 'home' ? (
					<AccountCollections
						cartao
						boleto
						carne
						assinaturas
						link
						cobranca
						extrato
						historicoTransacoes
						lancamentosFuturos
						realizarTransferencia
						historicoTransferencia
						criarContaDigital
						pagadores
						solicitarCartao
						cartoesPre
						terminais
						todos={true}
						area="Todos"
					/>
				) : null}
				{section === 'solicitacoes-de-cobranca' ? (
					<AccountCollections
						cartao
						boleto
						carne
						assinaturas
						link
						cobranca
						area="Solicitações de Cobrança"
					/>
				) : null}
				{section === 'financas' ? (
					<AccountCollections extrato historicoTransacoes lancamentosFuturos area="Finanças" />
				) : null}
				{section === 'transferencias' ? (
					<AccountCollections realizarTransferencia historicoTransferencia area="Transferências" />
				) : null}
				{section === 'outros-servicos' ? (
					<AccountCollections
						criarContaDigital
						pagadores
						solicitarCartao
						cartoesPre
						terminais
						area="Outros serviços"
					/>
				) : null}
				{permissoes.includes('Menu - Bloquear Saldo') || permissoes.length === 0 ? null : <UserInfos />}
			</Box>
		</Box>
	);
};

export default ContentAreas;
