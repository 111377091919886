import {
	AUTH_ME,
	CLEAR_TOKEN,
	CLEAR_TRANSACAO,
	DELETE_DOCUMENTO,
	DEL_ASSINATURA,
	DEL_CARNE,
	DEL_CONTA,
	DEL_CONTA_BANCARIA,
	DEL_PAGADOR,
	DEL_PLANO,
	DEL_TOKENS,
	DEL_USER,
	ENVIAR_BOLETO,
	GERAR_QR_CODE_IMAGEM,
	LOAD_ASSINATURAS,
	LOAD_ASSINATURA_ID,
	LOAD_AUTH,
	LOAD_BANCOS,
	LOAD_BOLETOS,
	LOAD_CARNE,
	LOAD_CARNE_ID,
	LOAD_COBRANCAS_CARTAO,
	LOAD_CONTAS,
	LOAD_CONTA_BANCARIA,
	LOAD_CONTA_DIGITAL_DOCUMENTO,
	LOAD_CONTA_ID,
	LOAD_DOCUMENTO,
	LOAD_EXPORT_EXTRATO,
	LOAD_EXPORT_TRANSACAO,
	LOAD_EXPORT_TRANSFERENCIA,
	LOAD_EXTRATO,
	LOAD_EXTRATO_RESUMO,
	LOAD_HISTORICO_TRANSACAO,
	LOAD_HISTORICO_TRANSFERENCIA,
	LOAD_LANCAMENTOS_FUTUROS,
	LOAD_LINK_PAGAMENTOS,
	LOAD_LINK_PAGAMENTOS_ID,
	LOAD_PAGADORES,
	LOAD_PAGADOR_ID,
	LOAD_PERMISSAO,
	LOAD_PERMISSAO_GERENCIAR,
	LOAD_PLANOS,
	LOAD_PLANO_ID,
	LOAD_RESUMO_TRANSACAO,
	LOAD_SIGN_OUT,
	LOAD_TOKENS,
	LOAD_TRANSACAO,
	LOAD_USER_CONTA,
	LOAD_USER_DATA,
	PAGAR_LINK_PAGAMENTO,
	POST_ASSINATURA,
	POST_AUTHME,
	POST_BOLETOS,
	POST_CAPTURA,
	POST_CARNE,
	POST_CARTAO_PAGADOR,
	POST_COBRANCA_CARTAO,
	POST_CONTA,
	POST_CONTA_BANCARIA,
	POST_DOCUMENTO,
	POST_LINK_PAGAMENTOS,
	POST_PAGADOR,
	POST_PLANO,
	POST_PRIMEIRO_ACESSO,
	POST_SAQUE,
	POST_SAQUE_AUTOMATICO,
	POST_SLIPT,
	POST_TOKENS,
	POST_TRANSFERENCIA,
	POST_USER_CONTA,
	PUT_ASSINATURA,
	PUT_PAGADOR,
	PUT_PLANO,
	REENVIAR_TOKEN,
	SET_STATE,
	SHOW_QR_CODE,
	UPDATE_USER_CONTA,
	UPDATE_USER_PASS,
} from '../constants/actionsStrings';
import { applyMiddleware, compose, createStore } from 'redux';

import thunk from 'redux-thunk';

const INITIAL_STATE = {
	linkPagamentosPagar: {
		data: [{}],
	},
	linkPagamentos: {
		data: [{}],
	},
	linkPagamentoId: {
		data: [{}],
	},
	boletos: {
		data: [{}],
	},
	userData: {
		saldo: {
			valor: '',
		},
	},
	selectedMenu: {},
	pagadores: {
		data: [{}],
	},
	extrato: {
		data: [{}],
	},
	lancamentos: {},
	pagador: {
		id: '',
		tipo: '',
		documento: '',
		conta_id: '',
		nome: '',
		celular: '',
		data_nascimento: '',
		email: '',
		endereco: {
			cep: '',
			rua: '',
			numero: '',
			complemento: '',
			bairro: '',
			cidade: '',
			estado: '',
		},
	},
	contas: {
		data: [{}],
	},
	lancamentosFuturos: {
		data: [{}],
	},
	conta: {
		documento: '',
		razao_social: '',
		celular: '',
		email: '',
		site: '',
		endereco: {
			cep: '',
			rua: '',
			numero: '',
			complemento: '',
			bairro: '',
			cidade: '',
			estado: '',
		},
	},
	usuarios: {
		user: [{}],
	},
	bancos: {},
	contaBancaria: {},
	contasBancarias: [{}],
	primeiroUsuario: {},
	historicoTransacao: {
		data: [{}],
	},
	transacao: {},
	carneList: {
		data: [{}],
	},
	cobrancaCartao: {},
	carne: {},
	enviarBoleto: {},
	auth: {},
	saque: {},
	transferencia: {},
	historicoTransferencia: {
		data: [
			{
				origem: {},
				destino: {},
			},
		],
	},
	cobrancaCartaoList: {
		data: [{}],
	},
	planosList: {
		data: [{}],
	},
	plano: {},
	assinaturasList: {
		data: [{}],
	},
	assinatura: {},
	cartaoPagador: {},
	saqueAutomatico: {},
	split: {},
	resumoTransacao: {},
	publicTokensList: [{}],
	publicToken: {},
	documentoImagem: {},
	userPermissao: {
		permissao: [{}],
	},
	gerenciarPermissao: {
		permissao: [{}],
	},
	authMe: {},
	contaDigitalDocumento: {},
	exportTransferencia: {},
	exportTransacao: {},
	exportExtrato: {},
	arquivoDocumento: [{}],
	reenviarToken: {},
	extratoResumo: {},
	qrcodeUrl: {},
	showQrCode: {},
};
const enhancer = compose(applyMiddleware(thunk));

const state = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_STATE:
			return action.payload;

		case LOAD_AUTH:
			return { ...state };

		case LOAD_SIGN_OUT:
			return { ...state, token: action.payload };

		case LOAD_PAGADOR_ID:
			return { ...state, pagador: action.payload };

		case LOAD_DOCUMENTO:
			return { ...state, arquivoDocumento: action.payload };

		case DELETE_DOCUMENTO:
			return {
				...state,
				arquivoDocumento: state.arquivoDocumento.filter(
					(item) => item.id !== action.payload
				),
			};

		case LOAD_USER_DATA:
			return { ...state, userData: action.payload };

		case REENVIAR_TOKEN:
			return { ...state, reenviarToken: action.payload };

		case LOAD_LINK_PAGAMENTOS:
			return { ...state, linkPagamentos: action.payload };

		case POST_LINK_PAGAMENTOS:
			return { ...state, linkPagamentos: action.payload };

		case PAGAR_LINK_PAGAMENTO:
			return { ...state, linkPagamentosPagar: action.payload };

		case LOAD_LINK_PAGAMENTOS_ID:
			return { ...state, linkPagamentoId: action.payload };

		case LOAD_BOLETOS:
			return { ...state, boletos: action.payload };

		case POST_SLIPT:
			return { ...state, split: action.payload };

		case POST_PAGADOR:
			return { ...state, pagador: action.payload };

		case LOAD_PAGADORES:
			return { ...state, pagadores: action.payload };

		case LOAD_CONTAS:
			return { ...state, contas: action.payload };

		case LOAD_CONTA_DIGITAL_DOCUMENTO:
			return { ...state, contaDigitalDocumento: action.payload };

		case LOAD_USER_CONTA:
			return { ...state, usuarios: action.payload };

		case DEL_USER:
			return {
				...state,
				usuarios: {
					...state.usuarios,
					user: state.usuarios.user.filter(
						(item) => item.id !== action.payload
					),
				},
			};

		case POST_USER_CONTA:
			return { ...state, usuarios: action.payload };

		case POST_CONTA:
			return { ...state, conta: action.payload };

		case LOAD_CONTA_ID:
			return { ...state, conta: action.payload };

		case POST_TOKENS:
			return { ...state, publicToken: action.payload };

		case POST_DOCUMENTO:
			return { ...state, documentoImagem: action.payload };

		case LOAD_TOKENS:
			return { ...state, publicTokensList: action.payload };

		case DEL_TOKENS:
			return {
				...state,
				publicTokensList: state.publicTokensList.filter(
					(item) => item.id !== action.payload
				),
			};

		case CLEAR_TOKEN:
			return { ...state, publicToken: {} };

		case DEL_PAGADOR:
			return {
				...state,
				pagadores: {
					...state.pagadores,
					data: state.pagadores.data.filter(
						(item) => item.id !== action.payload
					),
				},
			};

		case DEL_CONTA:
			return {
				...state,
				contas: {
					...state.contas,
					data: state.contas.data.filter(
						(item) => item.id !== action.payload
					),
				},
			};

		case PUT_PAGADOR:
			return { ...state, pagador: action.payload };

		case POST_BOLETOS:
			return { ...state, boletos: action.payload };

		case LOAD_EXTRATO:
			return { ...state, extrato: action.payload };

		case LOAD_LANCAMENTOS_FUTUROS:
			return { ...state, lancamentosFuturos: action.payload };

		case UPDATE_USER_CONTA:
			return { ...state, userData: action.payload };

		case POST_SAQUE_AUTOMATICO:
			return { ...state, saqueAutomatico: action.payload };

		case UPDATE_USER_PASS:
			return { ...state };

		case LOAD_BANCOS:
			return { ...state, bancos: action.payload };

		case POST_CONTA_BANCARIA:
			return { ...state, contaBancaria: action.payload };

		case LOAD_CONTA_BANCARIA:
			return { ...state, contasBancarias: action.payload };

		case DEL_CONTA_BANCARIA:
			return {
				...state,
				contasBancarias: state.contasBancarias.filter(
					(item) => item.id !== action.payload
				),
			};

		case POST_PRIMEIRO_ACESSO:
			return { ...state, primeiroUsuario: action.payload };

		case LOAD_HISTORICO_TRANSACAO:
			return { ...state, historicoTransacao: action.payload };

		case LOAD_TRANSACAO:
			return { ...state, transacao: action.payload };

		case CLEAR_TRANSACAO:
			return { ...state, transacao: {} };

		case ENVIAR_BOLETO:
			return { ...state, enviarBoleto: action.payload };

		case AUTH_ME:
			return { ...state, auth: action.payload };

		case POST_SAQUE:
			return { ...state, saque: action.payload };

		case LOAD_HISTORICO_TRANSFERENCIA:
			return { ...state, historicoTransferencia: action.payload };

		case POST_TRANSFERENCIA:
			return { ...state, transferencia: action.payload };

		case LOAD_CARNE:
			return { ...state, carneList: action.payload };

		case LOAD_CARNE_ID:
			return { ...state, carne: action.payload };

		case POST_CARNE:
			return { ...state, carne: action.payload };

		case POST_COBRANCA_CARTAO:
			return { ...state, cobrancaCartao: action.payload };

		case DEL_CARNE:
			return {
				...state,
				carneList: state.carneList.data.filter(
					(item) => item.id !== action.payload
				),
			};

		case LOAD_COBRANCAS_CARTAO:
			return { ...state, cobrancaCartaoList: action.payload };

		case POST_CAPTURA:
			return { ...state, cobrancaCartao: action.payload };

		case LOAD_PLANOS:
			return { ...state, planosList: action.payload };

		case LOAD_EXPORT_TRANSFERENCIA:
			return { ...state, exportTransferencia: action.payload };

		case LOAD_EXPORT_TRANSACAO:
			return { ...state, exportTransacao: action.payload };

		case LOAD_EXPORT_EXTRATO:
			return { ...state, exportExtrato: action.payload };

		case LOAD_PLANO_ID:
			return { ...state, plano: action.payload };

		case POST_PLANO:
			return { ...state, plano: action.payload };

		case PUT_PLANO:
			return { ...state, plano: action.payload };

		case DEL_PLANO:
			return {
				...state,
				planosList: {
					...state.planosList,
					data: state.planosList.data.filter(
						(item) => item.id !== action.payload
					),
				},
			};

		case POST_ASSINATURA:
			return { ...state, assinatura: action.payload };

		case LOAD_ASSINATURAS:
			return { ...state, assinaturasList: action.payload };

		case LOAD_RESUMO_TRANSACAO:
			return { ...state, resumoTransacao: action.payload };

		case LOAD_ASSINATURA_ID:
			return { ...state, assinatura: action.payload };

		case PUT_ASSINATURA:
			return { ...state, assinatura: action.payload };

		case POST_CARTAO_PAGADOR:
			return { ...state, cartaoPagador: action.payload };

		case DEL_ASSINATURA:
			return {
				...state,
				assinaturasList: {
					...state.assinaturasList,
					data: state.assinaturasList.data.filter(
						(item) => item.id !== action.payload
					),
				},
			};

		case LOAD_PERMISSAO:
			return { ...state, userPermissao: action.payload };

		case LOAD_PERMISSAO_GERENCIAR:
			return { ...state, gerenciarPermissao: action.payload };

		case POST_AUTHME:
			return { ...state, authMe: action.payload };

		case LOAD_EXTRATO_RESUMO:
			return { ...state, extratoResumo: action.payload };

		case GERAR_QR_CODE_IMAGEM:
			return { ...state, qrcodeUrl: action.payload };

		case SHOW_QR_CODE:
			return { ...state, showQrCode: action.payload };

		default:
			return { ...state };
	}
};

const store = createStore(state, enhancer);

export { store };
