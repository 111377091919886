import {
	Box,
	Button,
	LinearProgress,
	Menu,
	MenuItem,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
	loadCobrancasCartaoFilters,
	loadContasAll,
	postCobrancaEstornarAction,
} from '../../../../actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import CreditCardCaptureModal from './CreditCardCaptureModal/CreditCardCaptureModal';
import CustomBreadcrumbs from '../../../reusable/CustomBreadcrumbs/CustomBreadcrumbs';
import CustomTable from '../../../reusable/CustomTable/CustomTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GradientButton from '../../../reusable/GradientButton/GradientButton';
import LoadingScreen from '../../../reusable/LoadingScreen/LoadingScreen';
import Pagination from '@material-ui/lab/Pagination';
import SearchBar from '../../../reusable/SearchBar/SearchBar';
import SplitModal from '../../../reusable/SplitModal/SplitModal';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import useAuth from '../../../../hooks/useAuth';
import useDebounce from '../../../../hooks/useDebounce';

const columns = [
	{
		headerText: 'Criado em',
		key: 'created_at',
		CustomValue: (data) => {
			const date = new Date(data);
			const option = {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
			};
			const dataFormatada = date.toLocaleDateString('pt-br', option);
			return (
				<Box display="flex">
					<FontAwesomeIcon icon={faCalendarAlt} size="lg" />
					<Typography style={{ marginLeft: '6px' }}>{dataFormatada}</Typography>
				</Box>
			);
		},
	},
	{
		headerText: 'Situação',
		key: 'status',
		CustomValue: (status) => {
			if (status === 'Pré-autorizado') {
				return (
					<Typography
						style={{
							color: '#dfad06',
							borderRadius: '27px',
						}}
					>
						<b>PRÉ-AUTORIZADO</b>
					</Typography>
				);
			}
			if (status === 'Pago') {
				return (
					<Typography
						style={{
							color: 'green',
							borderRadius: '27px',
						}}
					>
						<b>PAGO</b>
					</Typography>
				);
			}
			if (status === 'Cancelado') {
				return (
					<Typography
						style={{
							color: 'red',
							borderRadius: '27px',
						}}
					>
						<b>CANCELADO</b>
					</Typography>
				);
			}
		},
	},
	{
		headerText: 'Pagador',
		key: 'pagador',
		CustomValue: (pagador) => {
			return (
				<>
					<Typography>{pagador ? pagador.nome : 'Sem pagador específico'}</Typography>
					<Typography>{pagador ? pagador.documento : null}</Typography>
				</>
			);
		},
	},
	{
		headerText: 'Taxas',
		key: 'taxa',
		CustomValue: (taxa) => {
			if (taxa > 0) {
				return (
					<Typography variant="" style={{ fontSize: 16, color: '#dfad06', fontWeight: 600 }}>
						R$ {taxa}
					</Typography>
				);
			} else {
				return (
					<Typography variant="" style={{ fontSize: 16, color: '	green', fontWeight: 600 }}>
						R$ {taxa}
					</Typography>
				);
			}
		},
	},
	{
		headerText: 'Valor',
		key: 'valor',
		CustomValue: (valor) => {
			return (
				<Typography variant="" style={{ fontSize: 17, fontWeight: 600, color: 'green' }}>
					R$ {valor}
				</Typography>
			);
		},
	},

	{
		headerText: 'Ações',
		key: 'menu',
	},
];

const CreditCardBillingList = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const token = useAuth();
	const userData = useSelector((state) => state.userData);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const { id } = useParams();

	const [page, setPage] = useState(1);
	const contasUser = useSelector((state) => state.contas);

	const handleNovaCobrancaCartao = () => {
		history.push('/dashboard/credito');
	};

	const [filters, setFilters] = useState({
		like: '',
		order: '',
		mostrar: '',
	});

	const debouncedLike = useDebounce(filters.like, 800);

	const cobrancaCartaoList = useSelector((state) => state.cobrancaCartaoList);

	useEffect(() => {
		dispatch(loadCobrancasCartaoFilters(token, page, debouncedLike, filters.order, filters.mostrar, id));
	}, [page, filters.order, filters.mostrar, debouncedLike, id]);

	useEffect(() => {
		return () => {
			setFilters({ ...filters });
		};
	}, []);

	const handleChangePage = (e, value) => {
		setPage(value);
	};
	const [loading, setLoading] = useState(false);
	const Editar = ({ row }) => {
		const [anchorEl, setAnchorEl] = useState(null);
		const [open, setOpen] = useState(false);
		const [openSplit, setOpenSplit] = useState(false);

		const handleClick = (event) => {
			setAnchorEl(event.currentTarget);
		};
		const handleClose = () => {
			setAnchorEl(null);
		};
		const handleEstornar = async (id) => {
			setAnchorEl(null);
			setLoading(true);
			const resEstornar = await dispatch(postCobrancaEstornarAction(token, id));
			if (resEstornar) {
				toast.success('Cobrança estornada com sucesso!');
				setLoading(false);
			} else {
				toast.error('Erro ao estornar');
				setLoading(false);
			}
		};

		return (
			<Box>
				{token && userData === '' ? null : (
					<>
						<Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
							<span class="material-icons" style={{ color: '#CC9B00', fontSize: '25px' }}>
								menu
							</span>
						</Button>
						<Menu
							id="simple-menu"
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={handleClose}
						>
							<MenuItem
								onClick={() => {
									setOpen(true);
									setAnchorEl(null);
								}}
							>
								Capturar Valor
							</MenuItem>
							<MenuItem onClick={() => handleEstornar(row.id)}>Estornar Valor</MenuItem>
							<MenuItem
								onClick={() => {
									setAnchorEl(null);
									setOpenSplit(true);
								}}
							>
								Repartir valor
							</MenuItem>
						</Menu>
						{open ? (
							<CreditCardCaptureModal row={row} open={open} onClose={() => setOpen(false)} />
						) : null}
						{openSplit ? (
							<SplitModal
								row={row}
								open={openSplit}
								onClose={() => setOpenSplit(false)}
								contasUser={contasUser.data}
							/>
						) : null}
					</>
				)}
			</Box>
		);
	};
	return (
		<Box display="flex" flexDirection="column">
			<LoadingScreen isLoading={loading} />
			{token && userData === '' ? (
				<CustomBreadcrumbs path1="Gerenciar Listas" to1="goBack" path2="Lista de Máquinas Virtuais" />
			) : (
				<CustomBreadcrumbs path1="Lista de Máquinas Virtuais" />
			)}
			<Box display="flex" justifyContent="space-between" flexDirection={matches ? 'column' : null}>
				<Typography style={{ marginTop: '8px' }} variant="h4">
					Lista de Máquinas Virtuais
				</Typography>
				{token && userData === '' ? null : (
					<Box>
						<GradientButton buttonText="+ Nova Cobrança" onClick={handleNovaCobrancaCartao} />
					</Box>
				)}
			</Box>
			<Box marginTop="16px" marginBottom="16px">
				<SearchBar
					fullWidth
					placeholder="Pesquisar por nome, documento..."
					value={filters.like}
					onChange={(e) =>
						setFilters({
							...filters,
							like: e.target.value,
						})
					}
				/>
			</Box>
			{cobrancaCartaoList.data && cobrancaCartaoList.per_page ? (
				<CustomTable columns={columns} data={cobrancaCartaoList.data} Editar={Editar} />
			) : (
				<LinearProgress />
			)}

			<Box alignSelf="flex-end" marginTop="8px">
				<Pagination
					variant="outlined"
					color="secondary"
					size="large"
					count={cobrancaCartaoList.last_page}
					onChange={handleChangePage}
					page={page}
				/>
			</Box>
		</Box>
	);
};

export default CreditCardBillingList;
