import { Avatar, Box, CircularProgress, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GradientButton from '../../GradientButton/GradientButton';
import { Link } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import WhiteLogo from '../../../../assets/logos/PrimaryLogo.svg';
import { loadUserData } from '../../../../actions/actions';
import useAuth from '../../../../hooks/useAuth';

const useStyles = makeStyles((theme) => ({
	avatarMenuContainer: {
		margin: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
	},
	avatarBox: {
		display: 'flex',
		alignItems: 'center',
		color: '#f2f2f2',
		marginTop: theme.spacing(2),
		alignContent: 'center',
		justifyContent: 'center',
	},
	avatarButton: {
		display: 'flex',
		alignContent: 'center',
		justifyContent: 'center',
		margin: '10px 0',
	},
	avatarImage: {
		width: '72px',
		height: '72px',
		border: '2px solid #fff',
	},
}));

const UserMenu = () => {
	const classes = useStyles();
	const userData = useSelector((state) => state.userData);
	const [realNome, setRealNome] = useState('');
	const token = useAuth();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(loadUserData(token));
	}, []);

	useEffect(() => {
		if (userData && userData.nome === ' ') {
			setRealNome(userData.razao_social);
		} else {
			setRealNome(userData.nome);
		}
		/* if (token && userData === '') {
			setRealNome('Administrador');
		} */
	}, [userData]);

	return (
		<Box className={classes.avatarMenuContainer}>
			<Box className={classes.avatarBox}>
				<>
					{userData && (userData.nome || userData.razao_social) ? (
						<Typography variant="subtitle2">{realNome}</Typography>
					) : token && userData === '' ? (
						'Administrador'
					) : (
						<Skeleton
							variant="text"
							animation="pulse"
							width="200px"
							height="40px"
							style={{ backgroundColor: '#d3d3d3' }}
						/>
					)}
				</>
			</Box>
			{token && userData === '' ? null : (
				<Box className={classes.avatarButton}>
					<GradientButton component={Link} to="/dashboard/editar-conta" buttonText="editar perfil" />
				</Box>
			)}
		</Box>
	);
};

export default UserMenu;
