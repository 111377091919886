import {
	Box,
	Button,
	Grid,
	IconButton,
	LinearProgress,
	TextField,
	Tooltip,
	Typography,
	makeStyles,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
	faBackspace,
	faCalendarAlt,
	faFileExcel,
	faQuestionCircle,
	faTable,
	faTrash,
} from '@fortawesome/free-solid-svg-icons';
import {
	loadExportHistoricoTransferencia,
	loadHistoricoTransferenciaFilters,
} from '../../../actions/actions';
import { useDispatch, useSelector } from 'react-redux';

import CurrencyInput from 'react-currency-input';
import CustomBreadcrumbs from '../../reusable/CustomBreadcrumbs/CustomBreadcrumbs';
import CustomTable from '../../reusable/CustomTable/CustomTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from '@material-ui/lab/Pagination';
import useAuth from '../../../hooks/useAuth';
import useDebounce from '../../../hooks/useDebounce';
import { useParams } from 'react-router';

const useStyles = makeStyles(() => ({
	currency: {
		font: 'inherit',
		color: 'currentColor',
		width: '100%',
		border: '0px',
		borderBottom: '1px solid gray',
		height: '1.1876em',
		margin: 0,
		display: 'block',
		padding: '6px 0 7px',
		minWidth: 0,
		background: 'none',
		boxSizing: 'content-box',
		animationName: 'mui-auto-fill-cancel',
		letterSpacing: 'inherit',
		animationDuration: '10ms',
		appearance: 'textfield',
		textAlign: 'start',
		paddingLeft: '5px',
	},
}));

const columns = [
	{
		headerText: 'Criado em',
		key: 'created_at',
		CustomValue: (data) => {
			const date = new Date(data);
			const option = {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
			};
			const formatted = date.toLocaleDateString('pt-br', option);
			return (
				<Box display="flex" justifyContent="center">
					<FontAwesomeIcon icon={faCalendarAlt} size="lg" />
					<Typography style={{ marginLeft: '6px' }}>{formatted}</Typography>
				</Box>
			);
		},
	},
	{
		headerText: 'Situação',
		key: 'status',
		CustomValue: (status) => {
			if (
				status === 'Bem sucedida' ||
				status === 'Sucesso' ||
				status === 'Confirmada' ||
				status === 'Aprovado' ||
				status === 'Criada'
			) {
				return (
					<Typography
						style={{
							color: 'green',
							fontWeight: 'bold',

							borderRadius: '27px',
						}}
					>
						{status}
					</Typography>
				);
			}
			if (status === 'Pendente') {
				return (
					<Typography
						style={{
							color: '#CCCC00',
							fontWeight: 'bold',

							borderRadius: '27px',
						}}
					>
						{status}
					</Typography>
				);
			}
			return (
				<Typography
					style={{
						color: 'red',
						fontWeight: 'bold',

						borderRadius: '27px',
					}}
				>
					{status}
				</Typography>
			);
		},
	},
	{
		headerText: 'Origem',
		key: 'origem',
		CustomValue: (origem) => {
			const { tipo, documento, nome, razao_social, cnpj } = origem;
			return (
				<Box>
					<Typography align="center">
						<b>{razao_social === null ? nome : tipo === 'Pessoa Jurídica' ? razao_social : nome}</b>
					</Typography>
					<Typography align="center">
						{cnpj === null ? documento : tipo === 'Pessoa Jurídica' ? cnpj : documento}
					</Typography>
				</Box>
			);
		},
	},
	{
		headerText: 'Destino',
		key: 'destino',
		CustomValue: (destino) => {
			const { tipo, documento, nome, razao_social, cnpj } = destino;
			return (
				<Box>
					<Typography align="center">
						<b>{razao_social === null ? nome : tipo === 'Pessoa Jurídica' ? razao_social : nome}</b>
					</Typography>
					<Typography align="center">
						{cnpj === null ? documento : tipo === 'Pessoa Jurídica' ? cnpj : documento}
					</Typography>
				</Box>
			);
		},
	},
	{ headerText: 'Tipo', key: 'tipo' },
	{
		headerText: 'Valor',
		key: 'valor',
		CustomValue: (valor) => {
			return (
				<Typography>
					R$ <b>{valor}</b>
				</Typography>
			);
		},
	},
	{
		headerText: 'Descrição',
		key: 'descricao',
		CustomValue: (descricao) => {
			return (
				<Tooltip title={descricao ? descricao : 'Sem descrição'}>
					<Box>
						<FontAwesomeIcon icon={faQuestionCircle} />
					</Box>
				</Tooltip>
			);
		},
	},
];

const TransferHistory = () => {
	const classes = useStyles();
	const token = useAuth();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const dispatch = useDispatch();
	const historicoTransferencia = useSelector((state) => state.historicoTransferencia);
	const userData = useSelector((state) => state.userData);
	const { id } = useParams();
	const exportTransferencia = useSelector((state) => state.exportTransferencia);
	const [page, setPage] = useState(1);
	const [filters, setFilters] = useState({
		like: '',
		valor: '',
		data: '',
	});

	const debouncedInputValue = useDebounce(filters.like, 800);

	const handleChangePage = (e, value) => {
		setPage(value);
	};

	useEffect(() => {
		dispatch(
			loadHistoricoTransferenciaFilters(token, page, debouncedInputValue, filters.valor, filters.data, id)
		);
	}, [page, token, filters.valor, filters.data, debouncedInputValue, id]);

	useEffect(() => {
		return () => {
			setFilters({ ...filters });
		};
	}, []);

	const handleExportarTransferencia = async () => {
		const res = await dispatch(
			loadExportHistoricoTransferencia(token, page, debouncedInputValue, filters.valor, filters.data, id)
		);
		if (res && res.url !== undefined) {
			window.open(`${res.url}`, '', '');
		}
	};

	return (
		<Box display="flex" flexDirection="column">
			{token && userData === '' ? (
				<CustomBreadcrumbs path1="Gerenciar Listas" to1="goBack" path2="Histórico de Transferências" />
			) : (
				<CustomBreadcrumbs path1="Histórico de Transferências" />
			)}
			<Box display="flex" justifyContent="space-between" flexDirection={matches ? 'column' : null}>
				<Typography style={{ marginTop: '8px' }} variant="h4">
					Histórico de Transferências
				</Typography>
			</Box>
			<Box
				display="flex"
				flexDirection={matches ? 'column' : null}
				alignContent="space-between"
				marginTop="16px"
				marginBottom="16px"
			>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={3}>
						<TextField
							variant="outlined"
							fullWidth
							label="Conta de Destino"
							InputLabelProps={{ shrink: true }}
							placeholder="Pesquisar por nome, documento..."
							value={filters.like}
							onChange={(e) =>
								setFilters({
									...filters,
									like: e.target.value,
								})
							}
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<TextField
							variant="outlined"
							fullWidth
							InputLabelProps={{
								shrink: true,
								pattern: 'd {4}- d {2}- d {2} ',
							}}
							type="date"
							label="Data"
							value={filters.data}
							onChange={(e) => setFilters({ ...filters, data: e.target.value })}
						/>
					</Grid>
					<Grid item xs={12} sm={2}>
						<Typography
							style={{
								alignSelf: 'center',
								fontSize: '11px',
								color: 'gray',
							}}
						>
							Valor da Transferência
						</Typography>
						<CurrencyInput
							className={classes.currency}
							decimalSeparator=","
							thousandSeparator="."
							prefix="R$ "
							value={filters.valor}
							onChangeEvent={(event, maskedvalue, floatvalue) =>
								setFilters({ ...filters, valor: floatvalue })
							}
							style={{
								marginBottom: '6px',
								width: '100%',
								alignSelf: 'center',
							}}
						/>
					</Grid>
				</Grid>
				<Box display="flex">
					<Tooltip title="Limpar Filtros">
						<IconButton
							onClick={() =>
								setFilters({
									...filters,
									like: '',
									valor: '',
									data: '',
								})
							}
						>
							<FontAwesomeIcon icon={faTrash} color="gray" />
						</IconButton>
					</Tooltip>

					<Tooltip title="Exportar Excel">
						<IconButton
							variant="outlined"
							style={{ marginLeft: '6px' }}
							onClick={handleExportarTransferencia}
						>
							<FontAwesomeIcon icon={faTable} color="green" />
						</IconButton>
					</Tooltip>
				</Box>
			</Box>

			{historicoTransferencia.data && historicoTransferencia.per_page ? (
				<CustomTable columns={columns} data={historicoTransferencia.data} />
			) : (
				<LinearProgress />
			)}
			<Box alignSelf="flex-end" marginTop="8px">
				{
					<Pagination
						variant="outlined"
						color="secondary"
						size="large"
						count={historicoTransferencia.last_page}
						onChange={handleChangePage}
						page={page}
					/>
				}
			</Box>
		</Box>
	);
};

export default TransferHistory;
