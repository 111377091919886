import { Box, Collapse, IconButton, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { get } from 'lodash';

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: theme.palette.background.default,
		color: '#fff',
	},
	body: {
		fontSize: 16,
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(odd)': {},
		borderRadius: '0px',
		'&:hover': {
			cursor: 'pointer',
			backgroundColor: 'rgba(204, 155, 0, 0.1)',
		},
		[theme.breakpoints.down('sm')]: {},
	},
}))(TableRow);

const useStyles = makeStyles({
	tableContainer: {
		borderRadius: '0px',
	},
	table: {
		minWidth: 700,
	},
});

const CustomRow = ({ row, itemColumns, handleClickRow }) => {
	const [open, setOpen] = React.useState(false);
	return (
		<>
			<TableRow
				style={{
					padding: '0px 10px',
					margin: '0px',
				}}
			>
				<TableCell
					style={{
						padding: '0px 10px',
						margin: '0px',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
						style={{
							padding: '8px',
							backgroundColor: 'rgba(204, 155, 0, 0.1)',
						}}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell />
				<TableCell />
			</TableRow>

			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<>
							<Table size="small">
								<TableHead>
									<TableRow>
										{itemColumns.map((column) => (
											<TableCell align="left" key={column.headerText}>
												{column.headerText}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{row.items.map((row) => (
										<StyledTableRow onClick={handleClickRow ? () => handleClickRow(row) : null}>
											{itemColumns.map((column) => (
												<>
													<StyledTableCell align="left">
														{column.CustomValue
															? column.CustomValue(get(row, column.key))
															: get(row, column.key)}
													</StyledTableCell>
												</>
											))}
										</StyledTableRow>
									))}
								</TableBody>
							</Table>
						</>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

const CustomTable = ({ data, columns, itemColumns, handleClickRow }) => {
	const classes = useStyles();
	return (
		<>
			<TableContainer className={classes.tableContainer} component={Paper}>
				<Table className={classes.table} aria-label="customized table">
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<>
									<StyledTableCell align="center">{column.headerText}</StyledTableCell>
								</>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{data.length > 0 ? (
							data.map((row) => (
								<>
									<TableRow key={row.name}>
										{columns.map((column) => (
											<>
												<StyledTableCell align="center">
													{column.CustomValue
														? column.CustomValue(get(row, column.key))
														: get(row, column.key)}
												</StyledTableCell>
											</>
										))}
									</TableRow>
									<CustomRow row={row} itemColumns={itemColumns} handleClickRow={handleClickRow} />
								</>
							))
						) : (
							<TableRow>
								<TableCell colSpan={columns.length}>
									<Box display="flex" flexDirection="column" alignItems="center">
										<FontAwesomeIcon
											icon={faExclamationTriangle}
											size="5x"
											style={{ marginBottom: '12px', color: '#ccc' }}
										/>
										<Typography variant="h6" style={{ color: '#ccc' }}>
											Não há dados para serem exibidos
										</Typography>
									</Box>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

export default CustomTable;
