import { Box, LinearProgress, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Link, useHistory, useParams } from 'react-router-dom';
import { loadCarneFilters } from '../../../actions/actions';
import useAuth from '../../../hooks/useAuth';
import useDebounce from '../../../hooks/useDebounce';
import CustomBreadcrumbs from '../../reusable/CustomBreadcrumbs/CustomBreadcrumbs';
import CustomTable from '../../reusable/CustomTable/CustomTable';
import GradientButton from '../../reusable/GradientButton/GradientButton';
import SearchBar from '../../reusable/SearchBar/SearchBar';

const columns = [
	{
		headerText: 'Criado em',
		key: 'created_at',
		CustomValue: (data) => {
			const date = new Date(data);
			const option = {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
			};
			const [dia] = date.toLocaleDateString('pt-br', option).split(' ');
			return <Typography align="center">{dia}</Typography>;
		},
	},

	{
		headerText: 'Comprador',
		key: 'pagador',
		CustomValue: (pagador) => {
			return (
				<>
					<Typography>{pagador.nome ? pagador.nome : null}</Typography>
					<Typography>{pagador.documento ? pagador.documento : null}</Typography>
				</>
			);
		},
	},
	{
		headerText: 'Parcelas',
		key: 'boleto',
		CustomValue: (boleto) => {
			const totalParcelas = boleto.length;
			return <Typography>{totalParcelas}</Typography>;
		},
	},

	{
		headerText: 'Total',
		key: 'valor',
		CustomValue: (valor) => <Typography>R${valor}</Typography>,
	},
];

const PaymentBooklet = () => {
	const token = useAuth();
	const history = useHistory();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const dispatch = useDispatch();
	const [page, setPage] = useState(1);
	const carneList = useSelector((state) => state.carneList);
	const userData = useSelector((state) => state.userData);
	const { id } = useParams();
	const [filters, setFilters] = useState({
		like: '',
		order: '',
		mostrar: '',
	});

	const debouncedLike = useDebounce(filters.like, 800);

	useEffect(() => {
		dispatch(loadCarneFilters(token, page, debouncedLike, filters.order, filters.mostrar, id));
	}, [page, filters.order, filters.mostrar, debouncedLike, id]);

	const handleChangePage = (e, value) => {
		setPage(value);
	};

	const handleClickRow = (row) => {
		const path = generatePath('/dashboard/detalhes-carne/:id/ver', {
			id: row.id,
		});
		history.push(path);
	};
	return (
		<Box display="flex" flexDirection="column">
			{token && userData === '' ? (
				<CustomBreadcrumbs path1="Gerenciar Listas" to1="goBack" path2="Carnê" />
			) : (
				<CustomBreadcrumbs path1="Carnê" />
			)}
			<Box display="flex" justifyContent="space-between" flexDirection={matches ? 'column' : null}>
				<Typography style={{ marginTop: '8px' }} variant="h4">
					Lista de Carnês
				</Typography>

				{token && userData === '' ? null : (
					<Link to="novo-carne">
						<GradientButton buttonText="+ Nova Cobrança" />
					</Link>
				)}
			</Box>
			<Box marginTop="16px" marginBottom="16px">
				<SearchBar
					fullWidth
					placeholder="Pesquisar por nome, documento..."
					value={filters.like}
					onChange={(e) =>
						setFilters({
							...filters,
							like: e.target.value,
						})
					}
				/>
			</Box>
			{carneList.data && carneList.per_page ? (
				<CustomTable columns={columns} data={carneList.data} handleClickRow={handleClickRow} />
			) : (
				<LinearProgress />
			)}

			<Box alignSelf="flex-end" marginTop="8px">
				<Pagination
					variant="outlined"
					color="secondary"
					size="large"
					count={carneList.last_page}
					onChange={handleChangePage}
					page={page}
				/>
			</Box>
		</Box>
	);
};

export default PaymentBooklet;
