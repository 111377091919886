import {
	Box,
	Button,
	FormLabel,
	Grid,
	Paper,
	Switch,
	TextField,
	Typography,
	makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';

import CreditCardRegistration from '../NewTypedSale/CreditCardRegistration/CreditCardRegistration';
import CustomBreadcrumbs from '../../../reusable/CustomBreadcrumbs/CustomBreadcrumbs';
import InputMask from 'react-input-mask';
import LoadingScreen from '../../../reusable/LoadingScreen/LoadingScreen';
import { postCobrancaCartaoSimplesAction } from '../../../../actions/actions';
import { toast } from 'react-toastify';
import useAuth from '../../../../hooks/useAuth';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		marginLeft: 'auto',
		marginRight: 'auto',
	},

	paper: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		marginLeft: 'auto',
		marginRight: 'auto',
		justifyItems: 'center',
		marginBottom: theme.spacing(6),
		marginTop: theme.spacing(3),
		padding: theme.spacing(3),
		borderRadius: '27px',
		width: '850px',
		alignSelf: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	stepper: {
		padding: theme.spacing(3, 0, 5),
		height: '60px',
	},
	buttons: {
		display: 'flex',
		justifyContent: 'center',
	},
	button: {
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(1),
		color: 'white',
	},
	headerPaper: {
		padding: theme.spacing(3),
		borderRadius: '27px 0 0 0 ',
		display: 'flex',
		justifyContent: 'space-between',
	},
}));

const SimpleSale = () => {
	const classes = useStyles();
	const token = useAuth();
	const history = useHistory();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const [cobrancaCartao, setCobrancaCartao] = useState({
		documento: '',
		celular: '',
		parcelas: '',
		valor: '',
		captura: false,
		cartao: {
			nome: '',
			numero: '',
			cvv: '',
			mes: '',
			ano: '',
			focus: '',
		},
	});
	const [errosCobranca, setErrosCobranca] = useState({});
	const [tipo, setTipo] = useState(false); // false - cpf ; true - cpnj

	const handlePreAutorizar = async () => {
		setLoading(true);
		const resCobrancaCartao = await dispatch(postCobrancaCartaoSimplesAction(token, cobrancaCartao));

		if (resCobrancaCartao) {
			setErrosCobranca(resCobrancaCartao);
			setLoading(false);
		} else {
			toast.success('Cobrança via Cartão criada com sucesso');
			history.push('/dashboard/credito');
			setLoading(false);
		}
	};

	const handleCobrar = async () => {
		setLoading(true);
		let newCobrancaCartao = cobrancaCartao;
		if (newCobrancaCartao.captura === false) {
			newCobrancaCartao = {
				...newCobrancaCartao,
				captura: true,
			};
			setCobrancaCartao(newCobrancaCartao);
		} else {
			newCobrancaCartao = {
				...newCobrancaCartao,
				captura: true,
			};
			setCobrancaCartao(newCobrancaCartao);
		}

		const resCobrancaCartao = await dispatch(postCobrancaCartaoSimplesAction(token, newCobrancaCartao));
		if (resCobrancaCartao) {
			setErrosCobranca(resCobrancaCartao);
			setLoading(false);
		} else {
			toast.success('Cobrança via Cartão criada com sucesso');
			history.push('/dashboard/credito');
			setLoading(false);
		}
	};

	return (
		<Box display="flex" flexDirection="column">
			<LoadingScreen isLoading={loading} />
			<CustomBreadcrumbs path1="Máquina Virtual" to1="/dashboard/credito" path2="Nova Cobrança Simples" />
			<Box className={classes.layout}>
				<Paper className={classes.paper}>
					<CreditCardRegistration
						linkPagamentoPagar={cobrancaCartao}
						setLinkPagamentoPagar={setCobrancaCartao}
						errosLink={errosCobranca}
						vendaSimples={true}
					/>

					<Grid container spacing={3} style={{ marginTop: '6px' }}>
						<Grid item xs={12}>
							<Typography align="center">Dados do pagador</Typography>
							<Box justifyContent="center" alignItems="center" justifyItems="center" display="flex">
								<FormLabel> CPF </FormLabel>
								<Switch
									style={{ alignSelf: 'center' }}
									value={tipo}
									onChange={() => setTipo(!tipo)}
								/>
								<FormLabel> CNPJ </FormLabel>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Box display="flex" alignItems="center">
								<InputMask
									mask={tipo ? '99.999.999/9999-99' : '999.999.999-99'}
									placeholder={'documento'}
									value={cobrancaCartao.documento}
									onChange={(e) =>
										setCobrancaCartao({
											...cobrancaCartao,
											documento: e.target.value,
										})
									}
								>
									{() => (
										<TextField
											variant="outlined"
											InputLabelProps={{ shrink: true }}
											error={errosCobranca.documento}
											helperText={
												errosCobranca.documento ? errosCobranca.documento.join(' ') : null
											}
											name="documento"
											fullWidth
											required
											label={'Documento'}
										/>
									)}
								</InputMask>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6}>
							<InputMask
								mask="(99) 99999-9999"
								value={cobrancaCartao.celular}
								onChange={(e) =>
									setCobrancaCartao({
										...cobrancaCartao,
										celular: e.target.value,
									})
								}
							>
								{() => (
									<TextField
										variant="outlined"
										error={errosCobranca.celular}
										helperText={errosCobranca.celular ? errosCobranca.celular.join(' ') : null}
										fullWidth
										required
										label="Celular"
										type="tel"
									/>
								)}
							</InputMask>
						</Grid>
					</Grid>
					<Box display="flex">
						<Button
							style={{ borderRadius: '27px', marginRight: '6px' }}
							variant="contained"
							color="secondary"
							onClick={handlePreAutorizar}
							className={classes.button}
						>
							Pré-autorizar
						</Button>
						<Button
							style={{ borderRadius: '27px' }}
							variant="contained"
							color="secondary"
							onClick={handleCobrar}
							className={classes.button}
						>
							Cobrar R$
							{cobrancaCartao.valor ? cobrancaCartao.valor : null}
						</Button>
					</Box>
				</Paper>
			</Box>
		</Box>
	);
};

export default SimpleSale;
